import React, { Fragment } from 'react'
import { ActionWrapper, ActionList } from './styles'
import { white, orange, grey2, black } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  FlexCell,
  Icon,
  Image,
  Relative,
} from '../../assets/styles/components'
import Badge from '../Badge/Badge'
import dummy from '../../utils/dummy'
import { formatMoney } from '../../utils/format'
import { Link } from 'react-router-dom'
import ajax from '../../routes/Profile/ajax'
import Spinner from '../Loader/Spinner'
import { withContext } from '../../utils/helper'

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      errordata: false,
      data: [],
      count: 0,
    }
    this.fetchData = this.fetchData.bind(this)
    this.read = this.read.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.fetchData()
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.open && this.state.open) {
      if (this.state.count > 0) {
        let ids = []
        this.state.data.map(g => {
          if (g.is_new_notification) ids.push(g.id)
          return g
        })
        this.read(ids)
      }
    }
  }

  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getNotification(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    let count = 0
    data.map(g => {
      if (g.is_new_notification) count++
      return g
    })
    this.setState({
      data,
      count,
      loading: false,
    })
  }

  async read(ids) {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.readNotification(ids, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.setState({
      loading: false,
    })
    this.fetchData()
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside = event => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.setState({ open: false })
    }
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    const { loading, errordata, data, open, count } = this.state
    return (
      <Relative>
        <Relative cursor="pointer" onClick={this.toggle}>
          <Image
            src={require('../../assets/images/icon-notif.png')}
            width={22}
          />
          <Badge count={count} withNumber top="-3px" right="-7px" />
        </Relative>
        {open ? (
          <ActionWrapper ref={ref => (this.wrapper = ref)}>
            {loading ? (
              <Flex justifyContent="center">
                <Spinner className="fa-lg" />
              </Flex>
            ) : data.length == 0 ? (
              <Text textAlign="center" fontSize="13px">
                No notification yet
              </Text>
            ) : (
              <Fragment>
                {data.map((g, i, a) => (
                  <ActionList
                    key={i}
                    onClick={() => {}}
                    style={{ marginBottom: i == a.length - 1 ? 0 : 12 }}
                  >
                    <a href={g.link}>
                      <Flex
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        {/* <Image src={g.image} width="35px" /> */}
                        <FlexCell flex={0.1}>
                          {g.type === 'transaction' ? (
                            <Icon
                              className="fa fa-shopping-bag fa-lg"
                              color={black}
                            />
                          ) : (
                            <Icon
                              className="fa fa-bell-o fa-lg"
                              color={black}
                            />
                          )}
                        </FlexCell>
                        <FlexCell flex={0.85} margin="0 0 0 7px">
                          <Text>{g.message}</Text>
                        </FlexCell>
                        <FlexCell flex={0.05}>
                          <Relative>
                            {g.is_new_notification ? (
                              <Badge size="10px" top="3px" right="0" />
                            ) : null}
                          </Relative>
                        </FlexCell>
                      </Flex>
                    </a>
                  </ActionList>
                ))}
              </Fragment>
            )}

            {/* <Link to="/">
              <Text
                textAlign="center"
                textDecoration="underline"
                fontSize="12px"
              >
                See all
              </Text>
            </Link> */}
          </ActionWrapper>
        ) : null}
      </Relative>
    )
  }
}

export default withContext(Notification)
