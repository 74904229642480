import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  blue,
  white,
  red,
  pink,
  green,
  bluesky,
  orange,
  breakpoints,
} from '../../assets/styles/styles'
import { Link } from 'react-router-dom'
import { btn } from '../../assets/styles/components'

const color = {
  white: css`
    background-color: ${white};
    color: ${blue};
    &:hover {
      opacity: 0.9;
    }
  `,
  blue: css`
    background-color: ${blue};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  grey: css`
    background-color: #ddd;
    color: ${white};
  `,
  'blue-light': css`
    background-color: ${bluesky};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'ghost-blue': css`
    background-color: transparent;
    color: ${blue};
    border: 1px solid ${blue};
    &:hover {
      background-color: ${blue};
      color: ${white};
    }
  `,
  'ghost-blue-light': css`
    background-color: ${white};
    color: #6eb1ff;
    border: 1px solid #6eb1ff;
    &:hover {
      background-color: #6eb1ff;
      color: ${white};
    }
  `,
  'ghost-orange': css`
    background-color: transparent;
    color: ${orange};
    border: 1px solid ${orange};
    &:hover {
      background-color: ${orange};
      color: ${white};
    }
  `,
  'ghost-grey': css`
    background-color: ${white};
    color: #aaa;
    border: 1px solid #aaa;
  `,
  'ghost-red': css`
    background-color: ${white};
    color: ${red};
    border: 1px solid ${red};
    &:hover {
      background-color: ${red};
      color: ${white};
    }
  `,
  orange: css`
    background-color: ${orange};
    color: ${white};
    border: 1px solid ${orange};
    &:hover {
      background-color: ${orange};
      color: ${white};
      opacity: 0.9;
    }
  `,
  pink: css`
    background-color: ${pink};
    color: ${white};
    border: 1px solid ${pink};
    &:hover {
      background-color: ${pink};
      color: ${white};
    }
  `,
  disabled: css`
    background-color: #f5f5f5;
    color: #cccccc;
    border: 1px solid #e0e0e0;
    cursor: not-allowed !important;
  `,
  green: css`
    background-color: ${green};
    color: ${white};
    border: 1px solid ${green};
  `,
  // yellow: css`
  //   background-color: ${yellow};
  //   color: ${white};
  //   &:hover {
  //     opacity: 0.9;
  //   }
  // `,
}

const ButtonStyled = styled.button`
  ${btn};
  font-size: ${props => props.fontSize || '14px'};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${props => (props.rounded ? '50%' : props.radius || '4px')};
  padding: ${props => props.padding || '8px 12px'};
  margin: ${props => props.margin || '0'};
  width: ${props => (props.block ? '100%' : props.width)};
  display: ${props => props.display};
  ${props => color[props.color]};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const ButtonLinkStyled = styled(Link)`
  ${btn};
  font-size: ${props => props.fontSize || '14px'};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${props => (props.rounded ? '50%' : props.radius || '4px')};
  padding: ${props => props.padding || '8px 12px'};
  margin: ${props => props.margin || '0'};
  width: ${props => (props.block ? '100%' : props.width)};
  display: ${props => props.display};
  ${props => color[props.color]};
  &:hover {
    text-decoration: none;
    ${props => props.hoverStyle};
  }

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const ButtonLinkAStyled = styled.a`
  ${btn};
  font-size: ${props => props.fontSize || '14px'};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${props => (props.rounded ? '50%' : props.radius || '4px')};
  padding: ${props => props.padding || '8px 12px'};
  margin: ${props => props.margin || '0'};
  width: ${props => (props.block ? '100%' : props.width)};
  display: ${props => props.display};
  ${props => color[props.color]};
  &:hover {
    text-decoration: none;
    ${props => props.hoverStyle};
  }

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

export { ButtonStyled, ButtonLinkStyled, ButtonLinkAStyled }
