import React, { Fragment } from 'react'
import { MenuMobileWrapper, MenuMobileList, MenuListWrapper } from './styles'
import {
  breakpoints,
  orange,
  black,
  blue,
  white,
} from '../../assets/styles/styles'
import {
  Text,
  Flex,
  FlexCell,
  Icon,
  Relative,
  Overlay,
} from '../../assets/styles/components'
import { Redirect } from 'react-router-dom'
import { withContext } from '../../utils/helper'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'
import Search from '../Search/Search'
import Badge from '../Badge/Badge'


const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const List = ({
  name,
  open,
  openc,
  onClick,
  color = black,
  padding = '12px 15px',
  icon = true,
  badge = null,
  href,
}) => (
  <a href={href} className="link">
    <Flex
      onClick={onClick}
      cursor="pointer"
      padding={padding}
      justifyContent="space-between"
      style={{
        borderBottom: '1px solid #ddd',
        borderRight: '1px solid #ddd',
        backgroundColor: white,
      }}
    >
      <Text color={color}>{name}</Text>
      <Flex>
        <Relative>{badge}</Relative>
        {icon ? (
          <Icon
            className={`fa ${open ? 'fa-chevron-down' : 'fa-chevron-right'} ${openc ? 'fa-chevron-down' : 'fa-chevron-right'}`}
            style={{ fontSize: 12, color, transition: 'all 0.2s' }}
          />
        ) : null}
      </Flex>
    </Flex>
  </a>
)

class MenuMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      redirect: '',
    }


  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  openCategories = (index) => {
    let menu = [...this.props.context.state.menu]
    menu[index].open = !menu[index].open
    this.setState({ menu })
  }


  openFirst = (index) => {
    let categories = [...this.props.context.state.categories]
    categories[index].open = !categories[index].open
    this.props.context.update('categories', categories)
    
  }

  openSecond = (index, index2) => {
    let categories = [...this.props.context.state.categories]
    categories[index].child[index2].open = !categories[index].child[index2].open
    this.props.context.update('categories', categories)
  }
  openThird = (index, index2, index3) => {
    let categories = [...this.props.context.state.categories]
    categories[index].child[index2].child[index3].open =
      !categories[index].child[index2].child[index3].open
    this.props.context.update('categories', categories)
  }
  resetCategories = () => {
    let menu = [...this.props.context.state.menu]
    let categories = [...this.props.context.state.categories]
    menu = menu.map((g) => (g.open = false))
    categories = categories.map((g) => {
      g.open = false
      g.child.map((h) => (h.open = false))
      return g
    })


    this.setState({ menu })
  }

  render() {
    const { redirect } = this.state
    const { mobileMenu, changeState, context } = this.props
    const { menu } = context.state
    if (redirect) return <Redirect to={redirect} push />
    return (
      <MediaQuery maxWidth={breakpoints[0]}>
        <MenuMobileWrapper open={mobileMenu}>
          <Overlay
            className={mobileMenu ? 'open' : ''}
            onClick={() => {
              changeState('mobileMenu', false)
            }}
          />
          <Flex
            backgroundColor={orange}
            padding="15px 15px 15px 5px"
            justifyContent="space-between"
            style={{ zIndex: 22, position: 'relative' }}
          >
            <FlexCell flex={0.18}>
              <button
                type="button"
                className={classNames('navbar-toggle')}
                data-toggle="collapse"
                data-target="#navbar-collapse-1"
                aria-expanded="false"
                onClick={() => changeState('mobileMenu', false)}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar top-bar" />
                <span className="icon-bar middle-bar" />
                <span className="icon-bar bottom-bar" />
              </button>
            </FlexCell>
            <FlexCell flex={0.82}>
              <Search location={this.props.location} match={this.props.match} />
            </FlexCell>
          </Flex>
          <MenuListWrapper>
            {menu.map((g, i) => {
              return (
                <Fragment key={i}>
                  <List
                    name={g.name}
                    open={g.open}
                    openc={g.openc}
                    onClick={() => {
                      if (this.props.location.pathname == g.link) {
                        changeState('mobileMenu', false)
                        return false
                      }
                      if (g.link) {
                        if (g.name != 'Layanan Digital') {
                          window.location.replace(g.link)
                          return false
                        }
                      }
                     

                      if (!g.link) {
                        this.openCategories(i)
                      }
                    }}
                  />
                  {g.open ? (
                    <Fragment>
                      {context.state.categories.map((h, j) => (
                        <Fragment key={j}>
                          <Relative padding="0 0 0 25px">
                            <List
                              name={h.name}
                              open={h.open}
                              color={blue}
                              padding="12px 15px 12px 0"
                              icon={h.child && h.child.length > 0}
                              onClick={() => this.openFirst(j)}
                            />
                          </Relative>
                          {h.open
                            ? h.child &&
                              h.child.map((i, k) => (
                                <Fragment key={k}>
                                  <Relative padding="0 0 0 40px">
                                    <List
                                      name={i.name}
                                      open={i.open}
                                      color={blue}
                                      padding="12px 15px 12px 0"
                                      icon={i.child && i.child.length > 0}
                                      href={`/subcategory/${i.slug}?parent=${h.id}`}
                                      onClick={() => {
                                       
                                      }}
                                    />
                                  </Relative>
                                
                                </Fragment>
                              ))
                            : null}
                        </Fragment>
                      ))}

                      <Relative padding="0 0 0 25px">
                        <List
                          name={'Brand'}
                          open={false}
                          color={blue}
                          padding="12px 15px 12px 0"
                          onClick={() => {
                            if (this.props.location.pathname == '/brand') {
                              changeState('mobileMenu', false)
                              return false
                            }
                            this.props.context.state.history.push('/brand')
                          }}
                        />
                      </Relative>
                    </Fragment>
                  ) : null}

                  
                </Fragment>
              )
            })}
            {this.props.context.state.token ? (
              <Fragment>
                <List
                  name={'Order'}
                  open={false}
                  onClick={() => {
                    if (this.props.location.pathname == '/order') {
                      changeState('mobileMenu', false)
                      return false
                    }

                    this.changeState('redirect', '/order')
                  }}
                  // badge={
                  //   <Badge
                  //     count={this.props.context.state.orderCount}
                  //     withNumber
                  //     right="0px"
                  //     bottom="0"
                  //     top="auto"
                  //   />
                  // }
                />
                <List
                  name={'Notification'}
                  open={false}
                  onClick={() => {
                    if (this.props.location.pathname == '/notification') {
                      changeState('mobileMenu', false)
                      return false
                    }
                    this.changeState('redirect', '/notification')
                  }}
                />
                <List
                  name={'Edit Profile'}
                  open={false}
                  onClick={() => {
                    if (this.props.location.pathname == '/profile') {
                      changeState('mobileMenu', false)
                      return false
                    }
                    this.changeState('redirect', '/profile')
                  }}
                />
                <List
                  name={'Logout'}
                  open={false}
                  onClick={() => {
                    changeState('mobileMenu', false)
                    this.props.context.logout()
                  }}
                />
              </Fragment>
            ) : (
              <Fragment>
                <List
                  name={'Login'}
                  open={false}
                  onClick={() => {
                    changeState('mobileMenu', false)
                    this.props.context.update('showLogin', true)
                  }}
                />
                <List
                  name={'Register'}
                  open={false}
                  onClick={() => {
                    if (this.props.location.pathname == '/register') {
                      changeState('mobileMenu', false)
                      return false
                    }
                    this.changeState('redirect', '/register')
                  }}
                />
              </Fragment>
            )}
          </MenuListWrapper>
        </MenuMobileWrapper>
      </MediaQuery>
    )
  }
}

export default withContext(MenuMobile)
