import React, { useContext } from 'react'
import { Absolute, Icon, Text, Flex } from '../../assets/styles/components'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import { AppContext } from '../../context/AppContext'

const ModalConfirmation = () => {
  const { state, update } = useContext(AppContext)
  return state.confirmation ? (
    <Modal open={state.confirmation} width="30%" padding="15px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={() => {
            update('confirmation', null)
          }}
        />
      </Absolute>
      <Text
        fontSize="18px"
        fontWeight="bold"
        margin="10px 0 0"
        textAlign="center"
      >
        {state.confirmation.text}
      </Text>
      <Flex margin="30px 0 0" justifyContent="center">
        <Button
          color="ghost-grey"
          padding="8px 20px"
          margin="0 10px"
          block
          onClick={() => {
            update('confirmation', null)
          }}
        >
          Cancel
        </Button>
        <Button
          color="orange"
          padding="8px 20px"
          margin="0 10px"
          block
          onClick={state.confirmation.submit}
        >
          {state.confirmation.submitText}
        </Button>
      </Flex>
    </Modal>
  ) : null
}

export default ModalConfirmation
