import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async login(Phone_Number, Password) {
    let form = JSON.stringify({
      Phone_Number,
      Password,
    })
    let responseJSON = await fetchPost(
      baseURL + '/login',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async register(
    NamaDepan,
    NamaBelakang,
    Gender,
    Password,
    Phone_Number,
    TanggalLahir,
    LocationId,
    NamaAlamat,
    Alamat
  ) {
    let form = JSON.stringify({
      NamaDepan,
      NamaBelakang,
      Gender,
      Password,
      Phone_Number,
      TanggalLahir,
      LocationId,
      NamaAlamat,
      Alamat
    })
    let responseJSON = await fetchPost(
      baseURL + '/register',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async otpgenerate(Phone_Number) {
    let responseJSON = await fetchPost(
      baseURL + '/otpgenerate',
      JSON.stringify({ Phone_Number }), // Pass Phone_Number inside an object
      '',
      true,
      false,
    );
    return responseJSON;
  },

    // otplogin blm selesai
    async otplogin(Phone_Number, Otp_Code){
      let form = JSON.stringify({
        Phone_Number, Otp_Code
      })
      let responseJSON = await fetchPost(
        baseURL + '/otplogin',
        form,
        '',
        true,
        false,
      )
      return responseJSON
    },

  async loginSocial(Media, UserId, Nama, Email, Avatar, UserToken) {
    let form = JSON.stringify({ Media, UserId, Nama, Email, Avatar, UserToken })
    let responseJSON = await fetchPost(
      baseURL + '/processSocialLogin',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resendConfirmationEmail(Email) {
    let form = JSON.stringify({ Email })
    let responseJSON = await fetchPost(
      baseURL + '/resendConfirmationEmail',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async forgotPassword(Phone_Number) {
    let form = JSON.stringify({ Phone_Number })
    let responseJSON = await fetchPost(
      baseURL + '/forgotPassword',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async checkForgotPasswordToken(Phone_Number, Token) {
    let form = JSON.stringify({ Phone_Number, Token })
    let responseJSON = await fetchPost(
      baseURL + '/checkForgotPasswordToken',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resetPassword(Phone_Number, Token, Password, Konfirmasi) {
    let form = JSON.stringify({ Phone_Number, Token, Password, Konfirmasi })
    let responseJSON = await fetchPost(
      baseURL + '/resetPassword',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async verificate(id) {
    let form = JSON.stringify({})
    let responseJSON = await fetchPost(
      baseURL + '/userverification/' + id,
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },
}
