import React from 'react'
import { BadgeStyled, BadgeTextStyled } from './styles'
import { red, white } from '../../assets/styles/styles'

const Badge = ({
  withNumber = false,
  count,
  color = red,
  textColor = white,
  borderColor = white,
  top = 0,
  right = 0,
  left,
  bottom,
  size = '15px',
  ...props
}) => {
  let fontSize = count && parseInt(count) > 99 ? '9px' : '10px'
  if (withNumber) {
    if (count && parseInt(count) > 0)
      return (
        <BadgeStyled
          top={top}
          right={right}
          left={left}
          bottom={bottom}
          size={size}
        >
          <BadgeTextStyled fontSize={fontSize}>{count}</BadgeTextStyled>
        </BadgeStyled>
      )
    else return null
  }
  return (
    <BadgeStyled
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      size={size}
    />
  )
}

export default Badge
