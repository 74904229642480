import styled from '@emotion/styled'
import { red } from '../../assets/styles/styles'

const ErrorTextStyled = styled.div`
  color: ${props => props.color || red};
  margin: ${props => props.margin || '0'};
  text-align: ${props => (props.center ? 'center' : 'left')};
`

export { ErrorTextStyled }
