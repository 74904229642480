import styled from '@emotion/styled'
import { white, breakpoints } from '../../assets/styles/styles'

const ModalStyled = styled.div`
  &.open {
    .modal-body {
      transform: translate(-50%, -50%);
      opacity: 1;
      z-index: 31;
    }
  }
`

const ModalOverlay = styled.div`
  background-color: ${props => props.color || 'rgba(0, 0, 0, 0.18)'};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: ${props => props.zIndex};
  display: none;
  &.open {
    opacity: 1;
    z-index: ${props => props.zIndex};
    display: block;
  }
`

const ModalBody = styled.div`
  position: fixed;
  background-color: ${props => props.backgroundColor || white};
  width: ${props => props.width || '550px'};
  top: 50%;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  z-index: ${props => props.zIndex};
  padding: ${props => props.padding};
  opacity: 0;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  max-height: ${props => props.maxHeight || '90vh'};
  overflow: auto;

  @media (max-width: ${breakpoints[0]}) {
    width: 90%;
    ${props => props.mobileStyle}
  }
`

export { ModalStyled, ModalOverlay, ModalBody }
