import React from 'react'
import PropTypes from 'prop-types'
import {
  RequiredMark,
} from '../../assets/styles/components'
import classNames from 'classnames'
import { typeChecker } from '../../utils/helper'
import { FormGroup } from '../../assets/styles/components'
import {
  SelectStyled,
  SelectText,
  SelectCaret,
  Options,
  NoOption,
  SelectLabelStyled,
  Option,
} from './styles'

class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside = event => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.toggle()
    }
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    const {
      margin,
      padding,
      value,
      option,
      optionKey,
      onChange,
      label,
      id,
      isRequired,
      stateKey, // the key needed for update the state
      inloopIndex, // if this select is inloop then this key is needed to define which select in the array
      disabled,
      placeholder,
      wrapperStyle,
    } = this.props
    const { open } = this.state
    return (
      <FormGroup
        margin={margin}
        ref={ref => (this.wrapper = ref)}
        style={wrapperStyle}
      >
        {label ? (
          <SelectLabelStyled
            htmlFor={id || label}
            className={classNames({ focused: open || value })}
            onClick={disabled ? null : this.toggle}
          >
            {label}
            {isRequired && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}  
          </SelectLabelStyled>
        ) : null}
        <SelectStyled
          className={classNames({ open })}
          onClick={disabled ? null : this.toggle}
          padding={padding}
        >
          <SelectText>
            {typeChecker(value) === 'object'
              ? value[optionKey]
              : value || placeholder}
          </SelectText>
          <SelectCaret className="fa fa-caret-down" />
        </SelectStyled>
        <Options className={classNames({ open })}>
          {option.length > 0 ? (
            option.map((item, index) => (
              <Option
                key={index}
                onClick={() => {
                  this.toggle()
                  stateKey
                    ? onChange(stateKey, item, inloopIndex)
                    : onChange(item, inloopIndex)
                }}
              >
                {typeChecker(item) === 'object'
                  ? item[optionKey || 'name']
                  : item}
              </Option>
            ))
          ) : (
            <NoOption>No data</NoOption>
          )}
        </Options>
      </FormGroup>
    )
  }
}

Select.propTypes = {
  id: PropTypes.string,
  stateKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelclass: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any,
  option: PropTypes.array,
  optionKey: PropTypes.string,
  onChange: PropTypes.func,
  inloopIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
}

Select.defaultProps = {
  option: [],
  disabled: false,
  padding: '8px 12px',
}

export default Select
