import styled from '@emotion/styled'
import { blue, black, white } from '../../assets/styles/styles'

const StepperWrapper = styled.div`
  display: flex;
  border: 1px solid #ddd;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  background-color: ${white};
`

const StepperAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 12px;
`

const StepperInput = styled.input`
  padding: 5px;
  box-shadow: none;
  outline: none;
  text-align: center;
  width: 60px;
  color: ${black};
  border: 0;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
`

export { StepperWrapper, StepperAction, StepperInput }
