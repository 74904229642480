import React, { Fragment } from 'react'
import Modal from '../../components/Modal/Modal'
import { withContext } from '../../utils/helper'
import Button from '../../components/Button/Button'
import InputOutlined from '../../components/Input/InputOutlined'
import {
  Text,
  Absolute,
  Icon,
  Flex,
  Separator,
  FlexCell,
  Image,
} from '../../assets/styles/components'
import { Underline } from './styles'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import ajaxHome from '../../routes/Home/ajax'
import TextareaOutlined from '../Input/TextareaOutlined'

class ModalHelp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      messaage: '',
      loading: false,
      error: '',
    }
    this.submit = this.submit.bind(this)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open && !this.props.open) {
      this.setState({
        name: '',
        email: '',
        messaage: '',
        loading: false,
        error: '',
      })
    }
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async submit(e) {
    e.preventDefault()
    const { name, email, message } = this.state
    this.setState({ loading: true, error: '' })
    let res = await ajaxHome.createHelp(name, email, message)
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.setState({ loading: false })
    this.props.close()
    this.props.context.update('success', res.message)
  }
  render() {
    const { state, update } = this.props.context
    const { name, email, message, loading, error } = this.state
    return (
      <Fragment>
        <Modal
          open={this.props.open}
          width="30%"
          padding="2em 2.5em 1.5em"
          overlayzIndex={21}
        >
          <Absolute top="7px" right="7px">
            <Icon
              className="fa fa-times-circle fa-lg"
              color="#999"
              cursor="pointer"
              onClick={() => this.props.close()}
            />
          </Absolute>

          <Image
            src={require('../../assets/images/icon-cs.png')}
            width="40px"
            height="40px"
            margin="0 auto 10px"
            display="block"
          />
          <Text fontWeight="bold" fontSize="20px" textAlign="center">
            Need Help?
          </Text>

          <form onSubmit={this.submit}>
            <InputOutlined
              label="Full Name"
              placeholder="ex: John Doe"
              value={name}
              onChange={e => this.changeState('name', e.target.value)}
              margin="0 0 15px"
            />
            <InputOutlined
              label="Email"
              placeholder="ex: johndoe@gmail.com"
              value={email}
              onChange={e => this.changeState('email', e.target.value)}
              margin="0 0 15px"
            />

            <TextareaOutlined
              label="Message"
              placeholder="ex: What is the status of my order?"
              value={message}
              onChange={e => this.changeState('message', e.target.value)}
              margin="0 0 15px"
            />
            <ErrorText center margin="0 0 15px">
              {error}
            </ErrorText>
            <Button color="orange" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Submit'}
            </Button>
          </form>
        </Modal>
      </Fragment>
    )
  }
}

export default withContext(ModalHelp)
