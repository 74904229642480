import styled from '@emotion/styled'
import { blue, white } from '../../assets/styles/styles'

const SelectLabelStyled = styled.label`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  transition: 0.2s all ease;
  font-weight: 600;
  opacity: 0.8;
`

const SelectStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  outline: none;
  box-shadow: none;
  padding: ${props => props.padding};
  transition: 0.3s all ease;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  &.open,
  &:hover {
    border-color: ${blue};
  }
`

const SelectText = styled.span`
  font-size: 14px;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
`

const SelectCaret = styled.i`
  color: #999999;
  margin-bottom: 2px;
`

const Options = styled.ul`
  padding-left: 0;
  list-style: none;
  margin: 0;
  z-index: 10;
  display: none;
  position: absolute;
  margin-bottom: 15px;
  top: 110%;
  left: 0;
  width: 100%;
  transition: 0.3s all ease;
  box-shadow: 2px 3px 5px 0 rgba(101, 96, 96, 0.3);
  max-height: 30vh;
  overflow: auto;
  &.open {
    display: block;
  }
`

const Option = styled.li`
  padding: 8px 15px;
  cursor: pointer;
  color: #7f8c8d;
  background-color: ${white};
  &:hover {
    background-color: ${blue};
    color: ${white};
  }
`

const NoOption = styled.li`
  padding: 8px 15px;
  color: #7f8c8d;
  background-color: ${white};
  text-align: center;
`

export {
  SelectLabelStyled,
  SelectStyled,
  SelectText,
  SelectCaret,
  Options,
  Option,
  NoOption,
}
