import React from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'
import { Relative, Flex, Absolute, Icon } from '../../assets/styles/components'
import Spinner from '../Loader/Spinner'
import { white, black } from '../../assets/styles/styles'

class Maps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      position: {},
      info: '',
    }
  }
  componentDidMount() {
    this.getLocation()
  }

  getLocation = () => {
    if (navigator.geolocation) {
      // timeout at 60000 milliseconds (60 seconds)
      let options = { timeout: 60000 }
      navigator.geolocation.getCurrentPosition(
        position => {
          let lat = position.coords.latitude
          let lng = position.coords.longitude
          this.setState({ position: { lat, lng } })
          this.props.changeCenter({ lat, lng })
        },
        err => {
          if (err.code == 1) {
            alert('Error: Location access denied')
          } else if (err.code == 2) {
            alert('Error: Position cannot be found')
          }
        },
        options,
      )
    } else {
      alert('Your browser doesnt support geolocation')
    }
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  render() {
    const { info } = this.state
    const { margin } = this.props
    return (
      <Relative style={{ height: '45vh', margin }}>
        <Map
          google={this.props.google}
          zoom={13}
          center={this.props.center}
          initialCenter={this.props.center}
          ref={ref => (this.maps = ref)}
          onClick={() => {
            if (info.text) this.changeState('info', '')
          }}
        >
          {this.props.data.map((g, i) => (
            <Marker
              key={i}
              name={g.name}
              position={{ lat: g.store_lat, lng: g.store_long }}
              onClick={() =>
                this.changeState('info', {
                  text: g.name,
                  lat: g.store_lat,
                  lng: g.store_long,
                })
              }
            ></Marker>
          ))}

          {this.state.position.lat ? (
            <Marker
              name={'Your position'}
              position={this.state.position}
              icon={{
                url: require('../../assets/images/loc.png'),
                anchor: new this.props.google.maps.Point(32, 32),
                scaledSize: new this.props.google.maps.Size(32, 32),
              }}
            />
          ) : null}
          {info.text ? (
            <InfoWindow position={info} visible={info.text ? true : false}>
              <div>{info.text}</div>
            </InfoWindow>
          ) : null}

          {/* <InfoWindow
         onClose={this.onInfoWindowClose}
        >
          <div><h1>{this.state.selectedPlace.name}</h1></div>
        </InfoWindow> */}
          <Absolute bottom="35px" left="10px">
            <Flex
              backgroundColor={white}
              width={'36px'}
              height={'36px'}
              justifyContent="center"
              alignItems="center"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
                cursor: 'pointer',
              }}
              onClick={() => this.getLocation()}
            >
              <Icon className="fa fa-location-arrow fa-lg" />
            </Flex>
          </Absolute>
        </Map>
      </Relative>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB7BKfcRhdnF3r06lZRph0CAkGjW5ru3mA',
  LoadingContainer: () => (
    <Flex justifyContent="center">
      <Spinner className="fa-2x" />
    </Flex>
  ),
})(Maps)
