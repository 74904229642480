import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import ModalAddAddress from '../Profile/components/ModalAddAddress'
import locationQueryRegistration from '../Profile/components/LocationQueryRegistration'
import Maps from '../../components/Maps/MapsAddress'
import arrowIcon from '../../assets/images/arrow-right.png'

import {
  Flex,
  Text,
  PageWrapper,
  FlexCell,
  RequiredMark,
} from '../../assets/styles/components'
import Dateinput from '../../components/Input/Dateinput'
import InputOutlined from '../../components/Input/InputOutlined'
import TextareaOutlined from '../../components/Input/TextareaOutlined'
import Checkbox from '../../components/Input/Checkbox'
import Button from '../../components/Button/Button'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import { withContext } from '../../utils/helper'
import { getParameterByName } from '../../utils/format'
import Select from '../../components/Select/Select'
import Footer from '../../components/Footer/Footer'
import ajax from './ajax'
import ajaxProfile from '../Profile/ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import ModalSuccess from './components/ModalSuccess'
import { numberOnly } from '../../utils/format'
import { LabelOutlinedStyled } from '../../components/Input/styles'
import LocationQueryRegistration from '../Profile/components/LocationQueryRegistration'
import { add } from 'date-fns'

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      password: '',
      confirmpassword: '',
      gender: '',
      genderlist: [
      { id: 1, name: 'Female', value: 'Wanita' },
        { id: 2, name: 'Male', value: 'Laki-laki' },
      ],
      phonenumber: '',
      birthdate:'',
      accepted : false, 
      loading: false,
      error: '',
      modal: false,
      selectedLocation: {},
      location_id: '',
      addressname: '',
      address: '',
      addressmodal: false,
      center: { lat: -6.175161, lng: 106.827106 },
      showMaps: false,
      editData:{},
      success: '',
    }
    this.registerSubmit = this.registerSubmit.bind(this)
    this.setLocation = this.setLocation.bind(this)
  } 
  componentDidMount() {
    window.scrollTo(0, 0)
    //Mengambil nilai variabel dari URL
    const urlParams = new URLSearchParams(window.location.search);
    const phonenumber = urlParams.get('phone');
    if(phonenumber){
      this.setState({phonenumber})
    }
    this.props.context.update('history', this.props.history)
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  setLocation(location){
    this.setState({selectedLocation : location, location_id : location.location_id})
    if (location && location.location_id) {
      this.changeState('location_id', location.location_id)
    }
  }

  async registerSubmit(e) {
    e.preventDefault()
    const {
      firstname,
      lastname,
      password,
      confirmpassword,
      gender,
      phonenumber,
      birthdate,
      location_id,
      addressname,
      address
    } = this.state
    if (password !== confirmpassword) {
      this.setState({
        error: 'Password and password confirmation must be the same',
      })
      return false
    }
    this.setState({ loading: true, error: '' })
    let res = await ajax.register(
      firstname,
      lastname,
      gender.value,
      password,
      phonenumber,
      birthdate,
      location_id,
      addressname,
      address
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    if(!this.state.accepted){
      this.setState({ loading: false, error: 'Please check the textbox if you agree with the Privacy Policy and ToS' })
      return false
    }

    // sessionStorage.setItem('addressData', JSON.stringify)({
    //   name : `${firstname} ${lastname}`,
    //   receiver_phonenumber : phonenumber,
    //   receiver_name : firstname,
    //   address : address,
    //   location_id: this.state.location.location_id
    // })

    this.setState({
      loading: false,
      firstname: '',
      lastname: '',
      password: '',
      confirmpassword: '',
      gender: this.state.genderlist[0],
      phonenumber: '',
      birthdate: '',
      location_id: '',
      addressname: '',
      address: '',
      accepted: false,
      modal: true,
      success: res.message,
    })
  }
  render() {
    const {
      firstname,
      lastname,
      password,
      confirmpassword,
      gender,
      genderlist,
      birthdate,
      phonenumber,
      error,
      modalType,
      loading,
      modal,
      addressmodal,
      location_id,
      addressname,
      address,
      loadingModal,
      showMaps,
      type,
      errorModal,
      center,
      editData,
      success,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />
        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Flex
              justifyContent="center"
              padding="1.5em 0.15em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Container>
                <Row>
                  <Col md={{ span: 4, offset: 4 }}>
                    <Text
                      fontWeight="bold"
                      fontSize="20px"
                      textAlign="center"
                      margin="0 0 10px"
                    >
                      Register New Member
                    </Text>
                    <Text
                      fontSize="13px"
                      fontWeight="500"
                      textAlign="center"
                      margin="0 0 20px"
                    >
                      One step more to get interesting offers
                    </Text>
                    <form onSubmit={this.registerSubmit}>
                      <InputOutlined
                        label="Phone Number"
                        placeholder="ex: 081234567890"
                        value={phonenumber}
                        onChange={e => {
                          let val = e.target.value
                          if (val.length > 0) val = numberOnly(val)
                          this.changeState('phonenumber', val)
                        }}
                        margin="0 0 0 px"
                        padding="10px 12px"
                        isRequired={true}
                      />
                    <Text
                      fontWeight="light"
                      color='red'
                      fontSize="12px"
                      textAlign="center"
                      margin="0 0 10px"
                    >
                      Pastikan nomor telepon ini sudah terhubung dengan whatsapp
                    </Text>
                      <InputOutlined
                        label="First Name"
                        placeholder="ex: John"
                        value={firstname}
                        onChange={e =>
                          this.changeState('firstname', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                        isRequired={true}
                      />
                      <InputOutlined
                        label="Last Name"
                        placeholder="ex: Doe"
                        value={lastname}
                        setstate="off"
                        onChange={e =>
                          this.changeState('lastname', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <Dateinput
                        label="Birth Date"
                        placeholder="ex: Doe"
                        value={birthdate}
                        onChange={e =>
                          this.changeState('birthdate', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                        width= "2000px"
                      />
                      {/* <InputOutlined
                        label="Phone Number"
                        placeholder="ex: 081234567890"
                        value={phonenumber}
                        onChange={e => {
                          let val = e.target.value
                          if (val.length > 0) val = numberOnly(val)
                          this.changeState('phonenumber', val)
                        }}
                        margin="0 0 15px"
                        padding="10px 12px"
                        isRequired={true}
                      /> */}
                      {/* <InputOutlined
                        id="regemail"
                        label="Email"
                        placeholder="ex: johndoe@gmail.com"
                        value={email}
                        onChange={e =>
                          this.changeState('email', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      /> */}

                      <InputOutlined
                        id="regpassword"
                        // autocomplete="current-password"
                        autocomplete="off"
                        type="password"
                        label="Password"
                        placeholder="Password must be at least 4 characters"
                        value={password}
                        onChange={e =>
                          this.changeState('password', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                        isRequired={true}
                      />
                      <InputOutlined
                        id="regconfpassword"
                        // autocomplete="current-password"
                        autocomplete="off"
                        type="password"
                        label="Password Confirmation"
                        placeholder="Input password once again"
                        value={confirmpassword}
                        onChange={e =>
                          this.changeState('confirmpassword', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                        isRequired={true}
                      />
                      <Select
                        label="Gender"
                        value={gender}
                        option={genderlist}
                        optionKey="name"
                        onChange={this.changeState}
                        stateKey="gender"
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                    <LocationQueryRegistration location={this.state.selectedLocation} setLocation={this.setLocation} />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <button
                        type="button"
                        className="arrow-button"
                        onClick={() => this.changeState('showMaps', !this.state.showMaps)}
                        style={{
                          outline: 'none',
                          border: 'none',
                          transform: this.state.showMaps ? 'rotate(-90deg)' : 'rotate(90deg)', 
                          transition: 'transform 0.5s ease-in-out',
                          backgroundColor: 'transparent',
                          display: 'block',
                          margin: '10px auto',
                          
                        }}
                      >
                        <img src={arrowIcon} style={{ width: '20px', height: 'auto' }} alt="arrow-down" />
                      </button>

                      {showMaps ? (
                        <div> {/* Add margin here */}
                          <Maps
                            center={center}
                            showMaps={showMaps}
                            changeState={this.changeState}
                            changeCenter={(e) => this.changeState('center', e)}
                            style={{ height: '300px'} }
                            />
                          <InputOutlined
                            label="Nama Alamat"
                            placeholder="ex: Rumah/Kantor etc"
                            value={addressname}
                            onChange={e =>
                              this.changeState('addressname', e.target.value)
                            }
                            margin="0 0 15px"
                            padding="10px 12px"
                            isRequired={true}
                          />
                          <Flex
                            justifyContent="center"
                            padding="0em 0.15em 0em"
                            mobileStyle={{ padding: '15px 20px' }}
                          ></Flex>
                          <TextareaOutlined
                            label="Full Address"
                            placeholder="ex: Jl. Perumahan Residence No. 10"
                            value={address}    
                            onChange={e => this.changeState('address', e.target.value)}
                            />
                        </div>
                      ) : null}

                      <div> {/* Adjust margin dynamically */}
                        <Checkbox
                          label='Saya setuju dengan <a href="https://dandanku.com/blog/kebijakan-privacy-keamanan-data-14" target="_blank">Privacy Policy</a> & <a href="https://dandanku.com/blog/terms-and-conditions-15" target="_blank">Terms and Conditions</a>'
                          fontSize="10px"
                          checked={this.state.accepted} // Assuming you have a state variable for the checkbox
                          onChange={(e) => this.changeState('accepted', e.target.checked)} // Update state on checkbox change
                          margin="0 0 15px"
                        />
                      </div>
                    </div>
                      <ErrorText center margin="0 0 15px">
                        {error}
                      </ErrorText>
                      <Button
                        color="blue"
                        padding="10px 20px"
                        block 
                        disabled={loading}
                      >
                        {loading ? <Spinner /> : 'Register'}
                      </Button>
                    </form>
                  </Col>
                </Row>
              </Container>
            </Flex>
          </FlexCell>
          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
        <ModalSuccess
          open={modal}
          message={success}
          close={() => this.changeState('modal', false)}
        />
      </Fragment>
    )
  }
}

export default withContext(Register)
