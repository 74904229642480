import React, { useEffect } from 'react'
import { getParameterByName } from '../../utils/format'
import ProductSearch from './ProductSearch'
import SubCategory from '../Home/SubCategory'
import ProductResult from './ProductResult'

const ProductGateway = ({ location }) => {
  let parent = getParameterByName('parent')
  let root = getParameterByName('root')

  // if (parent && root)
  //   return <ProductSearch parent={parent} root={root} location={location} />
  // else if (parent && !root)
  //   return <SubCategory parent={parent} root={root} location={location} />
  // else if (!parent && root)
  //   return <SubCategory parent={parent} root={root} location={location} />
  // else return <SubCategory parent={parent} root={root} location={location} />

  return <ProductResult location={location} />

}

export default ProductGateway
