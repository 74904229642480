import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Flex,
  FlexCell,
  Separator,
  Absolute,
  Icon,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import Maps from '../../components/Maps/Maps'
import InputOutlined from '../../components/Input/InputOutlined'
import Select from '../../components/Select/Select'
import ajax from './ajax'
import { blue, black } from '../../assets/styles/styles'
import Spinner from '../../components/Loader/Spinner'

class Store extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      search: '',
      status: {
        name: 'All',
        value: '',
      },
      statuslist: [],
      center: { lat: -6.175161, lng: 106.827106 },
      page: 1,
      loadmore: true,
      loadingmore: false,
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData(1)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) this.fetchData()
  }

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData(page = 1) {
    const { token } = this.props.context.state
    const { status, search } = this.state
    let loadingmore = false
    let loading = true
    if (page > 1) {
      loading = false
      loadingmore = true
    }
    this.setState({ loading, errordata: false, loadingmore })

    let res0 = await ajax.getLocation(token)
    if (!res0.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let res = await ajax.getData(page, status.value || '', search, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let statuslist = res0.data
    statuslist = statuslist.map(g => {
      return {
        name: g,
        value: g,
      }
    })
    statuslist = [
      {
        name: 'All',
        value: '',
      },
      ...statuslist,
    ]
    let data = res.data
    if (page > 1) data = [...this.state.data, ...data]
    // let center = this.state.center
    // if (data.length > 0)
    //   center = { lat: data[0].store_lat, lng: data[0].store_long }
    // else center = { lat: -6.178397, lng: 106.824091 }
    console.log(res.data.length)
    this.setState({
      data,
      // center,
      statuslist,
      loading: false,
      page,
      loadmore: res.data.length == 10,
      loadingmore: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    const {
      loading,
      errordata,
      data,
      search,
      status,
      statuslist,
      center,
      loadingmore,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Store Location`} margin="0 0 10px" />
              <Container padding="0">
                {/* <Row margin="0 -15px 40px">
              <Col md={12}>
                <Text fontSize="26px" fontWeight="bold" textAlign="center">
                  Store Location
                </Text>
              </Col>
            </Row> */}
                <Row>
                  <Col md={12}>
                    {center.lat ? (
                      <Maps
                        data={data}
                        center={center}
                        changeCenter={e => this.changeState('center', e)}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row margin="30px -15px 30px">
                  <Col xs={12} md={4}>
                    <Flex
                      justifyContent="flex-start"
                      mobileStyle={{ marginBottom: 15 }}
                    >
                      <FlexCell flex={0.5}>
                        <Text fontSize="18px" fontWeight="bold">
                          Store Location
                        </Text>
                      </FlexCell>

                      <FlexCell flex={0.5}>
                        <Select
                          placeholder="Store Status"
                          value={status}
                          option={statuslist}
                          optionKey="name"
                          onChange={this.changeState}
                          stateKey="status"
                          margin="0"
                        />
                      </FlexCell>
                    </Flex>
                  </Col>
                  <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <Relative>
                      <InputOutlined
                        placeholder="Search store"
                        value={search}
                        onChange={e =>
                          this.changeState('search', e.target.value)
                        }
                        margin="0"
                        padding="8px 12px 8px 40px"
                        onKeyPress={e => {
                          if (e.key === 'Enter') this.fetchData()
                        }}
                      />
                      <Absolute
                        top="50%"
                        left="12px"
                        transform="translateY(-50%)"
                      >
                        <Icon className="fa fa-search fa-lg" color={'#777'} />
                      </Absolute>
                    </Relative>
                  </Col>
                </Row>
                <Separator margin="0 0 30px" />

                {/* <Text fontSize="18px" fontWeight="bold" margin="0 0 20px">
              JABODETABEK
            </Text> */}
                <div
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {loading ? (
                    <Flex justifyContent="center">
                      <Spinner className="fa-2x" />
                    </Flex>
                  ) : (
                    <Row>
                      {data.length > 0 ? (
                        data.map((g, i) => (
                          <Col xs={6} md={3} key={i}>
                            <Text
                              fontSize="16px"
                              textDecoration="underline"
                              color={blue}
                              fontWeight="bold"
                              margin="0 0 10px"
                              cursor="pointer"
                              onClick={() => {
                                window.scroll({
                                  top: 0,
                                  left: 0,
                                  behavior: 'smooth',
                                })
                                this.changeState('center', {
                                  lat: g.store_lat,
                                  lng: g.store_long,
                                })
                              }}
                            >
                              {g.name}
                            </Text>
                            <Text margin="0 0 5px">
                              {`Open hour: ${g.open_hour} - ${g.close_hour} Everyday ${g.city_name}`}
                            </Text>
                            <Text margin="0 0 10px">{g.phone_number}</Text>
                          </Col>
                        ))
                      ) : (
                        <Text margin="0 15px">No store found</Text>
                      )}
                    </Row>
                  )}
                </div>
                {loadingmore ? (
                  <Row>
                    <Col md={12}>
                      <Flex justifyContent="center">
                        <Spinner className="fa-2x" />
                      </Flex>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md={12}>
                    {!loading && !loadingmore && this.state.loadmore ? (
                      <Text
                        onClick={() => this.fetchData(this.state.page + 1)}
                        textAlign="center"
                        margin="15px 0 0"
                        cursor="pointer"
                        fontSize="13px"
                        textDecoration="underline"
                      >
                        load more
                      </Text>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Store)
