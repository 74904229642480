import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import { Relative, FlexCell, PageWrapper } from '../../assets/styles/components'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import cancelbutton from '../../assets/images/icon-close.png'
import CartLeft from './components/CartLeft'
import CartRight from './components/CartRight'
import ModalPayment from './components/ModalPayment'
import ajax from './ajax'
import ajaxAddress from '../Profile/ajax'
import ajaxProduct from '../Product/ajax'
import ModalAddAddress from '../Profile/components/ModalAddAddress'
import ModalChooseAddress from './components/ModalChooseAddress'
import { Redirect } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import { calculateDiscount, formatMoney } from '../../utils/format'

class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      dataProduct: [],
      checkall: true,
      courier: '',
      courierlist: [],
      couriertype: '',
      couriertypelist: [],
      promo: '',
      promotext: '',
      promolist: [],
      voucheridlist: [],
      promoarray: [],
      modalPayment: false,
      paymentUrl: '',
      totalprice: 0,
      discount: 0,
      productdiscount: 0,
      productidlist:[],
      discountpercentage: 0,
      discountamount: 0,
      discountdelivery: 0,

      loadingAddress: false,
      addresslist: [],
      address: {},
      modalAddAddress: false,

      loadingModal: false,
      errorModal: '',
      modalChooseAddress: false,

      loadingCourier: false,
      errorCourier: '',

      redirect: '',
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchAddress = this.fetchAddress.bind(this)
    this.delete = this.delete.bind(this)
    this.submit = this.submit.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.checkVoucher = this.checkVoucher.bind(this)
    this.removePromo = this.removePromo.bind(this)
    this.checkDelivery = this.checkDelivery.bind(this)
    this.updateQty = this.updateQty.bind(this)
    this.fetchCourier = this.fetchCourier.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
    this.fetchAddress()
    this.fetchCourier()
  }
  componentWillUnmount() {
    this.updateQty()
    this.props.context.update('showDandanLoader', true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.courier !== this.state.courier ||
      prevState.address !== this.state.address ||
      prevState.couriertype !== this.state.couriertype
    ) {
      this.checkDelivery()
      const deliveryprice = this.state.couriertype?.price || 0
      this.setState({deliveryprice})
    }

  
    // Re-check voucher when courier type changes and promo is applied
    if (prevState.couriertype !== this.state.couriertype && this.state.promo ||
      prevState.totalprice !== this.state.totalprice && this.state.promo) {
      this.checkVoucher()
    }

    if(!this.state.totalprice && this.state.promolist.length>0){
      this.state.promolist = []
      this.state.voucheridlist = []
    }
    if(prevState.address !== this.state.address || prevState.couriertype != this.state.couriertype){
      this.checkDelivery()
      this.setState({promolist: []})
    }
    if (
      prevState.promolist !== this.state.promolist ||
      prevState.data !== this.state.data ||
      prevState.promo !== this.state.promo ||
      prevState.deliveryprice !== this.state.deliveryprice
    ) {
      this.calculateAndSetDiscount()
    }
    
  }
  calculateAndSetDiscount = () => {
    let totalprice = 0
    let discount = 0
    let productdiscount = 0
    let discountpercentvalue = 0
  
  
    // Calculate the total price and product-specific discounts based on data items
    this.state.data.forEach(item => {
      if (item.checked) {
        totalprice += parseInt(item.price) * parseInt(item.qty)
        productdiscount += calculateDiscount(item.price, item.discount_type, item.current_discount).discount * parseInt(item.qty)
        discount=productdiscount
      }
    })

    this.state.promolist.filter(promo => promo[3] === 'percentage')
    .forEach(promo => {
      const percentageDiscount = promo[2];
      discountpercentvalue = Math.floor((totalprice-productdiscount) * (percentageDiscount / 100))
      discount += discountpercentvalue
    })

    const discountamount = this.state.promolist.filter(promo => promo[3] == 'amount').
    reduce((sum, promo) => sum + promo[2], 0)

    const discountdelivery = this.state.promolist.filter(promo => promo[3] == 'delivery').
    reduce((sum, promo) => sum + promo[2], 0)
    
    let finaldeliveryprice = 0
    if(discountdelivery != 0){
      if(this.state.deliveryprice >= discountdelivery){
        if(this.state.deliveryprice >= 30000){
          finaldeliveryprice += 30000
        }else{
          finaldeliveryprice += this.state.deliveryprice
        }
      }else{
        finaldeliveryprice += discountdelivery
      }
    }
    discount += finaldeliveryprice
    
    if(totalprice > discountamount){
      discount += discountamount
    }
    
    const finalprice =  totalprice - discount
    this.setState({
      totalprice,
      finalprice,
      discount: Math.max(discount, 0),
      productdiscount,
      discountdelivery
    }, () => {
    })
  }
        
  async fetchData() {
    const { token,productidlist } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getData(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    let totalprice = 0
    data = data.map(g => {
      totalprice += parseInt(g.price) * parseInt(g.qty)
      g.oldqty = g.qty
      g.checked = true
      return g
    })
    this.setState(prevState => ({
      productidlist: [
        ...prevState.productidlist,
        ...data.map(arr => arr['product_id']).filter(val => val !== undefined),
      ],
    }))
    this.setState({
      data,
      totalprice,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }

  async fetchAddress() {
    const { token } = this.props.context.state
    this.setState({ loadingAddress: true, errordataAddress: false })
    let res = await ajaxAddress.getAddress(token)
    if (!res.success) {
      this.setState({ loadingAddress: false, errordataAddress: true })
      return false
    }
    let addresslist = res.data
    let address = {}
    if (addresslist.length > 0) {
      address = addresslist[addresslist.length - 1]
    }
    this.setState({
      addresslist,
      address,
      loadingAddress: false,
      errordataAddress: false,
    })
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  checkItem = index => {
    let data = [...this.state.data]
    data[index].checked = !data[index].checked
    this.setState({ data })
  }
  checkAllItem = () => {
    let data = this.state.data
    data = data.map(g => {
      g.checked = !this.state.checkall
      return g
    })
    this.setState({ data, checkall: !this.state.checkall })
  }
  changeQuantity = (index, qty) => {
    let data = [...this.state.data]
    data[index].oldqty = data[index].qty
    data[index].qty = qty
    this.setState({ data })
  }
  async addAddress(
    e,
    name,
    receiver_name,
    receiver_phone,
    address,
    locationid,
  ) {
    e.preventDefault()
    this.setState({ loadingModal: true, errorModal: '' })
    const { token } = this.props.context.state
    let res = await ajaxAddress.addAddress(
      name,
      receiver_name,
      receiver_phone,
      address,
      locationid,
      token,
    )
    if (!res.success) {
      this.setState({ loadingModal: false, errorModal: res.message })
      return false
    }

    this.setState({
      loadingModal: false,
      modalAddAddress: false,
    })
    this.props.context.update('success', res.message)
    this.fetchAddress()
  }
  async updateQty() {
    // this.setState({ loading: true, error: '' })
    const { token } = this.props.context.state
    const { data } = this.state
    let res
    for (let i = 0; i < data.length; i++) {
      if (data[i].oldqty != data[i].qty)
        res = await ajax.updateCart(data[i].cart_id, data[i].qty, token)
    }
    this.props.context.update('refetchCart', true)
    // if (!res.success) {
    //   this.setState({ loading: false, error: res.message })
    //   return false
    // }
    // this.fetchData()
    // this.props.context.update('refetchCart', true)
  }
  async delete(id) {
    this.setState({ loading: true, error: '' })
    const { token } = this.props.context.state
    let res = await ajax.deleteCart(id, token)
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.setState({promolist:[], errorPromo:""})
    this.fetchData()
    this.props.context.update('refetchCart', true)
  }

  async fetchCourier() {
    this.setState({ loadingCourier: true, errorCourier: '' })
    const { token } = this.props.context.state
    let res = await ajax.getCourier(token)
    if (!res.success) {
      this.setState({ loadingCourier: false, errorCourier: res.message })
      return false
    }
    let courierlist = res.data
    let courier = ''
    // if (courierlist.length > 0) courier = courierlist[0]
    this.setState({ courier, courierlist, loadingCourier: false })
  }

  async checkDelivery() {
    this.setState({ loadingCourierType: true, errorCourierType: '' })
    const { token } = this.props.context.state
    const { address, courier } = this.state

    let res = await ajax.checkDelivery(
      address.customer_address_id,
      courier ? courier.courier_id : '',
      token,
    )
    if (!res.success) {
      this.setState({
        loadingCourierType: false,
        errorCourierType: res.message,
      })
      return false
    }

    let couriertypelist = []
    res.data.map(g => {
      let obj = {
        ...g,
        name: `${g.service_name} (${g.etd}) Rp. ${formatMoney(g.price)}`,
        fullname: `${courier.name} - ${g.service_name} (${
          g.etd
        }) Rp. ${formatMoney(g.price)}`,
        price: parseInt(g.price),
      }
      if (g.price != '-') couriertypelist.push(obj)
        return g
    })
    
    let couriertype = this.state.couriertype
    
    if (!couriertype && couriertypelist.length > 0) {
      couriertype = couriertypelist[0]
    }

    this.setState({ 
      couriertype, 
      couriertypelist, 
      loadingCourierType: false 
    })
  }
  async checkVoucher() {
    const { token  } = this.props.context.state
    const { promotext, promolist, couriertype, totalprice, voucheridlist, discountdelivery } = this.state

    if(this.state.promolist.length>=2){
      this.setState({ errorPromo: 'Anda hanya sudah mencapai maksimal batas penggunaan voucher dalam transaksi ini' })
      return false
    }
    
    if (!promotext) {
      this.setState({ errorPromo: 'Mohon isi kode voucher' })
      return false
    }

    if (this.state.promolist.some(promo => promo[0] === promotext)) {
      this.setState({ errorPromo: 'Voucher telah digunakan'})
      return false
    }
    
    this.setState({ loadingPromo: true, errorPromo: '' })
    
    let res = await ajax.checkVoucher(
      promotext,
      couriertype.courier_service_id,
      totalprice,
      token,
    )


    if(res.success){
      if(couriertype.price <= res.data.voucher.discount_delivery){
        this.state.discountdelivery = couriertype.price
      }
      else{
        this.state.discountdelivery = res.data.voucher.discount_delivery
      }
    }

    if(this.state.promolist.length>=1 && res.data.voucher != undefined){
      if(this.state.promolist[0][3] == "delivery" && res.data.voucher.discount_delivery){
        this.setState({ errorPromo: 'Anda hanya bisa menggunakan satu voucher delivery untuk setiap transaksi' })
        return false
      }
      else if((this.state.promolist[0][3] == "percentage" || this.state.promolist[0][3]=="amount") && (res.data.voucher.discount_amount || res.data.voucher.discount_percentage)){
        this.setState({ errorPromo: 'Anda hanya bisa menggunakan satu voucher potongan untuk setiap transaksi' })
        return false
      }
    }

    if (!res.success) {
      this.setState({ loadingPromo: false, errorPromo: res.message, promo: '' })
      return false
    }

    if(this.state.totalprice > 0){
      if(res.data.voucher.discount_amount){
        this.setState(prevState => ({
          promolist: [...prevState.promolist, [res.data.voucher.voucher_id, promotext, res.data.voucher.discount_amount, 'amount']],
          voucheridlist : [...prevState.voucheridlist, [res.data.voucher_id]]
        }))
      }
  
      if(res.data.voucher.discount_percentage){
        this.setState(prevState => ({
          promolist: [...prevState.promolist, [res.data.voucher.voucher_id, promotext, res.data.voucher.discount_percentage, 'percentage']],
          voucheridlist : [...prevState.voucheridlist, [res.data.voucher_id]]
        }))
      }
      if(res.data.voucher.discount_delivery){
        if(!couriertype.price){
          this.setState({loadingPromo: false, errorPromo: 'Mohon pilih jasa pengiriman terlebih dahulu', promotext: ''})
          return false
        }
        this.setState(prevState => ({
          promolist: [...prevState.promolist, [res.data.voucher.voucher_id, promotext, this.state.discountdelivery, 'delivery']],
          voucheridlist : [...prevState.voucheridlist, [res.data.voucher_id]]
        }))
      }
    }else {
      this.setState({loadingPromo: false, errorPromo: 'Silahkan pilih produk terlebih dahulu', promotext: '', promolist: []})
      return false
    }
// disini problematik
    this.setState({
      promotext: '',
      loadingPromo: false,
    }, () => {
    })
    // this.setState({ promo: res.data.voucher, loadingPromo: false })
  }

  removePromo(index) {
    // Update promolist by filtering out the promo at the specified index
    this.setState((prevState) => {
      this.setState({ errorPromo: '' })
      const updatedPromoList = prevState.promolist.filter((_, i) => i !== index)
      return { promolist: updatedPromoList }
    }, () =>{
    })
  }    

  async add(id, data) {
    const { token } = this.props.context.state
    const { qty } = this.state
    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajaxProduct.addToCart(id, 1, token)

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', data)
    this.props.context.update('refetchCart', true)
  }

  async submit() {
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    const { address, couriertype, promo } = this.state
    let res = await ajax.createTransaction(
      address.customer_address_id,
      couriertype.courier_service_id,
      promo ? promo.voucher_id : '',
      token,
    )
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    let res2 = await ajax.snapToken(res.data.transaction_id, token)
    if (!res2.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res2.message)
      return false
    }
    this.props.context.update('showLoading', false)

    const snap = window.snap
    snap.pay(res2.data.snaptoken)

    this.fetchData()
    this.props.context.update('refetchCart', true)
    this.props.context.update('refetchOrder', true)
  }
  
  render() {
    const {
      loading,
      errordata,
      data,
      dataProduct,
      checkall,
      courier,
      courierlist,
      promo,
      promotext,
      promolist,
      loadingPromo,
      errorPromo,
      totalprice,
      finalprice,
      discount,
      modalPayment,
      paymentUrl,
      loadingAddress,
      addresslist,
      address,
      modalAddAddress,
      loadingModal,
      errorModal,
      modalChooseAddress,
      loadingCourier,
      errorCourier,
      redirect,
      couriertypelist,
      couriertype,
      loadingCourierType,
      errorCourierType,
      productdiscount,
    } = this.state
    if (redirect) return <Redirect to={redirect} push />
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb text={`Home > Shopping Cart`} margin="0 0 10px" />
              <Container>
                <Row margin="0 -15px 30px">
                  <CartLeft
                    checkall={checkall}
                    checkAllItem={this.checkAllItem}
                    data={data}
                    loading={loading}
                    checkItem={this.checkItem}
                    changeState={this.changeState}
                    changeQuantity={this.changeQuantity}
                    deleteCart={this.delete}
                  />
                  <CartRight
                    data={data}
                    dataProduct={dataProduct}
                    loading={loadingAddress}
                    address={address}
                    changeState={this.changeState}
                    courier={courier}
                    courierlist={courierlist}
                    couriertype={couriertype}
                    couriertypelist={couriertypelist}
                    promo={promo}
                    promotext={this.state.promotext}
                    promolist = {promolist}
                    loadingPromo={loadingPromo}
                    errorPromo={errorPromo}
                    totalprice={totalprice}
                    finalprice ={finalprice}
                    submit={this.submit}
                    checkVoucher={this.checkVoucher}
                    removePromo={this.removePromo}
                    loadingCourier={loadingCourier}
                    errorCourier={errorCourier}
                    checkDelivery={this.checkDelivery}
                    updateQty={this.updateQty}
                    discount={discount}
                    loadingCourierType={loadingCourierType}
                    errorCourierType={errorCourierType}
                    productdiscount={productdiscount}
                    add={this.add}
                  />
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
        {/* <ModalPayment
          open={modalPayment}
          url={paymentUrl}
          close={() => this.changeState('modalPayment', false)}
        /> */}
        <ModalAddAddress
          open={modalAddAddress}
          close={() => this.changeState('modalAddAddress', false)}
          type="add"
          changeState={this.changeState}
          loading={loadingModal}
          error={errorModal}
          add={this.addAddress}
        />
        <ModalChooseAddress
          open={modalChooseAddress}
          data={addresslist}
          changeState={this.changeState}
          activeData={address}
          close={() => this.changeState('modalChooseAddress', false)}
          submit={address => this.changeState('address', address)}
        />
      </Fragment>
    )
  }
}

export default withContext(Cart)
