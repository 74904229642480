import React from 'react';
import { FormGroup } from '../../assets/styles/components';
import { LabelOutlinedStyled, CheckboxStyled } from './styles';

const Checkbox = props => {
    const { label, id, margin, checked, onChange } = props;

    return (
        <FormGroup margin={margin}>
            <div style={{ display: 'flex', alignItems: 'center' }}> {/* Flex container for alignment */}
                <CheckboxStyled
                    type="checkbox"
                    id={id || label}
                    checked={checked}
                    onChange={onChange}
                />
                {label && (
                    <LabelOutlinedStyled htmlFor={id || label}
                            style={{ marginLeft: '8px' }}
                            dangerouslySetInnerHTML={{ __html: label }}>
                    </LabelOutlinedStyled>
                )}
            </div>
        </FormGroup>
    );
}

Checkbox.defaultProps = {
    id: '',
    label: '',
    margin: '0 0 30px',
    checked: false,
};

export default Checkbox;
