import styled from '@emotion/styled'
import { blue, white, breakpoints } from '../../assets/styles/styles'

const Wrapper = styled.div`
  padding: 15px 20px;
  border-radius: 10px;
  margin: ${props => props.margin};
  // box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  background-color: ${white};

  a:hover {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle};
  }
`

const Underline = styled.div`
  border-bottom: 3px solid ${blue};
  margin: 5px 0 20px;
  width: 50%;
`

export { Wrapper, Underline }
