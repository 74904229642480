import styled from '@emotion/styled'
import { blue, breakpoints } from '../../assets/styles/styles'

const OtherWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 24vw;
  @media (max-width: ${breakpoints[0]}) {
    overflow-y: visible;
    max-height: 50vh;
  }
`

export { OtherWrapper }
