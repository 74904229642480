import React from 'react'
import {
  Container as BootstrapContainer,
  Row as BootstrapRow,
  Col as BootstrapCol,
} from 'react-bootstrap'

const Container = ({ margin, padding, backgroundColor, ...props }) => {
  return (
    <BootstrapContainer
      {...props}
      style={{ padding, margin, backgroundColor }}
    />
  )
}

const Row = ({ margin, padding, justifyContent, alignItems, ...props }) => {
  return (
    <BootstrapRow
      {...props}
      style={{ padding, margin, justifyContent, alignItems }}
    />
  )
}

const Col = ({ margin, padding, style, ...props }) => {
  return <BootstrapCol {...props} style={{ padding, margin, ...style }} />
}

export { Container, Row, Col }
