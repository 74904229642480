import React from 'react'
import { Text } from '../../assets/styles/components'
import titlebg from '../../assets/images/bg-title.png'
import { white } from '../../assets/styles/styles'

const PageTitle = ({ text }) => {
  return (
    <div style={{ textAlign: 'center', margin: '0 0 30px' }}>
      <div
        style={{
          backgroundImage: `url('${titlebg}')`,
          display: 'inline-block',
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: 280,
          // padding: '20px 60px',
        }}
      >
        <Text
          fontSize="26px"
          fontWeight="bold"
          textAlign="center"
          isTitle="true"
          color={white}
        >
          {text}
        </Text>
      </div>
    </div>
  )
}

export default PageTitle
