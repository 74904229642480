import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getData(status, datestart, dateend, token) {
    let form = JSON.stringify({
      status,
      datestart,
      dateend,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/get',
      form,
      token,
    )
    return responseJSON
  },

  async getDetails(TransactionId, token) {
    let form = JSON.stringify({
      TransactionId,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/detail',
      form,
      token,
    )
    return responseJSON
  },

  async getTracking(TransactionId, token) {
    let form = JSON.stringify({
      TransactionId,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/tracking',
      form,
      token,
    )
    return responseJSON
  },

  async receive(TransactionId, token) {
    let form = JSON.stringify({
      TransactionId,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/receive',
      form,
      token,
    )
    return responseJSON
  },

  async rate(TransactionId, Rate, token) {
    let form = JSON.stringify({
      TransactionId,
      Rate,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/rate',
      form,
      token,
    )
    return responseJSON
  },

  async review(TransactionDetailId, Rate, Review, token) {
    let form = JSON.stringify({ TransactionDetailId, Rate, Review })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/reviewproduct',
      form,
      token,
    )
    return responseJSON
  },
}
