import React from 'react'
import { Absolute, Icon, Text, Flex } from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import Spinner from '../../../components/Loader/Spinner'
import { orange } from '../../../assets/styles/styles'
import Radio from '../../../components/Radio/Radio'
import { TableAddress } from '../../Profile/styles'
import Button from '../../../components/Button/Button'

const ModalChooseAddress = ({
  open,
  close,
  data,
  activeData,
  submit,
  changeState,
  ...props
}) => {
  return (
    <Modal open={open} width="50%" padding="15px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={close}
        />
      </Absolute>
      <Text fontSize="16px" fontWeight="bold" textAlign="center">
        Choose Address
      </Text>
      <TableAddress>
        <thead>
          <tr>
            <th width="20%">Receiver</th>
            <th width="35%">Delivery Address</th>
            <th width="25%">Postal Code</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((g, i) => (
            <tr
              key={i}
              onClick={() => {
                changeState('address', g)
                close()
              }}
              style={{ cursor: 'pointer' }}
            >
              <td>
                <Text fontWeight="bold">{g.receiver_name || '-'}</Text>
                <Text>{g.receiver_phonenumber || '-'}</Text>
              </td>
              <td>
                <Text fontWeight="bold">{g.name}</Text>
                <Text>{g.address}</Text>
              </td>
              <td>
                <Text textTransform="capitalize">
                  {`${g.location.provinsi}, ${g.location.kabupaten}, ${g.location.kecamatan}, ${g.location.kelurahan}, ${g.location.kodepos}, Indonesia`}
                </Text>
              </td>
              <td style={{ display: 'flex', justifyContent: 'center' }}>
                <Radio
                  margin="0"
                  value={
                    activeData.customer_address_id == g.customer_address_id
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </TableAddress>

      <Flex justifyContent="center">
        <Button
          color="blue"
          padding="8px 20px"
          width="40%"
          margin="20px 0 0"
          mobileStyle={{ width: '100%' }}
          onClick={() => {
            changeState('modalChooseAddress', false)
            changeState('modalAddAddress', true)
          }}
        >
          + Add Address
        </Button>
      </Flex>
    </Modal>
  )
}

export default ModalChooseAddress
