import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getFeatured(token) {
    let responseJSON = await fetchGet(baseURL + '/featuredblog', token)
    return responseJSON
  },

  async getBlogFrame(token) {
    let responseJSON = await fetchGet(
      baseURL + '/pagedescription/frameblog',
      token,
    )
    return responseJSON
  },

  async getRecent(page, token) {
    let responseJSON = await fetchGet(
      baseURL + '/listproductdigital?' + page,
      token,
    )
    return responseJSON
  },

  async getDetails(id, token) {
    let responseJSON = await fetchGet(baseURL + '/productdigital/' + id, token)
    return responseJSON
  },
}
