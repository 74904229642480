import React from 'react'
import { Flex } from '../../assets/styles/components'
import { numberOnly } from '../../utils/format'
import { StepperWrapper, StepperAction, StepperInput } from './styles'

const Stepper = ({ value, onChange }) => {
  return (
    <StepperWrapper>
      <StepperAction
        onClick={() => {
          if (parseInt(value) != 1) onChange((parseInt(value) - 1).toString())
        }}
      >
        -
      </StepperAction>
      <StepperInput
        type="text"
        value={value}
        onChange={e => onChange(numberOnly(e.target.value))}
      />
      <StepperAction onClick={() => onChange((parseInt(value) + 1).toString())}>
        +
      </StepperAction>
    </StepperWrapper>
  )
}

export default Stepper
