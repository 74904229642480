import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import {
  Relative,
  Text,
  Flex,
  FlexCell,
  PageWrapper,
} from '../../assets/styles/components'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import ModalDetails from './components/ModalDetails'
import OrderList from './components/OrderList'
import Footer from '../../components/Footer/Footer'
import { Chip } from './styles'
import ajax from './ajax'
import ajaxCart from '../Cart/ajax'
import Spinner from '../../components/Loader/Spinner'
import ModalReview from './components/ModalReview'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import moment from 'moment'
import ModalTracking from './components/ModalTracking'

class Order extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      activeData: null,
      modalDetails: false,
      modalTracking: false,
      modalReview: false,
      filtereddata: [],
      chips: [
        {
          name: 'All',
          type: '',
          active: true,
        },
        {
          name: 'New',
          type: 1,
        },
        {
          name: 'Processed',
          type: 2,
        },
        {
          name: 'Sent',
          type: 3,
        },
        { name: 'Completed', type: 5 },
      ],
      loadingDetails: false,
      errordataDetails: false,
      date: [],
      tracking: [],
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchDetails = this.fetchDetails.bind(this)
    this.confirm = this.confirm.bind(this)
    this.pay = this.pay.bind(this)
    this.paykredivo = this.paykredivo.bind(this)
    this.review = this.review.bind(this)
    this.viewTracking = this.viewTracking.bind(this)
  }
  componentDidMount() {
    let format = new Date()
    let firstDay = new Date(format.getFullYear(), format.getMonth(), 1)
    let lastDay = new Date(format.getFullYear(), format.getMonth() + 1, 0)
    let date = [firstDay, lastDay]
    this.setState({ date })

    this.checkRoute()

    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    // this.fetchData(firstDay, lastDay)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.date != this.state.date) {
      this.fetchData(this.state.date[0], this.state.date[1])
    }
  }

  handleClick = (jnsbank, transaction_id) =>{
    this.pay(jnsbank, transaction_id)
    this.props.context.update('paymentorderconfirmation', null)
    
  }

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  checkRoute = () => {
    let route = this.props.location.pathname
    route = route.replace(/\D/g, '')
    if (route) this.fetchDetails(route)
  }
  async fetchData(start = this.state.date[0], end = this.state.date[1]) {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    start = moment(start).format('YYYY-MM-DD')
    end = moment(end).format('YYYY-MM-DD')
    let active = this.state.chips.find(g => g.active === true)
    let status = ''
    if (active) status = active.type
    let res = await ajax.getData(status, start, end, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    this.setState({
      data,
      filtereddata: data,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  async fetchDetails(id) {
    const { token } = this.props.context.state
    this.setState({
      loadingDetails: true,
      errordataDetails: false,
      modalDetails: true,
    })
    let res = await ajax.getDetails(id, token)
    if (!res.success) {
      this.setState({ loadingDetails: false, errordataDetails: true })
      return false
    }
    let activeData = res.data
    this.setState({
      activeData,
      loadingDetails: false,
    })
  }

  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async viewTracking(id) {
    const { token } = this.props.context.state
    this.setState({
      loadingDetails: true,
      errordataDetails: false,
      modalTracking: true,
    })
    let res = await ajax.getTracking(id, token)
    if (!res.success) {
      this.setState({ loadingDetails: false, errordataDetails: true })
      return false
    }
    let tracking = res.data
    tracking = tracking.reverse()
    this.setState({
      tracking,
      loadingDetails: false,
    })
  }
  viewReview = data => {
    this.setState({ activeData: data, modalReview: true })
  }
  async confirm(transaction_id) {
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    let res = await ajax.receive(transaction_id, token)
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('success', 'Successfully receive order')
    this.fetchData()
  }
  async review(transaction_detail_id, rate, review) {
    this.setState({ modalReview: false })
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    let res = await ajax.review(transaction_detail_id, rate, review, token)
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('success', 'Thanks for giving your review!')
    this.fetchData()
  }
  async pay(jnsbank, transaction_id) {
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    let res2 = await ajaxCart.snapToken(transaction_id, jnsbank, token)
    if (!res2.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res2.message)
      return false
    }
    this.props.context.update('showLoading', false)

    const snap = window.snap
    snap.pay(res2.data.snaptoken)
    
  }

  async paykredivo(transaction_id){
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
  
      let res3 = await ajaxCart.snapTokenKredivo(transaction_id, token)
      if(res3.data.stt=="OK"){
        this.props.context.update('showLoading', false)
        window.location.href = res3.data.link;
      }else{
        this.props.context.update('showLoading', false)
        this.props.context.update('error', res3.data.message)
        return false
      }
  }
  
  filter = item => {
    let chips = this.state.chips
    chips = chips.map(g => {
      g.active = false
      if (item.name === g.name) g.active = true
      return g
    })
    let filtereddata = [...this.state.data]
    if (item.type !== '')
      filtereddata = filtereddata.filter(g => g.status == item.type)
    this.setState({ chips, filtereddata }, () => this.fetchData())
  }
  render() {
    const {
      loading,
      errordata,
      filtereddata,
      modalDetails,
      activeData,
      chips,
      loadingDetails,
      modalReview,
      errordataDetails,
      tracking,
      modalTracking,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb text={`Home > Order List`} margin="0 0 10px" />

              <Container padding="0">
                <Row justifyContent="space-between" alignItems="center">
                  <Col xs={12} md={8}>
                    <Relative margin="15px 0">
                      {chips.map((g, i) => (
                        <Chip
                          key={i}
                          active={g.active}
                          onClick={() => this.filter(g)}
                        >
                          {g.name}
                        </Chip>
                      ))}
                    </Relative>
                  </Col>
                  <Col xs={12} md={4}>
                    <Flex
                      justifyContent="flex-end"
                      mobileStyle={{
                        marginBottom: 20,
                        justifyContent: 'flex-start',
                      }}
                    >
                      <DateRangePicker
                        onChange={date => this.changeState('date', date)}
                        value={this.state.date}
                        clearIcon={null}
                        format="dd-MM-yyyy"
                      />
                    </Flex>
                  </Col>
                </Row>
              </Container>

              <Relative padding="1em 2em" mobileStyle={{ padding: '0 15px' }}>
                <Container padding="0">
                  <Row margin="0 -15px 30px">
                    {loading ? (
                      <Col
                        md={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Spinner className="fa-2x" />
                      </Col>
                    ) : filtereddata.length == 0 ? (
                      <Col
                        md={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Text>No transaction yet</Text>
                      </Col>
                    ) : (
                      filtereddata.map((g, i) => (
                        <OrderList
                          key={i}
                          data={g}
                          viewDetails={this.fetchDetails}
                          viewTracking={this.viewTracking}
                          viewReview={this.viewReview}
                          confirm={this.confirm}
                          pay={this.pay}
                          paykredivo={this.paykredivo}
                        />
                      ))
                    )}
                  </Row>
                </Container>
              </Relative>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>

        {modalDetails ? (
          <ModalDetails
            open={modalDetails}
            data={activeData}
            loading={loadingDetails}
            errordata={errordataDetails}
            close={() => this.changeState('modalDetails', false)}
            pay={this.pay}
           
          />
        ) : null}

        {modalReview ? (
          <ModalReview
            open={modalReview}
            data={activeData}
            type="order"
            loading={loadingDetails}
            close={() => this.changeState('modalReview', false)}
            submit={this.review}
          />
        ) : null}

        {modalTracking ? (
          <ModalTracking
            open={modalTracking}
            data={tracking}
            loading={loadingDetails}
            errordata={errordataDetails}
            close={() => this.changeState('modalTracking', false)}
          />
        ) : null}
      </Fragment>
    )
  }
}

export default withContext(Order)
