import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Separator,
  BoxShadow,
  Flex,
  Icon,
  FlexCell,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import { black } from '../../assets/styles/styles'

class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getNotification(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.setState({
      data: res.data,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  render() {
    const { loading, errordata, data } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Notification`} margin="0 0 10px" />
              <Container padding="0">
                <Row margin="0 -15px 40px">
                  <Col md={{ span: 8, offset: 2 }}>
                    <BoxShadow padding="10px 15px 15px" borderRadius="10px">
                      <Text
                        fontSize="26px"
                        fontWeight="bold"
                        textAlign="center"
                        margin="0 0 10px"
                      >
                        Notification
                      </Text>
                      <Separator />
                      {loading ? (
                        <Flex justifyContent="center" margin="15px 0">
                          <Spinner className="fa-2x" />
                        </Flex>
                      ) : (
                        data.map((g, i) => (
                          <Fragment key={i}>
                            <a href={g.link}>
                              <Flex
                                justifyContent="space-between"
                                alignItems="flex-start"
                                margin="20px 10px"
                              >
                                {/* <Image className="rounded" src={g.image} width="35px" /> */}
                                <FlexCell flex={0.2}>
                                  <Text textAlign="center">
                                    {g.type === 'transaction' ? (
                                      <Icon
                                        className="fa fa-shopping-bag fa-lg"
                                        color={black}
                                      />
                                    ) : (
                                      <Icon
                                        className="fa fa-bell-o fa-lg"
                                        color={black}
                                      />
                                    )}
                                  </Text>
                                </FlexCell>
                                <FlexCell flex={0.8} margin="0 0 0 7px">
                                  <Text>{g.message}</Text>
                                </FlexCell>
                              </Flex>
                            </a>
                          </Fragment>
                        ))
                      )}
                    </BoxShadow>
                  </Col>
                </Row>
                <Row></Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Notifications)
