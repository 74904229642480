import React from 'react'
import { Flex, Text, Icon } from '../../../assets/styles/components'
import { PaginationNumber } from '../styles'

const Pagination = ({ totalcount, totalpage, fetch, page = 1, orderCount }) => {
  let pagination = []
  let start = page <= 2 ? 1 : page - 2
  let end = start + 4
  if (end > totalpage) end = totalpage
  if (start > 2 && end - start == 3) start -= 1
  if (start > 2 && end - start == 2) start -= 2
  for (let i = start; i <= end; i++) {
    pagination.push(
      <PaginationNumber
        key={i}
        className={page == i ? 'active' : ''}
        onClick={() => {
          if (page == i) return false
          fetch(i)
        }}
      >
        {i}
      </PaginationNumber>,
    )
  }
  return (
    <Flex
      justifyContent="flex-end"
      margin="15px 0"
      mobileStyle={{ margin: '30px 0 20px' }}
    >
      <Text margin="0 15px 0 0">{page==1 ? 1 : ((page-1)*orderCount.value)+1} - {page*orderCount.value} from {totalcount} product</Text>
      <Icon
        className="slick-arrow slick-prev paging-arrow"
        style={{
          position: 'relative',
          transform: 'translate(0,0)',
          fontStyle: 'normal',
          left: 0,
          ':before': {
            lineHeight: '1.2',
          },
        }}
        margin="0 7px"
        opacity={page == 1 ? '0.2' : '1'}
        cursor={page == 1 ? 'not-allowed' : 'pointer'}
        onClick={() => {
          if (page == 1) return false
          fetch(page - 1)
        }}
      />
      {pagination}
      <Icon
        className="slick-arrow slick-next paging-arrow"
        style={{
          position: 'relative',
          transform: 'translate(0,0)',
          fontStyle: 'normal',
          right: 0,
        }}
        margin="0 7px"
        opacity={page == totalpage ? '0.2' : '1'}
        cursor={page == totalpage ? 'not-allowed' : 'pointer'}
        onClick={() => {
          if (page == totalpage) return false
          fetch(page + 1)
        }}
      />
    </Flex>
  )
}

export default Pagination
