import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Flex,
  FlexCell,
  Image,
  Relative,
  InlineBlock,
} from '../../../assets/styles/components'
import Rating from '../../../components/Rating/Rating'
import Button from '../../../components/Button/Button'
import { orange, red, white, blue } from '../../../assets/styles/styles'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Stepper from '../../../components/Stepper/Stepper'
import { Color, ColorBullet } from '../styles'
import Slider from 'react-slick'
import PreviewImage from './PreviewImage'
import { NextArrow, PrevArrow } from '../../../utils/helper'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const ProductDetailsDesc = ({
  data,
  qty,
  changeState,
  color,
  colorList,
  size,
  sizeList,
  activeImage,
  preview,
  add,
  reviewnontrans,
  activeProduct,
  changeSize,
  changeColor,
}) => {
  const [visible, setVisible] = useState(false)
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <Flex margin="0 0 10px">
            <Text margin="0 10px 0 0" fontSize="18px" fontWeight="bold">
              {data.product && data.product.brand && data.product.brand.name} -{' '}
              {data.product && data.product.name}{' '}
            </Text>
            {data.product.rate ? (
              <Rating count={data.product.rate} />
            ) : (
              <Text textAlign="center" color={blue} fontSize="12px">
                Not rated
              </Text>
            )}
          </Flex>
        </Col>
        <Col md={6}>
          <Flex margin="0 0 15px">
            <Image
              // className="rounded"
              src={data.product.brand && data.product.brand.icon}
              height="60px"
            />
          </Flex>
        </Col>
      </Row>
      <Row margin="0 -15px 30px">
        <Col md={6} padding="0">
          <Relative padding="0 30px 0 0" mobileStyle={{ padding: 0 }}>
            <Image
              className="rounded"
              src={
                data.product.picture && data.product.picture[activeImage].url
              }
              width="50%"
              height="40vh"
              objectFit="contain"
              margin="0 auto 20px"
              display="block"
              cursor="pointer"
              onClick={() => {
                setVisible(true)
              }}
              mobileStyle={{ width: '100%' }}
            />
            <Relative
              margin="0 0 0 15px"
              mobileStyle={{ margin: '0 20px 0 20px' }}
            >
              <Slider {...settings}>
                {data.product.picture &&
                  data.product.picture.map((g, i) => (
                    <div
                      key={i}
                      style={{ width: '20%' }}
                      onClick={() => {
                        changeState('activeImage', i)
                      }}
                    >
                      <Relative padding="0 10px">
                        <Image
                          className="rounded"
                          width="100%"
                          height="100px"
                          src={g.url}
                          objectFit="contain"
                          cursor="pointer"
                        />
                      </Relative>
                    </div>
                  ))}
              </Slider>
            </Relative>
          </Relative>
        </Col>
        <Col md={6}>
          <Flex>
            <Text
              color={orange}
              fontSize="20px"
              fontWeight="bold"
              mobileStyle={{ marginTop: 20, fontSize: 24, textAlign: 'center' }}
            >
              Rp{' '}
              {formatMoney(
                calculateDiscount(
                  activeProduct.price,
                  activeProduct.discount_type,
                  activeProduct.discount_value,
                ).price,
              )}
            </Text>
            <Flex
              backgroundColor={red}
              margin="0 15px 0"
              padding="0 5px"
              borderRadius="5px"
            >
              {activeProduct.discount_type == 'Persentase' ? (
                <Text color={white} fontSize="16px" fontWeight="bold">
                  -{activeProduct.discount_value}%
                </Text>
              ) : null}
            </Flex>

            {activeProduct.discount_value ? (
              <Text color="#777" fontSize="16px" textDecoration="line-through">
                Rp {formatMoney(activeProduct.price || 0)}
              </Text>
            ) : null}
          </Flex>

          {colorList.length > 0 ? (
            <Flex margin="25px 0 5px" alignItems="flex-start">
              <FlexCell flex={0.2} mobileStyle={{ flex: 0.3 }}>
                <Text fontSize="15px">Color</Text>
              </FlexCell>
              <FlexCell flex={0.7} mobileStyle={{ flex: 0.8 }}>
                {colorList.map((g, i) => (
                  <InlineBlock
                    key={i}
                    margin="0 0 20px"
                    style={{ maxWidth: 90, verticalAlign: 'top' }}
                  >
                    <Color active={color === g} onClick={() => changeColor(g)}>
                      {/* <ColorBullet backgroundColor={g.hex_code} /> */}
                      <Image
                        className="rounded"
                        src={g.background}
                        width="50px"
                        height="50px"
                        margin="0 auto"
                        display="block"
                      />
                      <Text fontSize="11px" textAlign="center" margin="5px 0 0">
                        {g.name}
                      </Text>
                    </Color>
                  </InlineBlock>
                ))}
              </FlexCell>
            </Flex>
          ) : null}

          {sizeList.length > 0 ? (
            <Flex
              margin={colorList.length == 0 ? '25px 0 5px' : '0 0 5px'}
              alignItems="flex-start"
            >
              <FlexCell flex={0.2} mobileStyle={{ flex: 0.3 }}>
                <Text fontSize="15px">Size</Text>
              </FlexCell>
              <FlexCell flex={0.7} mobileStyle={{ flex: 0.8 }}>
                {sizeList.map((g, i) => (
                  <InlineBlock
                    key={i}
                    margin="0 0 20px"
                    style={{ verticalAlign: 'top' }}
                  >
                    <Color active={size === g} onClick={() => changeSize(g)}>
                      <Text fontSize="11px" textAlign="center">
                        {g.name}
                      </Text>
                    </Color>
                  </InlineBlock>
                ))}
              </FlexCell>
            </Flex>
          ) : null}

          <Flex
            margin={
              colorList.length > 0 && sizeList.length > 0
                ? '0 0 10px'
                : '15px 0 10px'
            }
          >
            <FlexCell flex={0.2} mobileStyle={{ flex: 0.3 }}>
              <Text fontSize="15px">Quantity</Text>
            </FlexCell>
            <FlexCell flex={0.2} mobileStyle={{ flex: 0.3 }}>
              <Stepper value={qty} onChange={e => changeState('qty', e)} />
            </FlexCell>
          </Flex>

          <Flex margin="20px 0 25px">
            {/* <Image
              src={require('../../../assets/images/guarantee.png')}
              height="36px"
              margin="15px 0 25px"
              mobileStyle={{ width: '100%', height: 'auto' }}
            /> */}

            <Image
              src={require('../../../assets/images/icon-guard.png')}
              height="36px"
              margin="0 10px 0 0"
            />
            <Text margin="0 20px 0 0">100% Original</Text>
            <Image
              src={require('../../../assets/images/icon-truck.png')}
              height="36px"
              margin="0 10px 0 0"
            />
            <Text>Free Delivery Fee</Text>
          </Flex>
          {activeProduct.stock && activeProduct.stock.current_stock > 0 ? (
            <Button
              color="orange"
              padding="10px 40px"
              onClick={add}
              mobileStyle={{ margin: '0 auto', display: 'block' }}
            >
              Add to cart
            </Button>
          ) : (
            <Button
              color="disabled"
              padding="10px 40px"
              disabled
              mobileStyle={{ margin: '0 auto', display: 'block' }}
            >
              Out of stock
            </Button>
          )}
        </Col>
      </Row>
      {visible ? (
        <PreviewImage
          src={preview}
          index={activeImage}
          visible={visible}
          close={() => setVisible(false)}
        />
      ) : null}
    </Fragment>
  )
}

export default ProductDetailsDesc
