import styled from '@emotion/styled'
import { breakpoints } from '../../assets/styles/styles'
import bg from '../../assets/images/bgpattern.png'

const Wrapper = styled.div`
	padding-top: calc(105px + ${(props) => props.promo}px);
	// background: linear-gradient(rgba(255,255,255,.95),rgba(255,255,255,.95)),url('${bg}');
	// background-repeat: repeat;
	// background-size: 25%;

  @media (max-width: ${breakpoints[0]}) {
    padding-top: calc(50px + ${(props) => props.promo}px);
  }
`

const LeftStyled = styled.div``

const ContentStyled = styled.div`
  padding: 2.5em 3em;
  overflow: auto;
  width: ${(props) => (props.sidebarActive ? '80%' : '100%')};
  height: calc(100% - ${(props) => (props.searchOrFilter ? '173px' : '63px')});
  position: fixed;
  z-index: 2;
  transition: 0.2s all;
`

const RightStyled = styled.div`
  margin-left: ${(props) => (props.sidebarActive ? '20%' : '0')};
  transition: 0.2s all;
`

export { Wrapper, LeftStyled, ContentStyled, RightStyled }
