import React, { Fragment, useState, useEffect } from 'react'
import {
  Absolute,
  Icon,
  Text,
  Flex,
  Separator,
  Image,
  Relative,
} from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import { Container, Row, Col } from '../../../components/Bootstrap/Bootstrap'
import TextareaOutlined from '../../../components/Input/TextareaOutlined'
import Spinner from '../../../components/Loader/Spinner'
import Button from '../../../components/Button/Button'
import { orange } from '../../../assets/styles/styles'
import { LabelOutlinedStyled } from '../../../components/Input/styles'
import ErrorText from '../../../components/Error/ErrorText'

const ModalReview = ({ open, close, data, type, loading, submit, ...props }) => {
  const [rate, setRate] = useState(0)
  const [rating, setRating] = useState([])
  const [review, setReview] = useState('')
  const [error, setError] = useState('')
  useEffect(() => {
    let newrating = []
    let icon = ''
    for (let i = 0; i < 5; i++) {
      if (i < parseInt(rate)) {
        icon = (
          <Icon
            key={i}
            className="fa fa-heart fa-lg"
            cursor="pointer"
            style={{ fontSize: 24 }}
            color={orange}
            margin="0 5px"
            onClick={() => setRate(i + 1)}
          />
        )
      } else {
        icon = (
          <Icon
            key={i}
            className="fa fa-heart-o fa-lg"
            cursor="pointer"
            style={{ fontSize: 24 }}
            color={orange}
            margin="0 5px"
            onClick={() => setRate(i + 1)}
          />
        )
      }
      newrating.push(icon)
    }
    setRating(newrating)
  }, [rate])
  return (
    <Modal open={open} width="400px" padding="15px 30px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={close}
        />
      </Absolute>
      <Text fontSize="20px" fontWeight="bold" margin="0 0 20px">
        Review Product
      </Text>

      <Container fluid>
        <Row>
          <Col md={12} padding="0">
            <Relative margin="0 0 15px">
              <LabelOutlinedStyled>Rating</LabelOutlinedStyled>
              <Flex justifyContent="center">{rating}</Flex>
            </Relative>

            <TextareaOutlined
              label="Review"
              placeholder="ex: The product is really nice"
              value={review}
              onChange={e => setReview(e.target.value)}
              margin="0 0 15px"
            />

            <ErrorText center margin="0 0 15px">
              {error}
            </ErrorText>
            {type === 'order' && (
              <Button
                color="blue"
                padding="10px 20px"
                block
                disabled={loading}
                onClick={() => {
                  // if (!review) {
                  //   setError('Please fill review')
                  //   return false
                  // }
                  submit(data.transaction_detail_id, rate, review)
                }}
              >
                {loading ? <Spinner /> : 'Give Review'}
              </Button>
            )}

            {type === 'product' && (
              <Button
                color="blue"
                padding="10px 20px"
                block
                disabled={loading}
                onClick={() => {
                  // if (!review) {
                  //   setError('Please fill review')
                  //   return false
                  // }
                  submit(data.product.id, rate, review)
                }}
              >
                {loading ? <Spinner /> : 'Give Review'}
              </Button>
            )}
            
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default ModalReview
