import styled from '@emotion/styled'
import { blue, white, breakpoints } from '../../assets/styles/styles'

const width = '8px'

const ActionWrapper = styled.ul`
  background-color: ${white};
  position: absolute;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  top: 160%;
  z-index: 100;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  transition: all 0.2s;
  right: 0;
	display: block;
	width: 300px;
  max-height: 80vh;
  overflow: auto;
  padding: 12px;
  &:before {
    content: '';
    border-bottom: ${width} solid ${white};
    border-left: ${width} solid transparent;
    border-right: ${width} solid transparent;
    z-index: 101;
    position: absolute;
    top: -${width};
    right: 15px;
    transform: translateX(50%);
  }
  &:after {
    content: '';
    width: ${width};
    height: ${width};
    position: absolute;
    top: -${width};
    right: -${width};
    z-index: 102;
    // background: ${white};
  }

  @media (max-width: ${breakpoints[0]}) {
		// right: auto;
		// left: -10px;
		width: 280px;
		// &:before {
		// 	right: auto;
		// 	left: 5px;
		// }
  }
`

const ActionList = styled.li`
  margin-bottom: 10px;
  list-style: none;
  cursor: pointer;
  position: relative;
  color: ${props => (props.active ? blue : '#a6a6a9')};
  font-weight: 500;
  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

export { ActionWrapper, ActionList }
