import styled from '@emotion/styled'
import { blue } from '../../assets/styles/styles'

const RadioWrapper = styled.div`
  margin: ${props => props.margin};
  cursor: pointer;
`

const RadioStyled = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid ${blue};
  border-radius: 50%;
  position: relative;
`

const RadioFilled = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: ${blue};
`

const RadioGroupWrapper = styled.div`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  margin-right: ${props => props.radioMargin};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`

const LabelStyled = styled.label`
  color: #7f8c8d;
  font-size: 12px;
  font-weight: normal;
  cursor: text;
  margin-bottom: 8px;
  display: block;
`

const RadioTextStyled = styled.label`
  color: #464646;
  margin-left: 10px;
  font-weight: normal;
`

export {
  RadioWrapper,
  RadioStyled,
  RadioFilled,
  LabelStyled,
  RadioGroupWrapper,
  RadioTextStyled,
}
