import React, { Fragment } from 'react'
import {
  Absolute,
  Icon,
  Text,
  Flex,
  Separator,
  Image,
  Relative,
} from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import Spinner from '../../../components/Loader/Spinner'
import { orange, blue } from '../../../assets/styles/styles'
import { Container, Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Button from '../../../components/Button/Button'
import { mapStatus } from './OrderList'
import moment from 'moment'

const ModalTracking = ({ open, close, data, loading, errordata, ...props }) => {
  return (
    <Modal open={open} width="50%" padding="15px 30px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={close}
        />
      </Absolute>
      <Text fontSize="20px" fontWeight="bold" margin="0 0 20px">
        Tracking Pengiriman
      </Text>

      {loading ? (
        <Flex justifyContent="center">
          <Spinner className="fa-2x" />
        </Flex>
      ) : errordata ? (
        <Flex justifyContent="center">
          <Text>Tracking not valid</Text>
        </Flex>
      ) : (
        <Container fluid>
          {data.map((g, i) => (
            <Row key={i} padding="10px 0">
              <Col md={4}>
                <Text textAlign="right" fontSize="13px" fontWeight="bold">
                  {moment(g.date, 'DD-MM-YYYY HH:mm:ss').format(
                    'dddd, DD MMM YYYY',
                  )}
                </Text>
                <Text
                  textAlign="right"
                  fontSize="13px"
                  fontWeight="bold"
                  color="#777"
                >
                  {moment(g.date, 'DD-MM-YYYY HH:mm:ss').format('HH:mm A')}
                </Text>
              </Col>
              <Col md={8}>
                <Relative
                  style={{ borderBottom: '1px solid #e0e0e0', height: '100%' }}
                >
                  <Text>{g.desc}</Text>
                </Relative>
              </Col>
            </Row>
          ))}
        </Container>
      )}
    </Modal>
  )
}

export default ModalTracking
