import React from 'react'
import { ErrorTextStyled } from './styles'

const ErrorText = ({ margin, center, color, children, ...props }) => {
  if (children)
    return (
      <ErrorTextStyled {...props} center={center} margin={margin} color={color}>
        {children}
      </ErrorTextStyled>
    )
  return null
}

export default ErrorText
