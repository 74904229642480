import styled from '@emotion/styled'
import { blue, white, black } from '../../assets/styles/styles'

const Tab = styled.div`
  padding: 4px 15px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  margin-right: 10px;
  font-weight: 500;
  transition: 0.2s;
  background-color: ${(props) => (props.active ? `${blue}` : `${white}`)};
  color: ${(props) => (props.active ? `${white}` : `${black}`)};
  border-radius: 15px;
`

const TableAddress = styled.table`
  width: 100%;
  thead {
    border-bottom: 1px solid #eee;
    th {
      padding: 10px 10px 0 0;
    }
  }
  tbody {
    td {
      padding: 10px 10px 0 0;
      vertical-align: top;
    }
  }
`

const Underline = styled.div`
  border-bottom: 3px solid ${blue};
  margin: 5px 0 20px;
  width: 50%;
`

const Options = styled.ul`
  padding-left: 0;
  list-style: none;
  margin: 0;
  z-index: 10;
  display: none;
  position: absolute;
  margin-bottom: 15px;
  top: 110%;
  left: 0;
  width: 100%;
  transition: 0.3s all ease;
  box-shadow: 2px 3px 5px 0 rgba(101, 96, 96, 0.3);
  &.open {
    display: block;
  }
`

const Option = styled.li`
  padding: 8px 15px;
  cursor: pointer;
  color: #7f8c8d;
  background-color: ${white};
  &:hover {
    background-color: ${blue};
    color: ${white};
  }
`

const NoOption = styled.li`
  padding: 8px 15px;
  color: #7f8c8d;
  background-color: ${white};
  text-align: center;
`

export { Tab, TableAddress, Underline, Options, Option, NoOption }
