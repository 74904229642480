import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import {
  Relative,
  Flex,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import ProductDetailsDesc from './components/ProductDetailsDesc'
import ProductDetailsFooter from './components/ProductDetailsFooter'
import ModalCartAdded from './components/ModalCartAdded'
import ModalReview from '../Order/components/ModalReview'
import Footer from '../../components/Footer/Footer'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import { dummyplaceholder } from '../../utils/apihost'

class ProductDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: {
        product: {},
      },
      datareview: '',
      datacountreview: '',
      qty: 1,
      color: {},
      colorList: [],
      size: {},
      sizeList: [],
      activeImage: '',
      modalCartAdded: false,
      modalReview: false,
      preview: [],
      review: [],
      reviewnontrans: [],
      activeProduct: {},
      variationList: [],
      reviewpage: 1,
      reviewpagenontrans: 1,
      loadmorereview: true,
      loadmorereviewnontrans: true,
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchReview = this.fetchReview.bind(this)
    this.add = this.add.bind(this)
    this.reviewreg = this.reviewreg.bind(this)
    this.fetchReviewNonTrans = this.fetchReviewNonTrans.bind(this)
    this.fetchCheckReview = this.fetchCheckReview.bind(this)
    this.fetchCountReview = this.fetchCountReview.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
    this.fetchReview()
    this.fetchReviewNonTrans()
    this.fetchCheckReview()
    this.fetchCountReview()
    
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  componentDidUpdate(prevProps, prevState) {
    // if (
    //   prevState.color != this.state.color
    //   // ||prevState.size != this.state.size
    // )
    //   this.searchVariation()
    // if (prevProps.location.state.id != this.props.location.state.id)
    // this.fetchData()
  }
  async fetchData() {
    this.setState({ loading: true, errordata: false })
    const { location, context } = this.props
    const { token } = context.state

    const { qty } = this.state
    console.log('Qty :'+qty)

    let id = location.pathname.split('-')
    id = id[id.length - 1]

    let res = await ajax.getProductDetails(id, token || '')
    if (!res.success) {
      window.location.replace('/')
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    let activeImage = ''
    let preview = []
    if (data.product.picture && data.product.picture.length > 0) {
      // activeImage = data.product.picture[0].url
      activeImage = 0
      data.product.picture.map((g) => preview.push(g.url))
    }
    let colorList = data.color
    colorList = colorList.map((g) => {
      res.data.variation.map((h) => {
        if (g.id == h.color_id) {
          let bg = h.avatar ? h.avatar : dummyplaceholder
          g.background = bg
        }
        return h
      })
      return g
    })

    let color = colorList.length > 0 ? colorList[0] : {}

    let sizeList = data.size
    let size = sizeList.length > 0 ? sizeList[0] : {}

    this.setState(
      {
        data,
        activeImage,
        color,
        colorList,
        size,
        sizeList,
        baseSizeList: sizeList,
        preview,
        modalReview: false,
        variationList: res.data.variation,
        loading: false,
      },
      () => this.changeColor(color, size),
    )
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  changeSize = (size) => {
    this.setState({ size }, () => this.searchVariation(this.state.color, size))
  }
  changeColor = (color, size = this.state.size) => {
    const { variationList, baseSizeList } = this.state
    let sizeList = []
    let variation = {}
    variationList.map((g) => {
      if (g.color_id == color.id) {
        variation = {
          ...variation,
          [g.size_id]: g.size_id,
        }
      }
      return g
    })
    sizeList = baseSizeList.filter((g) => {
      if (Object.values(variation).indexOf(g.id) > -1) {
        return g
      }
      return null
    })
    if (sizeList.length > 0) size = sizeList[0]
    this.setState({ sizeList, color, size }, () =>
      this.searchVariation(color, size),
    )
  }
  searchVariation = (color, size) => {
    const { variationList } = this.state
    let activeProduct = variationList.find(
      (g) => g.color_id == color.id && g.size_id == size.id,
    )
    if (!activeProduct) activeProduct = {}
    this.setState({ activeProduct })
  }
  async fetchReview(reviewpage = this.state.reviewpage) {
    this.setState({ loadingReview: true, errordataReview: false })
    const { location, context } = this.props
    const { token } = context.state
    let id = location.pathname.split('-')
    id = id[id.length - 1]
    let res = await ajax.getReview(id, reviewpage, token)
    if (!res.success) {
      this.setState({ loadingReview: false, errordataReview: true })
      return false
    }
    let review = res.data

    if (reviewpage > 1) review = [...this.state.review, ...review]

    this.setState({
      review,
      loadingReview: false,
      reviewpage,
      loadmorereview: res.data.length == 5,
    })
  }

  async fetchReviewNonTrans(reviewpagenontrans = this.state.reviewpagenontrans) {
    this.setState({ loadingReview: true, errordataReview: false })
    const { location, context } = this.props
    const { token } = context.state
    let id = location.pathname.split('-')
    id = id[id.length - 1]
    let res = await ajax.getReviewNonTrans(id, reviewpagenontrans, token)
    if (!res.success) {
      this.setState({ loadingReview: false, errordataReview: true })
      return false
    }
    let reviewnontrans = res.data
    if (reviewpagenontrans > 1) reviewnontrans = [...this.state.reviewnontrans, ...reviewnontrans]
    this.setState({
      reviewnontrans,
      loadingReview: false,
      reviewpagenontrans,
      loadmorereviewnontrans: res.data.length == 5,
    })
  }

  async fetchCheckReview() {
    this.setState({ loadingReview: true, errordataReview: false })
    const { location, context } = this.props
    const { token } = context.state
    let id = location.pathname.split('-')
    id = id[id.length - 1]
    let res = await ajax.getCheckReview(id, token)
    if (!res.success) {
      this.setState({ loadingReview: false, errordataReview: true })
      return false
    }
    let datareview = res.data
    console.log(datareview);
    this.setState({
      datareview,
      loadingReview: false,
    })
  }

  async fetchCountReview() {
    this.setState({ loadingReview: true, errordataReview: false })
    const { location, context } = this.props
    const { token } = context.state
    let id = location.pathname.split('-')
    id = id[id.length - 1]
    let res = await ajax.getCountReview(id, token)
    if (!res.success) {
      this.setState({ loadingReview: false, errordataReview: true })
      return false
    }
    let datacountreview = res.data
    this.setState({
      datacountreview,
      loadingReview: false,
    })
  }
  async add() {
    const { token } = this.props.context.state
    const { data, qty, activeProduct } = this.state

    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    if (qty > activeProduct.stock && activeProduct.stock.current_stock) {
      this.props.context.update('error', 'Out of stock')
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajax.addToCart(
      activeProduct.id ? activeProduct.id : '',
      qty,
      token,
    )

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', activeProduct)
    this.props.context.update('refetchCart', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  viewReview = data => {
    this.setState({ activeData: data, modalReview: true })
  }
  async reviewreg(product_id, rate, review) {
    this.changeState('modalReview', false)
    this.setState({ modalReview: false })
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    let res = await ajax.review(product_id, rate, review, token)
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('success', 'Thanks for giving your review!')
    this.fetchReviewNonTrans();
    this.fetchData();
  }
  render() {
    const {
      loading,
      errordata,
      data,
      qty,
      color,
      colorList,
      size,
      sizeList,
      activeImage,
      modalCartAdded,
      preview,
      datareview,
      datacountreview,
      modalReview,
      loadingReview,
      loadmorereviewnontrans,
      review,
      reviewreg,
      reviewnontrans,
      activeProduct,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb
                text={`Home > ${data.product && data.product.name}`}
                margin="0 0 10px"
              />
              <Container padding="0">
                {loading ? (
                  <Flex justifyContent="center">
                    <Spinner className="fa-2x" />
                  </Flex>
                ) : (
                  <ProductDetailsDesc
                    data={data}
                    qty={qty}
                    changeState={this.changeState}
                    color={color}
                    size={size}
                    colorList={colorList}
                    sizeList={sizeList}
                    activeImage={activeImage}
                    activeProduct={activeProduct}
                    add={this.add}
                    preview={preview}
                    reviewnontrans={reviewnontrans}
                    changeSize={this.changeSize}
                    changeColor={this.changeColor}
                  />
                )}
                <ProductDetailsFooter
                  data={data}
                  loading={loadingReview}
                  review={review}
                  datareview={datareview}
                  datacountreview={datacountreview}
                  reviewnontrans={reviewnontrans}
                  fetchData={this.fetchReview}
                  fetchDataNonTrans={this.fetchReviewNonTrans}
                  viewReview={this.viewReview}
                  loadmore={this.state.loadmorereview}
                  loadmorenontrans={loadmorereviewnontrans}
                  page={this.state.reviewpage}
                  pagenontrans={this.state.reviewpagenontrans}
                />
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
        {modalReview ? (
          <ModalReview
            open={modalReview}
            data={data}
            type={'product'}
            loading={loading}
            close={() => this.changeState('modalReview', false)}
            submit={this.reviewreg}
          />
        ) : null}
        {/* <ModalCartAdded
          open={modalCartAdded}
          activeImage={activeImage}
          data={data}
          close={() => this.changeState('modalCartAdded', false)}
        /> */}
      </Fragment>
    )
  }
}

export default withContext(ProductDetails)
