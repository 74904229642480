import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { ModalStyled, ModalOverlay, ModalBody } from './styles'

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open)
      document.body.style.overflow = 'hidden'
    if (prevProps.open && !this.props.open)
      document.body.style.overflow = 'auto'
  }

  render() {
    const {
      open,
      children,
      className,
      overlayzIndex,
      padding,
      width,
      maxHeight,
      overlayColor,
      backgroundColor,
      mobileStyle,
    } = this.props
    return (
      <ModalStyled className={classNames({ open })}>
        <ModalOverlay
          className={classNames({ open })}
          zIndex={overlayzIndex}
          color={overlayColor}
        />
        <ModalBody
          className={classNames('modal-body', className)}
          padding={padding}
          width={width}
          maxHeight={maxHeight}
          backgroundColor={backgroundColor}
          mobileStyle={mobileStyle}
          zIndex={parseInt(overlayzIndex) + 1}
        >
          {children}
        </ModalBody>
      </ModalStyled>
    )
  }
}

Modal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  padding: PropTypes.string,
  overlayzIndex: PropTypes.number,
}

Modal.defaultProps = {
  open: false,
  className: '',
  padding: '2em 2.5em 2.5em',
  overlayzIndex: 30,
  width: '550px',
}

export default Modal
