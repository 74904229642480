import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Flex,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import PageTitle from '../../components/PageTitle/PageTitle'
import bg from '../../assets/images/bg-about.png'

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: 'About DAN+DAN',
      description: '',
      picture: '',
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }

  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getData(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.setState({
      name: res.data.name,
      description: res.data.description,
      picture: res.data.picture,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  render() {
    const { name, description, picture, loading, errordata } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 3em"
              mobileStyle={{ padding: '15px 20px' }}
              className="about"
            >
              <Breadcrumb text={`Home > About Dandan`} margin="0 0 10px" />

              {loading ? (
                <Flex justifyContent="center">
                  <Spinner className="fa-2x" />
                </Flex>
              ) : (
                <Container padding="0">
                  <Row>
                    <Col md={12}>
                      {/* <Text
                    fontSize="26px"
                    fontWeight="bold"
                    textAlign="center"
                    margin="0 0 40px"
                    mobileStyle={{ marginBottom: 20 }}
                  >
                    {name}
                  </Text> */}
                      <PageTitle text={name} />
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md={5} xs={12} lg={{ offset: 2, span: 3 }}>
                      <Image
                        className="rounded"
                        src={picture}
                        width="100%"
                        objectFit="contain"
                        mobileStyle={{ marginBottom: 20 }}
                      />
                    </Col> */}
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                      <Relative
                        style={{
                          background: `linear-gradient(rgba(255,255,255,.8),rgba(255,255,255,.8)),url('${bg}')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '70%',
                          backgroundPosition: 'center 0%',
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      </Relative>
                    </Col>
                  </Row>
                </Container>
              )}
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(About)
