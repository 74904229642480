import styled from '@emotion/styled'
import {
  blue,
  white,
  grey,
  black,
  breakpoints,
} from '../../assets/styles/styles'
import { Link } from 'react-router-dom'

const width = '8px'

const ActionWrapper = styled.div`
  background-color: ${white};
  position: absolute;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  top: 130%;
  z-index: 100;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  transition: all 0.2s;
  right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 300px;
  padding: 15px 20px;
  &:before {
    content: '';
    border-bottom: ${width} solid ${white};
    border-left: ${width} solid transparent;
    border-right: ${width} solid transparent;
    z-index: 101;
    position: absolute;
    top: -${width};
    right: 15px;
    transform: translateX(50%);
  }
  &:after {
    content: '';
    width: ${width};
    height: ${width};
    position: absolute;
    top: -${width};
    right: -${width};
    z-index: 102;
    // background: ${white};
	}
	
  @media (max-width: ${breakpoints[0]}) {
		width: 260px;
	}
`

const UserMenuList = styled(Link)`
  border-bottom: 1px dashed #999;
  padding: 10px 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  color: ${black};
  width: 100%;
  &:hover {
    text-decoration: none;
    color: ${blue};
  }
`

const UserWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${props => (props.active ? white : 'transparent')};
`

export { ActionWrapper, UserMenuList, UserWrapper }
