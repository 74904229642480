import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getHomepage(token) {
    let responseJSON = await fetchGet(baseURL + '/homepage', token)
    return responseJSON
  },

  async getSlider(token) {
    let responseJSON = await fetchGet(baseURL + '/slider', token)
    return responseJSON
  },

  async getPromoText(token) {
    let responseJSON = await fetchGet(
      baseURL + '/pagedescription/promosi',
      token,
    )
    return responseJSON
  },

  async getVideoSlider(token) {
    let responseJSON = await fetchGet(baseURL + '/videoslider', token)
    return responseJSON
  },

  async getBestChoice(token) {
    let responseJSON = await fetchGet(baseURL + '/sponsoredcontent', token)
    return responseJSON
  },

  async getFeaturedProduct(token) {
    let responseJSON = await fetchGet(baseURL + '/featuredproduct', token)
    return responseJSON
  },

  async getProductGroup(token) {
    let responseJSON = await fetchGet(baseURL + '/productgroup', token)
    return responseJSON
  },

  async getFooterSocialMedia(token) {
    let responseJSON = await fetchGet(baseURL + '/socialmedia', token)
    return responseJSON
  },

  async getFooterCourier(token) {
    let responseJSON = await fetchGet(baseURL + '/courier', token)
    return responseJSON
  },

  async getFooterContact(token) {
    let responseJSON = await fetchGet(baseURL + '/contactinfo', token)
    return responseJSON
  },

  async getFooter(token) {
    let responseJSON = await fetchGet(baseURL + '/footer', token)
    return responseJSON
  },

  async getCategoryMenu(token) {
    let responseJSON = await fetchGet(baseURL + '/menucategory', token)
    return responseJSON
  },

  async getMenu(token) {
    let responseJSON = await fetchGet(baseURL + '/menu', token)
    return responseJSON
  },

  async getCategoryDigital(token) {
    let responseJSON = await fetchGet(baseURL + '/categorydigital', token)
    return responseJSON
  },

  async getLatestProduct(token) {
    let form = JSON.stringify({})
    let responseJSON = await fetchPost(
      baseURL + '/latestproduct?Page=1',
      form,
      token,
    )
    return responseJSON
  },

  async createHelp(name, email, message, token) {
    let form = JSON.stringify({ name, email, message })
    let responseJSON = await fetchPost(baseURL + '/createhelp', form, token)
    return responseJSON
  },
}
