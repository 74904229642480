import styled from '@emotion/styled'
import { white, orange } from '../../assets/styles/styles'

const CopyrightStyled = styled.div`
  background-color: ${orange};
  color: ${white};
  text-align: center;
  padding: 0.5em 2em;
  font-size: 11px;
  font-weight: bold;
`

export { CopyrightStyled }
