import React from 'react'
import { FormGroup } from '../../assets/styles/components'
import { LabelOutlinedStyled, StyledDateinput } from './styles'

const Dateinput = props => {
    const { label, id, margin } = props
    return (
        <FormGroup margin={margin}>
            {label ? (
                <LabelOutlinedStyled htmlFor={id || label}>
                    {label}
                </LabelOutlinedStyled>
            ) : null}
            <StyledDateinput
                {...props}
                id={id || label}
                padding={props.padding}
            />
        </FormGroup>
    )
}

Dateinput.defaultProps = {
    id: '',
    type: 'date',
    label: '',
    margin: '0 0 30px',
    padding: '8px 12px',
    width: '400px'
}

export default Dateinput