import React, { Fragment, useContext } from 'react'
import { Text } from '../../assets/styles/components'

const Breadcrumb = ({ text, margin, ...props }) => {
  return (
    <Fragment>
      <Text fontWeight="500" color="#666" fontSize="12px" margin={margin}>
        {text}
      </Text>
    </Fragment>
  )
}

export default Breadcrumb
