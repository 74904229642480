import React, { Fragment } from 'react'
import {
  MenuHeader,
  MenuInline,
  MenuWrapper,
  MenuWrapperRight,
  MenuFirst,
  MenuFirstList,
  MenuSecond,
  MenuSecondList,
  MenuThird,
  MenuThirdList,
  MenuHeaderMobile,
} from './styles'
import { white, blue, black, breakpoints } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  Icon,
  Relative,
  Separator,
  Overlay,
} from '../../assets/styles/components'
import { Redirect } from 'react-router-dom'
import dummy from '../../utils/dummy'
import Masonry from 'react-masonry-component'
import ajax from '../../routes/Home/ajax'
import { withContext } from '../../utils/helper'
import MediaQuery from 'react-responsive'
import MenuMobile from './MenuMobile'
import SubMenu from './SubMenu'

const masonryOptions = {
  transitionDuration: 0,
}

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      open1: false,
      firstopen: false,
      firstopen1: false,
      secondopen: false,
      secondopen1: false,
      third: '',
      
      categories: [],
      catdigital:[],
      subcategory:[],
      subcategories: [],
      subthirdcategories: [],
      redirect: '',
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.fetchData()
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  async fetchData() {
    const { token, refetchMenu } = this.props.context.state
    if (!refetchMenu) return false
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getMenu(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let res1 = await ajax.getCategoryDigital(token)
  
    // let catdigital = res1.data
    // console.log(catdigital)
    


    let menu = [
      { name: 'Categories' },
      ...res.data,
      
    ]

    this.setState({ loading: false,  catdigital: res1.data})
    this.props.context.update('menu', menu)
    this.props.context.update('refetchMenu', false)
  }
  handleClickOutside = (event) => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.setState({ open: false, third: '' })
    }
  }

  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    const {
      subcategories,
      subthirdcategories,
      catdigital,
      subcategory,
      open,
      open1,
      firstopen,
      firstopen1,
      secondopen,
      secondopen1,
      third,
      redirect,
    } = this.state
    const { categoriesmenu, categorydigital, menu } = this.props.context.state
    if (redirect) return <Redirect to={redirect} push />
    return (
      <Relative>
      
        <MediaQuery minWidth={breakpoints[0]}>
          <MenuHeader>
            {menu.map((g, i) => (
              <MenuInline
                key={i}
                onMouseEnter={() => {
                  if (!g.link) {
                    this.changeState('open', true)
                    this.changeState('open1', false)
                    return false
                  }

                }}
                onClick={() => {
                  if (!g.link) {
                    this.changeState('open', !open)
                    return false
                  }

                
                  if (this.props.location.pathname == g.link) return false
                  window.location.replace(g.link)
                  // this.props.history.push(g.url)
                }}
              >
                <Text
                  
                  fontSize="14px"
                  isTitle="true"
                  className='menu-title'
                >
                  {g.name}
                </Text>

                {g.name == 'Categories' && <SubMenu categories={categoriesmenu} subcategories={subcategories} subthirdcategories={subthirdcategories} secondopen={secondopen} open={open} third={third} context={this.props.context} location={this.props.location} changeState={this.changeState}/>}

                
              </MenuInline>
            ))}
          </MenuHeader>
        </MediaQuery>

        <MenuMobile
          mobileMenu={this.props.mobileMenu}
          changeState={this.props.changeState}
          menu={this.state.menu}
          categories={this.state.categories}
          location={this.props.location}
        />
      </Relative>
    )
  }
}

export default withContext(Menu)
