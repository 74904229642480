import React, { useContext } from 'react'
import { Accordion, Card} from 'react-bootstrap'
import { Container} from '../../components/Bootstrap/Bootstrap'
import { Absolute, Icon, Text, Flex,  Relative, Image, BoxShadow } from '../../assets/styles/components'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import { AppContext } from '../../context/AppContext'

const ModalPaymentOrder = () => {
  const { state, update } = useContext(AppContext)
  const Cardhead = {
    fontSize:"12px !important",
    backgroundColor:"rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)"

  }
  return state.paymentorderconfirmation ? (
    <Modal open={state.paymentorderconfirmation} width="50%" padding="15px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={() => {
            update('paymentorderconfirmation', null)
          }}
        />
      </Absolute>
      <Text
        fontSize="18px"
        fontWeight="bold"
        margin="10px 0 0"
        textAlign="center"
      >
        Pilih Jenis Pembayaran?
      </Text>

      <Button
          color="orange"
          padding="6px 20px"
          margin="10px 10px 0px 0px"
          block 
        >
            Bank Transfer / Credit Card / Gopay / CS Store
        </Button>
      
        <Accordion>
          <Accordion.Item style={Cardhead} eventKey="0">
            <Accordion.Header>Bank Transfer</Accordion.Header>
            <Accordion.Body>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('bca_va', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                 update('paymentorderconfirmation', null)
                }}  style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-bca.png')}
                      height={20} margin-left={10 }
                  />
                  </Card.Header>
              </Card>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('bca_va', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                update('paymentorderconfirmation', null)
                }} style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-mandiri.png')}
                      height={20} margin-left={10 }
                  />
                  </Card.Header>
              </Card>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('bni_va', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                update('paymentorderconfirmation', null)
                
                }} style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-bni.png')}
                      height={20} margin-left={10 }
                  />
                  </Card.Header>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item style={Cardhead} eventKey="1">
            <Accordion.Header>Credit Card</Accordion.Header>
            <Accordion.Body>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('credit_card', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                
                }} style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-visa.png')}
                      height={20} margin-left={10 }
                  /> / <Image
                  src={require('../../assets/images/logo-mastercard.png')}
                  height={20} margin-left={10 }
              />
                  </Card.Header>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item style={Cardhead} eventKey="2">
            <Accordion.Header>E-Money</Accordion.Header>
            <Accordion.Body>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('gopay', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                
                }} style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-gopay.png')}
                      height={20} margin-left={10 }
                  />
                  </Card.Header>
              </Card>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('shopeepay', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                
                }} style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-shopeepay.png')}
                      height={20} margin-left={10 }
                  />
                  </Card.Header>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item style={Cardhead} eventKey="3">
            <Accordion.Header>Store</Accordion.Header>
            <Accordion.Body>
              <Card onClick={() => {
                state.paymentorderconfirmation.hClick('alfamart', state.paymentorderconfirmation.text)
                //state.paymentorderconfirmation.submit()
                
                }} style={{ padding:"5px 6px" }}>
                <Card.Header>
                  <Image
                      src={require('../../assets/images/logo-alfagroup.png')}
                      height={20} margin-left={10 }
                  />
                  </Card.Header>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      {/* <ul class="list-group">
        <li class="list-group-item" style={{padding: ".30rem 1.25rem"}}> <Image
            src={require('../../assets/images/logo-bni.png')}
             height={25} margin-left={10 }
          /> <Image
          src={require('../../assets/images/logo-bca.png')}
         height={30} margin-left={10}
        /> <Image
        src={require('../../assets/images/logo-mandiri.png')}
       height={30} margin-left={10}
      /> 
          </li>
        <li class="list-group-item" style={{padding: ".30rem 1.25rem"}}><Image
            src={require('../../assets/images/logo-mastercard.png')}
            height={30} margin-left={10}
          /><Image
          src={require('../../assets/images/logo-visa.png')}
           height={25} margin-left={10}
        />
          </li>
        <li class="list-group-item" style={{padding: ".30rem 1.25rem"}}><Image
            src={require('../../assets/images/logo-gopay.png')}
            height={25} margin-left={10}
          /></li>
        <li class="list-group-item" style={{padding: ".30rem 1.25rem"}}><Image
            src={require('../../assets/images/logo-alfagroup.png')}
            height={30} margin-left={10}
          /></li>
      </ul> */}
      
       
        <Button
          color="orange"
          padding="6px 20px"
          margin="10px 10px 0px 0px"
          block

        >
          Cicilan / Pay Later
        </Button>
        <Card onClick={() => {
                state.paymentorderconfirmation.hClick('kredivo')
                //state.paymentorderconfirmation.submit()
                
                }} style={{ padding:"5px 6px" }}>
           <Card.Header>
            <Image
                src={require('../../assets/images/logo-kredivo.png')}
                height={22} margin-left={10 }
            />
            </Card.Header>
        </Card>
        {/* <ul class="list-group">
            <li class="list-group-item" style={{padding: ".30rem 1.25rem"}}> <Image
                src={require('../../assets/images/logo-kredivo.png')}
                height={30} margin-left={10 }
            />
          </li>
        </ul> */}
      <Flex margin="30px 0 0" justifyContent="center">
        {/* <Button
          color="ghost-grey"
          padding="8px 20px"
          margin="0 10px"
          block
          onClick={() => {
            update('paymentorderconfirmation', null)
          }}
        >
          Cancel
        </Button> */}
       
      </Flex>
    </Modal>
  ) : null
}

export default ModalPaymentOrder
