import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import {
  Relative,
  BoxShadow,
  Text,
  Image,
  Flex,
  Separator,
  FlexCell,
  PageWrapper,
  Icon,
} from '../../assets/styles/components'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import CartLeft from './components/CartLeft'
import CartRight from './components/CartRight'
//import ModalPayment from './components/ModalPayment'
import ajax from './ajax'
import ajaxAddress from '../Profile/ajax'
import ModalAddAddress from '../Profile/components/ModalAddAddress'
//import ModalPayment from '../../components/Loader/ModalPayment'
import ModalKalkulator from './components/ModalKalkulator'
import ModalChooseAddress from './components/ModalChooseAddress'

import { Redirect } from 'react-router-dom'
import { formatMoney, calculateDiscount } from '../../utils/format'
import { orange, red, white, black } from '../../assets/styles/styles'
import Footer from '../../components/Footer/Footer'
import Button from '../../components/Button/Button'
import Tooltip from 'rc-tooltip'

class CartConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      courier: {},
      promo: '',
      promolist: [],
      idlist : [],
      //modalPayment: false,
      paymentUrl: '',
      totalprice: 0,
      address: {},
      redirect: '',
      jenis: 'Test',
      bcastt:false,
      jenisbank: '',
      discount: 0,
      productdiscount: 0,
      activeData:null,
      modalKalkulators: false,
      loadingDetails: false,
      errordataDetails: false,
    }
    this.submit = this.submit.bind(this)
    this.kalkulatoKredivo = this.kalkulatoKredivo.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    //this.setState('jenisbank', this.state.jenisbank);
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
    //this.setState('jenisbank', this.state.jenisbank);
  }

  handleClick = (jnsbank) =>{
    this.submit(jnsbank)
    this.props.context.update('paymentconfirmation', null)
    this.changeState('redirect', '/order')
    
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.data != this.state.data) {
  //     let totalprice = 0
  //     let discount = 0
  //     let productdiscount = 0
  //     this.state.data.map(g => {
  //       if (g.checked) {
  //         totalprice += parseInt(g.price) * parseInt(g.qty)
  //         discount +=
  //           calculateDiscount(g.price, g.discount_type, g.current_discount)
  //             .discount * parseInt(g.qty)
  //       }
  //       return g
  //     })
  //     productdiscount = discount
  //     if (this.state.promo) {
  //       discount += parseInt(
  //         ((totalprice - discount) *
  //           parseInt(this.state.promo.discount_percentage || 0)) /
  //           100 +
  //           parseInt(this.state.promo.discount_amount || 0) +
  //           parseInt(this.state.promo.discount_delivery || 0),
  //       )
  //     }
  //     totalprice -= discount
  //     this.setState({ totalprice, discount, productdiscount })
      
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      let totalprice = 0
      let discount = 0
      let productdiscount = 0
      let amountdiscountvalue = 0
      let deliverydiscountvalue = 0
      let discountpercentvalue = 0
    
      // Calculate total price and product-specific discounts based on data items
      this.state.data.forEach(item => {
        if (item.checked) {
          totalprice += parseInt(item.price) * parseInt(item.qty)
          discount += calculateDiscount(item.price, item.discount_type, item.current_discount).discount * parseInt(item.qty)
        }
      })
    
      // Store the product discount separately for later use
      productdiscount = discount
      // Apply percentage-based discounts from promolist
      this.state.promolist.filter(promo => promo[3] === 'percentage')
      .forEach(promo => {
        const percentageDiscount = promo[2]
        discountpercentvalue += Math.floor((totalprice-productdiscount) * (percentageDiscount / 100))
      })
      
      discount += discountpercentvalue
      
      // Calculate fixed amount discounts
      
      const discountamount = this.state.promolist.filter(promo => promo[3] === 'amount')
      .forEach(promo => {
        const amountDiscount = promo[2]
        amountdiscountvalue += amountDiscount
      })

      discount += amountdiscountvalue
      

      // const discountamount = this.state.promolist
      //   .filter(promo => promo[3] === 'amount')
      //   .reduce((sum, promo) => sum + promo[2], 0)
    
      // Calculate delivery discount
      // const discountdelivery = this.state.promolist
      //   .filter(promo => promo[3] === 'delivery')
      //   .reduce((sum, promo) => sum + promo[2], 0)

        const discountdelivery = this.state.promolist.filter(promo => promo[3] === 'delivery')
        .forEach(promo => {
          const deliveryDiscount = promo[2]
          deliverydiscountvalue += deliveryDiscount
        })
        if(this.state.deliveryprice < deliverydiscountvalue){
          deliverydiscountvalue = this.state.deliveryprice
        }
        discount += deliverydiscountvalue
    
      // Apply delivery discount if it's less than delivery price
      // if (this.state.deliveryprice > discountdelivery) {
      //   discount += discountdelivery
      // }
    
      // Apply fixed amount discount only if totalprice is greater
      // if (totalprice > discountamount) {
      //   discount += discountamount
      // }
        
      // Calculate the final total price after all discounts
      const finalprice = totalprice - discount
    
      // Update state with calculated values
      this.setState({
        totalprice: finalprice,
        discount: Math.max(discount, 0),
        productdiscount
      })
    }
  }
    
  
  
// disini problematik
  fetchData = () => {
    if (!this.props.location.state) {
      this.setState({ redirect: '/cart' })
      return false
    }
    const { data, address, courier, promo, promolist } = this.props.location.state

    this.setState({ data, address, courier, promo, promolist })
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async submit(jnsbank) {
    //this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    const { address, courier, promolist, idlist, jenis} = this.state
    this.state.promolist.forEach(promo => {
      if(promo[0]){
        idlist.push(promo[0])
      }
    })
    let res = await ajax.createTransaction(
      address.customer_address_id,
      courier.courier_service_id,
      idlist ? idlist : [],
      token,
    )
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('refetchCart', true)
    //kredivo
    if(jenis=="Bank"){
      if(jnsbank=="kredivo"){
        let res3 = await ajax.snapTokenKredivo(res.data.transaction_id, token)
        this.props.context.update('showLoading', false)
        window.location.href = res3.data.link;
      }else{
        let res2 = await ajax.snapToken(res.data.transaction_id, jnsbank, token)
        if (!res2.success) {
          this.props.context.update('showLoading', false)
          this.props.context.update('error', res2.message)
          return false
        }
        this.props.context.update('showLoading', false)
    
        const snap = window.snap
        snap.pay(res2.data.snaptoken)
      }
     
    }
   
  }
  async kalkulatoKredivo(){
    const { token } = this.props.context.state
    const { address, courier, promolist} = this.state
    let voucherid = promolist ? promolist.map(subArray => subArray[0]) : ''    
    this.props.context.update('showLoading', true)
    let res = await ajax.checkTransaction(
      address.customer_address_id,
      courier.courier_service_id,
      voucherid ? voucherid : '',
      token,
    )
    
    if(Object.keys(res).length==2) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', "Silahkan coba beberapa saat lagi")
      return false
    }else if(res.data.stt=="OK"){
      
      this.setState({
        loadingDetails: true,
        errordataDetails: false,
        modalKalkulators: true,
      })
  
      let activeData = res.data
      this.setState({
        activeData,
        loadingDetails: false,
      })
      this.props.context.update('showLoading', false)
    }else{
     
    }
    
    
  }
  render() {
    const {
      loading,
      errordata,
      data,
      courier,
      address,
      promo,
      jenis,
      jenisbank,
      totalprice,
      redirect,
      discount,
      modalKalkulators,
      activeData,
      loadingDetails,
      errordataDetails,
      productdiscount,
    } = this.state
    if (redirect) return <Redirect to={redirect} />
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb
                text={`Home > Shopping Cart > Checkout Confirmation`}
                margin="0 0 10px"
              />
              <Container padding="0">
                <Row margin="0 -15px 30px">
                  <Col md={{ span: 8, offset: 2 }}>
                    <BoxShadow
                      padding="20px"
                      borderRadius="10px"
                      backgroundColor={white}
                    >
                      <Text fontSize="26px" fontWeight="bold" margin="0 0 15px">
                        Checkout Confirmation
                      </Text>

                      <Row>
                        <Col xs={12} md={6}>
                          <Flex
                            margin="0 0 15px"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <FlexCell flex={0.4}>
                              <Text>Send to</Text>
                            </FlexCell>
                            <FlexCell flex={0.6}>
                              <Text
                                fontSize="15px"
                                fontWeight="bold"
                                mobileStyle={{
                                  textAlign: 'right',
                                }}
                              >
                                {address.name}
                              </Text>
                              <Text
                                fontSize="14px"
                                margin="5px 0 0"
                                mobileStyle={{
                                  textAlign: 'right',
                                }}
                              >
                                {address.address}
                              </Text>
                            </FlexCell>
                          </Flex>

                          <Flex
                            margin="0 0 15px"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <FlexCell flex={0.4}>
                              <Text>Delivery</Text>
                            </FlexCell>
                            <FlexCell flex={0.6}>
                              <Text
                                fontSize="15px"
                                mobileStyle={{
                                  textAlign: 'right',
                                }}
                              >
                                {courier.fullname}
                              </Text>
                            </FlexCell>
                          </Flex>

                          <Flex
                            margin="0 0 15px"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <FlexCell flex={0.4}>
                              <Text>Promo Code</Text>
                            </FlexCell>
                            <FlexCell flex={0.6}>
                              {this.state.promolist && this.state.promolist.length > 0 ? (
                                this.state.promolist.map((promo) => (
                                  <Text
                                    key={promo[0]} // Using the first element as the unique key
                                    fontSize="15px"
                                    fontWeight="bold"
                                    mobileStyle={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    {promo[1]} {/* This is the promo code, e.g., PST2024123 */}
                                  </Text>
                                ))
                              ) : (
                                <Text
                                  fontSize="15px"
                                  fontWeight="bold"
                                  mobileStyle={{
                                    textAlign: 'right',
                                  }}
                                >
                                  Not using promo code
                                </Text>
                              )}
                            </FlexCell>

                          </Flex>
                        </Col>

                        <Col xs={12} md={6}>
                          <Flex margin="0 0 5px" justifyContent="space-between">
                            <Text>Delivery Price</Text>
                            <Text
                              fontSize="16px"
                              fontWeight="bold"
                              color="#777"
                            >
                              Rp {formatMoney(courier.price || 0)}
                            </Text>
                          </Flex>

                          <Flex
                            margin={discount > 0 ? '0 0 5px' : '0 0 10px'}
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <FlexCell flex={0.5}>
                              <Text>Total Price</Text>
                            </FlexCell>
                            <FlexCell flex={0.5}>
                              <Text
                                fontSize="16px"
                                fontWeight="bold"
                                color="#777"
                                textAlign="right"
                              >
                                Rp {formatMoney(totalprice + discount)}
                              </Text>
                              {discount > 0 ? null : (
                                <Separator margin="10px 0 0" />
                              )}
                            </FlexCell>
                          </Flex>

                          {discount > 0 ? (
                            <Flex
                              margin="0 0 10px"
                              justifyContent="space-between"
                            >
                              <FlexCell flex={0.5}>
                                <Text>Potongan Diskon</Text>
                              </FlexCell>
                              <FlexCell flex={0.5}>
                                <Text
                                  fontSize="16px"
                                  fontWeight="bold"
                                  color={red}
                                  textAlign="right"
                                >
                                  - Rp {formatMoney(discount)}
                                  <Tooltip
                                    placement="top"
                                    trigger={['hover']}
                                    overlay={
                                      <div>
                                        <div>
                                          Product Discount : Rp{' '}
                                          {formatMoney(productdiscount)}
                                        </div>
                                        {promo.discount_delivery ? (
                                          <div>
                                            Delivery Discount : Rp{' '}
                                            {formatMoney(
                                              promo.discount_delivery || 0,
                                            )}
                                          </div>
                                        ) : null}
                                        {promo.discount_percentage > 0 ||
                                        promo.discount_amount > 0 ? (
                                          <div>
                                            Voucher Discount : Rp{' '}
                                            {formatMoney(
                                              parseInt(
                                                promo.discount_percentage || 0,
                                              ) /
                                                100 +
                                                parseInt(
                                                  promo.discount_amount || 0,
                                                ),
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    }
                                  >
                                    <Icon
                                      className="fa fa-info-circle"
                                      cursor="pointer"
                                      style={{ marginLeft: 5, color: black }}
                                    />
                                  </Tooltip>
                                </Text>
                                <Separator margin="10px 0 0" />
                              </FlexCell>
                            </Flex>
                          ) : null}

                          <Flex
                            margin="0 0 10px"
                            justifyContent="space-between"
                          >
                            <Text>Total Price</Text>
                            <Text fontSize="16px" fontWeight="bold">
                              Rp{' '}
                              {formatMoney(totalprice + courier.price)}
                            </Text>
                          </Flex>
                        </Col>
                      </Row>

                      <Text
                        fontWeight="bold"
                        fontSize="15px"
                        margin="15px 0 10px"
                      >
                        Order Items
                      </Text>

                      {data.map((g, i) => (
                        <Fragment key={i}>
                          <Row
                            alignItems="center"
                            margin="15px -15px"
                            padding="0 15px"
                          >
                            <Col md={1}>
                              <Image
                                className="rounded"
                                src={g.avatar}
                                width="100%"
                                mobileStyle={{ marginBottom: 15, width: '50%' }}
                              />
                            </Col>
                            <Col md={5}>
                              <Text fontWeight="bold" margin="0 0 5px">
                                {g.name}
                              </Text>
                              <Flex>
                                <Text color={orange}>
                                  Rp{' '}
                                  {formatMoney(
                                    calculateDiscount(
                                      g.price,
                                      g.discount_type,
                                      g.current_discount,
                                    ).price,
                                  )}
                                </Text>
                                {g.current_discount ? (
                                  <Text
                                    color="#777"
                                    textDecoration="line-through"
                                    margin="0 0 0 10px"
                                  >
                                    Rp {formatMoney(g.price || 0)}
                                  </Text>
                                ) : null}
                                <Text color="#999" margin="0 0 0 10px">
                                  {g.qty} Item(s)
                                </Text>
                              </Flex>
                            </Col>
                            <Col md={3}>
                              <Text
                                color="#999"
                                margin="0 0 5px"
                                mobileStyle={{ margin: '10px 0 0' }}
                              >
                                Subtotal
                              </Text>
                              <Text
                                color={orange}
                                fontWeight="bold"
                                mobileStyle={{ marginBottom: 10 }}
                              >
                                Rp{' '}
                                {formatMoney(
                                  calculateDiscount(
                                    g.price,
                                    g.discount_type,
                                    g.current_discount,
                                  ).price * g.qty,
                                )}
                              </Text>
                            </Col>
                          </Row>
                          <Separator margin="0 0 15px" />
                        </Fragment>
                      ))}
                     
                      <Button
                        color="orange"
                        padding="10px 20px"
                        margin="10px auto 0"
                        onClick={() => {
                          this.props.context.update('paymentconfirmation', {
                            text: 'Are you sure to proceed to payment?',
                            submitText: 'Pay Now With Midtrans',
                            changeState: this.changeState,
                            handleClick: this.handleClick,
                            submit: () => {
                              this.props.context.update('paymentconfirmation', null)
                              this.changeState('redirect', '/order')
                              //this.submit()
                            },
                          })
                          this.changeState('jenis','Bank')
                        }}
                      >
                        Pay Now
                      </Button>
                    </BoxShadow>
                  </Col>
                </Row>
              </Container>
              <Container padding="0">
                <Row margin="0 -15px 30px">
                  <Col md={{ span:8, offset:2 }}>
                    <BoxShadow
                     padding="10px 20px"
                     borderRadius="10px"
                     backgroundColor={white}
                   >
                      <Text fontSize="14px" fontWeight="bold" margin="0 0 15px">
                        Bayar dengan Kredivo?
                      </Text>
                      <p>Simulasikan cicilan yang dapat anda peroleh dengan kredivo? <Button
                        color="blue"
                        padding="2px 6px"
                        fontSize="10px"
                        onClick={() => {
                          this.kalkulatoKredivo()
                        }}> 
                        Kalkulator Cicilan
                        </Button>
                      </p>
                   </BoxShadow>
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>
          <ModalKalkulator
            open={modalKalkulators}
            close={() => this.changeState('modalKalkulators', false)}
            data={activeData}
            loading={loadingDetails}
            errordata={errordataDetails}
          />

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
      
    )
  }
}

export default withContext(CartConfirmation)
