import React from 'react'
import { AppContext } from '../context/AppContext'
import { black } from '../assets/styles/styles'
import { Image } from '../assets/styles/components'

export const withContext = Component => {
  return props => (
    <AppContext.Consumer>
      {context => <Component {...props} context={context} />}
    </AppContext.Consumer>
  )
}

// export function useFetch(url) {
//   const [data, setData] = useState([])
//   const [loading, setLoading] = useState(true)
//   async function fetchUrl() {
//     const response = await fetch(url)
//     const json = await response.json()
//     setData(json)
//     setLoading(false)
//   }
//   useEffect(() => {
//     fetchUrl()
//   }, [])
//   return [data, loading]
// }

export function typeChecker(value) {
  if (value && typeof value === 'object' && value.constructor === Array)
    return 'array'
  if (value && typeof value === 'object' && value.constructor === Object)
    return 'object'
  if (typeof value === 'string' || value instanceof String) return 'string'
  if (typeof value === 'number' && isFinite(value)) return 'number'
}

export function NextArrow(props) {
  const { className, style, onClick, right } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        color: black,
        right: right || -20,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <Image
        src={require('../assets/images/arrow-right.png')}
        width="15px"
        height="15px"
      />
    </div>
  )
}

export function PrevArrow(props) {
  const { className, style, onClick, left } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        color: black,
        position: 'absolute',
        left: left || -20,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <Image
        src={require('../assets/images/arrow-left.png')}
        width="15px"
        height="15px"
      />
    </div>
  )
}
