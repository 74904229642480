import React from 'react'
import { Text, Flex, Image } from '../../assets/styles/components'
import { isInt } from '../../utils/format'

const Rating = ({ count, left = false, ...props }) => {
  let arr = []
  for (let i = 0; i < parseInt(count); i++) {
    arr.push(require('../../assets/images/heart.png'))
  }
  if (!isInt(count)) arr.push(require('../../assets/images/heart-half.png'))

  return (
    <Flex justifyContent={left ? 'flex-start' : 'center'}>
      {arr.map((g, i) => (
        <Image key={i} src={g} height="12px" margin="0 3px 0 0" />
      ))}
      <Text fontSize="12px" margin="0 0 0 2px">
        ({count})
      </Text>
    </Flex>
  )
}

export default Rating
