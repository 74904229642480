import React, { Fragment, useState, useEffect } from 'react'
import { Image, Fixed, Icon } from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import { white } from '../../../assets/styles/styles'
import { ModalOverlay } from '../../../components/Modal/styles'
import { Container, Row, Col } from '../../../components/Bootstrap/Bootstrap'

const PreviewImage = ({ src, index, close }) => {
  const [newindex, setIndex] = useState(index)
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    // returned function will be called on component unmount
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  return (
    <Fragment>
      <Fixed top="25px" right="15px" zIndex="100">
        <Image
          src={require('../../../assets/images/icon-close.png')}
          width="50px"
          margin="-12px 0 0 0"
          height="50px"
          cursor="pointer"
          onClick={close}
        />
      </Fixed>

      <Fixed
        top="40%"
        right="30px"
        transform="translateY(-40%)"
        zIndex="100"
        mobileStyle={{ right: '15px' }}
      >
        <Icon
          className="fa fa-arrow-right fa-lg"
          color={white}
          cursor="pointer"
          onClick={() => {
            if (newindex === src.length - 1) return false
            setIndex(newindex + 1)
          }}
        />
      </Fixed>

      <Fixed
        top="40%"
        left="30px"
        transform="translateY(-40%)"
        zIndex="100"
        mobileStyle={{ left: '15px' }}
      >
        <Icon
          className="fa fa-arrow-left fa-lg"
          color={white}
          cursor="pointer"
          onClick={() => {
            if (newindex === 0) return false
            setIndex(newindex - 1)
          }}
        />
      </Fixed>
      {/* <Modal open backgroundColor="rgba(0,0,0,0.9)" padding="0" width="50%"> */}
      <ModalOverlay className="open" color="rgba(0,0,0,0.9)" zIndex={30} />
      <Fixed
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
        zIndex="100"
        mobileStyle={{ width: '80%' }}
      >
        <Container fluid>
          <Row>
            <Col xs={12} md={{ span: 6, offset: 3 }}>
              <Image
                className="rounded"
                src={src[newindex]}
                width="100%"
                height="auto"
                margin="0 0 25px"
                borderRadius="5px"
              />
            </Col>
          </Row>
          <Row justifyContent="center">
            {src.map((g, i) => {
              return (
                <Col xs={4} md={2}>
                  <Image
                    className="rounded"
                    key={i}
                    src={g}
                    width="100%"
                    height="auto"
                    borderRadius="5px"
                    cursor="pointer"
                    mobileStyle={{ marginBottom: 15 }}
                    onClick={() => setIndex(i)}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </Fixed>
      {/* </Modal> */}
    </Fragment>
  )
}

PreviewImage.defaultProps = {}

export default PreviewImage
