import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Flex,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'

class BlogDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }

  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let id = this.props.location.pathname.split('-')
    id = id[id.length - 1]

    let res = await ajax.getDetails(id, token)
    if (!res.success) {
      window.location.replace('/')
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.setState({
      data: res.data,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  render() {
    const { data, loading, errordata } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Blog`} margin="0 0 10px" />

              {loading ? (
                <Flex justifyContent="center">
                  <Spinner className="fa-2x" />
                </Flex>
              ) : (
                <Container padding="0">
                  <Row>
                    <Col md={12}>
                      <Text
                        fontSize="26px"
                        fontWeight="bold"
                        textAlign="center"
                        margin="0 0 40px"
                        mobileStyle={{ marginBottom: 20 }}
                      >
                        {data.title}
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                      <Image
                        className="rounded"
                        src={data.picture}
                        width="100%"
                        display="block"
                        margin="0 auto 25px"
                        objectFit="contain"
                      />
                    </Col>
                    <Col xs={12} md={{ span: 8, offset: 2 }}>
                      <Text>
                        <div
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                      </Text>
                    </Col>
                  </Row>
                </Container>
              )}
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(BlogDetails)
