import React, { useContext, useState } from 'react'
import Modal from '../Modal/Modal'
import { Text, Icon, Absolute, Flex } from '../../assets/styles/components'
import { blue, grey, black } from '../../assets/styles/styles'
import Button from '../Button/Button'
import { AppContext } from '../../context/AppContext'
import { baseURL } from '../../utils/apihost'
import Spinner from './Spinner'

const ModalWebview = ({ url, open, close }) => {
  const [loading, setLoading] = useState(true)
  const { state, update } = useContext(AppContext)
  return (
    <Modal open={open} width="30%" padding="35px 20px 15px">
      <Absolute right="10px" top="10px">
        <Icon
          className="fa fa-times-circle"
          style={{ fontSize: 20 }}
          // color={grey}
          onClick={close}
          cursor="pointer"
          opacity="0.8"
        />
      </Absolute>
      {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
      {loading ? (
        <Flex justifyContent="center" margin="25px 0 0">
          <Spinner color={black} className="fa-2x" />
        </Flex>
      ) : null}
      {open ? (
        <iframe
          src={url}
          style={{ width: '100%', height: '70vh' }}
          frameBorder="0"
          onLoad={() => setLoading(false)}
        />
      ) : null}
    </Modal>
  )
}

export default ModalWebview
