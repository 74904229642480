import React, { Fragment } from 'react'
import { Relative, Flex, Text } from '../../../assets/styles/components'
import InputOutlined from '../../../components/Input/InputOutlined'
import { Options, Option, NoOption } from '../styles'
import ajax from '../ajax'
import classNames from 'classnames'
import { withContext } from '../../../utils/helper'
import Spinner from '../../../components/Loader/Spinner'
import { white, blue } from '../../../assets/styles/styles'
import { LabelOutlinedStyled } from '../../../components/Input/styles'

class LocationQuery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: [],
      text: '',
      timeout: 0,
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }
  async fetchData(locationquery) {
    this.setState({ loading: true, errordata: false })
    const { token } = this.props.context.state
    let res = await ajax.getLocation(locationquery, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    this.setState({ data, loading: false })
  }
  changeText = (text) => {
    if (this.state.timeout) clearTimeout(this.state.timeout)
    this.setState({
      text,
      timeout: setTimeout(() => {
        if (text.length > 2) this.fetchData(text)
      }, 1500),
    })
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside = (event) => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.setState({ open: false })
    }
  }
  render() {
    const { loading, text, data, open } = this.state
    const { location, setLocation } = this.props
    return (
      <Relative margin="0 0 15px">
        <LabelOutlinedStyled>Postal Code</LabelOutlinedStyled>
        {/* <Text margin="-8px 0 7px" fontSize="13px">
          Please fill and choose domicile appeared in postal code
        </Text> */}

        <div ref={(ref) => (this.wrapper = ref)}>
          {/* <InputOutlined
              placeholder="Input at least 3 letters to search area"
              value={text}
              onChange={(e) => this.changeText(e.target.value)}
              margin="0"
              onFocus={() => this.setState({ open: true })}
            /> */}
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: 4,
              cursor: 'pointer',
              padding: '8px 12px',
            }}
            onClick={() => this.setState({ open: true })}
          >
            <Text fontSize="14px">
              {location.location_id
                ? `${location.provinsi}, ${location.kabupaten}, ${location.kecamatan}, ${location.kelurahan}, ${location.kodepos}, Indonesia`
                : 'Mohon mencari domisili dan kodepos yang sesuai'}{' '}
              {/* <span
              style={{ color: blue, cursor: 'pointer', marginLeft: 3 }}
            >
              Change
            </span> */}
            </Text>
          </div>
          {open ? (
            <Options className={classNames({ open })}>
              <div style={{ padding: 10, backgroundColor: white }}>
                <InputOutlined
                  placeholder="Minimal 3 huruf atau nomor untuk mencari"
                  value={text}
                  onChange={(e) => this.changeText(e.target.value)}
                  margin="0"
                  onFocus={() => this.setState({ open: true })}
                  padding="6px 10px"
                  style={{ fontSize: 12 }}
                />
              </div>
              <div style={{ overflow: 'auto', maxHeight: 200 }}>
                {loading ? (
                  <Flex
                    justifyContent="center"
                    backgroundColor={white}
                    padding="10px 0"
                  >
                    <Spinner />
                  </Flex>
                ) : data.length > 0 ? (
                  data.map((g, i) => (
                    <Option
                      key={i}
                      onClick={() => {
                        setLocation(g)
                        this.setState({ open: false })
                      }}
                    >
                      {`${g.provinsi}, ${g.kabupaten}, ${g.kecamatan}, ${g.kelurahan}, ${g.kodepos}, Indonesia`}
                    </Option>
                  ))
                ) : (
                  <NoOption>No area found</NoOption>
                )}
              </div>
            </Options>
          ) : null}
        </div>
      </Relative>
    )
  }
}

export default withContext(LocationQuery)
