import React, { useContext } from 'react'
import Modal from '../Modal/Modal'
import { Text, Icon } from '../../assets/styles/components'
import { blue, orange } from '../../assets/styles/styles'
import Button from '../Button/Button'
import { AppContext } from '../../context/AppContext'

const ModalError = () => {
  const { state, update } = useContext(AppContext)
  return (
    <Modal open={state.error} width="25%" padding="15px 20px">
      <Text textAlign="center">
        <Icon
          className="fa fa-times-circle-o"
          style={{ fontSize: 80 }}
          color={orange}
        />
      </Text>
      <Text
        textAlign="center"
        fontWeight="bold"
        fontSize="18px"
        margin="10px 0 15px"
      >
        {state.error}
      </Text>
      <Button
        color="blue"
        padding="8px 20px"
        block
        onClick={() => {
          update('error', '')
        }}
      >
        OK
      </Button>
    </Modal>
  )
}

export default ModalError
