const banner = {
  status: 'success',
  data: [
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Tampil Cantik bersama Wardah',
    },
    {
      id: 2,
      image: require('../assets/images/banner1.jpg'),
      title: 'Tampil Cantik bersama Wardah',
    },
    {
      id: 3,
      image: require('../assets/images/banner1.jpg'),
      title: 'Tampil Cantik bersama Wardah',
    },
    {
      id: 4,
      image: require('../assets/images/banner1.jpg'),
      title: 'Tampil Cantik bersama Wardah',
    },
  ],
}

const cart = {
  status: 'success',
  data: [
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Ms Acne Care',
      category: 'Solution Care Toner',
      qty: 1,
      price: 240000,
    },
    {
      id: 2,
      image: require('../assets/images/product1.jpg'),
      name: 'Ms Acne Care Super',
      category: 'Solution Care Toner',
      qty: 2,
      price: 240000,
    },
  ],
}

const history = {
  status: 'success',
  keyword: [
    { id: 1, name: 'Cetaphil face wash' },
    { id: 2, name: 'maybeline' },
  ],
  product: [
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Ms Acne Care',
      category: 'Solution Care Toner',
      qty: 1,
      price: 240000,
    },
    {
      id: 2,
      image: require('../assets/images/product1.jpg'),
      name: 'Ms Acne Care Super',
      category: 'Solution Care Toner',
      qty: 2,
      price: 240000,
    },
  ],
}

const search = {
  status: 'success',
  brand: [
    { id: 1, image: require('../assets/images/brand.png'), name: 'Maybeline' },
  ],
  product: [
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Ms Acne Care',
      category: 'Solution Care Toner',
      qty: 1,
      price: 240000,
      brand: {
        id: 1,
        image: require('../assets/images/brand.png'),
        name: 'Maybeline',
      },
    },
    {
      id: 2,
      image: require('../assets/images/product1.jpg'),
      name: 'Ms Acne Care Super',
      category: 'Solution Care Toner',
      qty: 2,
      price: 240000,
      brand: {
        id: 1,
        image: require('../assets/images/brand.png'),
        name: 'Maybeline',
      },
    },
  ],
}

const products = {
  status: 'success',
  brand: [
    { id: 1, image: require('../assets/images/brand.png'), name: 'Maybeline' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'AGE 20s' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'Anna Sui' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'Makeover' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'SK II' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'Maybeline' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'AGE 20s' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'Anna Sui' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'Makeover' },
    { id: 1, image: require('../assets/images/brand.png'), name: 'SK II' },
  ],
  category: [
    {
      id: 1,
      name: 'Foundation',
    },
    {
      id: 2,
      name: 'Primer',
    },
    {
      id: 3,
      name: 'Concealer',
    },
    {
      id: 4,
      name: 'Bronzer',
    },
    {
      id: 1,
      name: 'Foundation',
    },
    {
      id: 2,
      name: 'Primer',
    },
    {
      id: 3,
      name: 'Concealer',
    },
    {
      id: 4,
      name: 'Bronzer',
    },
    {
      id: 1,
      name: 'Foundation',
    },
    {
      id: 2,
      name: 'Primer',
    },
    {
      id: 3,
      name: 'Concealer',
    },
    {
      id: 4,
      name: 'Bronzer',
    },
  ],
  product: [
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
    {
      id: 1,
      image: require('../assets/images/product1.jpg'),
      name: 'Lip - Ink',
      category: 'SASC',
      price: 130000,
    },
  ],
}

const productdetails = {
  status: 'success',
  data: {
    name: 'Fit Me Blush',
    brand: {
      id: 1,
      image: require('../assets/images/brand.png'),
      name: 'Maybeline',
    },
    rating: 4.5,
    price: 89000,
    discount: 30,
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis blandit posuere neque non rhoncus. 
		Etiam eget ex dignissim, pretium magna placerat, rhoncus leo. Suspendisse convallis tellus dui, at bibendum 
		libero viverra at. Morbi vitae elit vel lorem sollicitudin malesuada nec ac nunc. Fusce nec massa rutrum,
		 mollis eros quis, congue libero. Vivamus tristique ut ipsum ac pellentesque. Pellentesque ex purus, sodales 
		 vitae gravida sed, cursus eu lectus. Sed mi magna, bibendum sed ligula posuere, vulputate egestas ex. 
		 Phasellus accumsan massa a arcu facilisis euismod. Fusce id venenatis libero. Vivamus aliquet ullamcorper 
		 pharetra. Etiam laoreet at nisi non elementum. Sed placerat velit ut justo vehicula sollicitudin. Cras 
		 convallis enim risus, ac mollis magna ornare vel.`,
    images: [
      {
        image: require('../assets/images/product1.jpg'),
      },
      {
        image: require('../assets/images/product1.jpg'),
      },
      {
        image: require('../assets/images/product1.jpg'),
      },
      {
        image: require('../assets/images/product1.jpg'),
      },
      {
        image: require('../assets/images/product1.jpg'),
      },
    ],
  },
}

const categories = {
  status: 'success',
  data: [
    {
      name: 'Face',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Eyes',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Tools',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
      ],
    },
    {
      name: 'Lashes',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
      ],
    },
    {
      name: 'Lips',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Brows',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Brushes',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Brushes',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Brushes',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
    {
      name: 'Brushes',
      sub_category: [
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
        {
          id: 3,
          name: 'Concealer',
        },
        {
          id: 4,
          name: 'Bronzer',
        },
        {
          id: 1,
          name: 'Foundation',
        },
        {
          id: 2,
          name: 'Primer',
        },
      ],
    },
  ],
}

const order = {
  status: 'success',
  data: [
    {
      created_date: '2019-11-02 00:00:00',
      status: 1,
      order_id: 'PS201911/0001',
      total: 178000,
      items: [
        {
          name: 'Fit Me Blush',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
      ],
    },
    {
      created_date: '2019-11-02 00:00:00',
      status: 2,
      order_id: 'PS201911/0001',
      total: 178000,
      items: [
        {
          name: 'Fit Me Blush',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
      ],
    },
    {
      created_date: '2019-11-02 00:00:00',
      status: 3,
      order_id: 'PS201911/0001',
      total: 178000,
      items: [
        {
          name: 'Fit Me Blush',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
      ],
    },
    {
      created_date: '2019-11-02 00:00:00',
      status: 4,
      order_id: 'PS201911/0001',
      total: 178000,
      items: [
        {
          name: 'Fit Me Blush',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
        {
          name: 'Fit Me Blush Super Toner',
          brand: {
            id: 1,
            image: require('../assets/images/brand.png'),
            name: 'Maybeline',
          },
          image: require('../assets/images/product1.jpg'),
          price: 89000,
          qty: 1,
        },
      ],
    },
  ],
}

const event = {
  status: 'success',
  data: [
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
    {
      id: 1,
      image: require('../assets/images/banner1.jpg'),
      title: 'Grand Opening Dandan Store Cipete',
      created_date: '2019-11-10 13:00:00',
    },
  ],
}

export default {
  banner,
  cart,
  history,
  search,
  products,
  productdetails,
  categories,
  order,
  event,
}
