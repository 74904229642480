import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { black, border, monserrat, roboto, breakpoints } from './styles'

const btn = css`
  display: inline-block;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  transition: 0.1s all;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
    box-shadow: none;
  }
`

const Relative = styled.div`
  position: relative;
  margin: ${props => props.margin};
  cursor: ${props => props.cursor};
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const Absolute = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  cursor: ${props => props.cursor};
  transform: ${props => props.transform};
  width: ${props => props.width};
  z-index: ${props => props.zIndex};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const Fixed = styled.div`
  position: fixed;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  cursor: ${props => props.cursor};
  transform: ${props => props.transform};
  width: ${props => props.width};
  z-index: ${props => props.zIndex};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const FormGroup = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 0 30px'};
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.borderRadius};
  flex-wrap: ${props => props.flexWrap};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const FlexCell = styled.div`
  flex: ${props => props.flex};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  width: ${props => props.width};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const InlineBlock = styled.div`
  display: inline-block;
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor};
`

const FilledGroup = styled.div`
  margin: ${props => props.margin};
`

const FilledLabel = styled.label`
  color: #7f8c8d;
  font-size: 12px;
  font-weight: normal;
`

const FilledContent = styled.p`
  color: #555;
  margin: 0;
`

const WhiteSpace = styled.div`
  white-space: ${props => props.type};
  padding: ${props => props.padding};
`

const Text = styled.div`
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  color: ${props => props.color || black};
  font-style: ${props => props.fontStyle};
  text-transform: ${props => props.textTransform};
  cursor: ${props => props.cursor};
  text-decoration: ${props => props.textDecoration};
	font-family: ${props => (props.isTitle === 'true' ? monserrat : monserrat)};
	word-break: ${props => props.wordBreak};
  // line-height: ${props =>
    props.lineHeight || props.isTitle === 'true' ? '1' : '1'};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
	}
	
	&:hover {
		${props => props.hoverStyle}
	}
`

const Image = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  object-fit: ${props => props.objectFit};
  cursor: ${props => props.cursor};
  border-radius: ${props => props.borderRadius};
  display: ${props => props.display};
  object-position: ${props => props.objectPosition};

  @media (min-width: ${breakpoints[0]}) and (max-width: ${breakpoints[2]}) {
    ${props => props.mediumStyle};
  }

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const Icon = styled.i`
  margin: ${props => props.margin};
  cursor: ${props => props.cursor};
  color: ${props => props.color};
  opacity: ${props => props.opacity};
`

const Separator = styled.div`
  border-bottom: ${props => props.borderWidth || '1px'}
    ${props => props.borderStyle || 'solid'}
    ${props => props.borderColor || '#ddd'};
  margin: ${props => props.margin};

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`

const SeparatorVertical = styled.div`
  border-right: ${props => props.borderWidth || '1px'}
    ${props => props.borderStyle || 'solid'}
    ${props => props.borderColor || '#ddd'};
  margin: ${props => props.margin};
  height: 15px;
  width: 1px;
`

const BoxShadow = styled.div`
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  padding: ${props => props.padding || '20px 25px'};
  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.backgroundColor};
  margin: ${props => props.margin};
`

const Overlay = styled.div`
  background-color: ${props => props.color || 'rgba(0, 0, 0, 0.18)'};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: ${props => props.zIndex};
  display: none;
  &.open {
    opacity: 1;
    z-index: ${props => props.zIndex};
    display: block;
  }
`

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - ${props => (props.promo ? '130px' : '105px')});

  @media (max-width: ${breakpoints[0]}) {
    height: calc(100vh - ${props => (props.promo ? '75px' : '50px')});
  }

  @media (max-width: ${breakpoints[0]}) {
    ${props => props.mobileStyle}
  }
`
// const Required = styled.div`
//   font-size: ${props => props.fontSize};
//   margin: ${props => props.margin};
//   font-weight: ${props => props.fontWeight};
//   text-align: ${props => props.textAlign};
//   color: ${props => props.color || 'red'};
//   font-style: ${props => props.fontStyle};
//   text-transform: ${props => props.textTransform};
//   cursor: ${props => props.cursor};
//   text-decoration: ${props => props.textDecoration};
// 	font-family: monserrat;
// 	word-break: ${props => props.wordBreak};
//   line-height: ${props =>
//   props.lineHeight || (props.isTitle === 'true' ? '1' : '1.5')};

//   @media (max-width: ${breakpoints[0]}) {
//     ${props => props.mobileStyle}
// 	}
	
// 	&:hover {
// 		${props => props.hoverStyle}
// 	}
// `

const RequiredMark = styled.span`
  color: red;
  font-size: 14px;
  margin-left: 5px;
`
export {
  btn,
  Relative,
  Absolute,
  Fixed,
  FormGroup,
  Flex,
  FlexCell,
  InlineBlock,
  FilledGroup,
  FilledLabel,
  FilledContent,
  WhiteSpace,
  Text,
  Image,
  Icon,
  Separator,
  SeparatorVertical,
  BoxShadow,
  Overlay,
  PageWrapper,
  RequiredMark,
}
