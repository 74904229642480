import React, { Fragment } from 'react'
import Modal from '../../components/Modal/Modal'
import { withContext } from '../../utils/helper'
import Button from '../../components/Button/Button'
import InputOutlined from '../../components/Input/InputOutlined'
import { host } from '../../utils/apihost'
import {
  Text,
  Absolute,
  Icon,
  Flex,
  Separator,
  FlexCell,
} from '../../assets/styles/components'
import { Underline } from './styles'
import ajax from './ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phonenumber: '',
      showpassword:false,
      formstatus:1,
      showError: false,
      otpcode: '',
      password: '',
      loading: false,
      error: '',
    }
    this.submit = this.submit.bind(this)
    this.handleotp = this.handleotp.bind(this)
    this.otpsubmit = this.otpsubmit.bind(this)
    this.responseGoogle = this.responseGoogle.bind(this)
    this.responseFacebook = this.responseFacebook.bind(this)
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.context.state.showLogin &&
      !this.props.context.state.showLogin
    ) {
      this.setState({
        phonenumber: '',
        password: '',
        showpassword:false,
        showError: false,
        otpcode: '',
        formstatus:'1',
        loading: false,
        error: '',
      })
    }
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  async submit(e) {
    e.preventDefault()
    const { phonenumber, password, formstatus} = this.state
    this.setState({ loading: true, error: '' })
    let res = await ajax.login(phonenumber, password)
    //Pengecekan pertama
    if(formstatus == '3'){
      this.setState({ loading: false, error: 'Anda belum terdaftar', formstatus: res.message, showError: true })
      //Redirect ke halaman register + memindahkan nilai variabel phonenumber
      window.location.replace("/register?phone=" + encodeURIComponent(phonenumber));
      return false
    }
    // Pengecekan selanjutnya
    if (!res.success) {
      if(formstatus == '2'){
        if(res.message == '2'){
          this.setState({ loading: false, error: 'Kolom Password Kosong ', formstatus: res.message, showError: true })
          return false
        }
        if(res.message == '3'){
          this.setState({ loading: false, error: 'Silahkan daftar terlebih dahulu', formstatus: res.message, showError: true })
          return false    
        }
        if(res.message == '4'){
          this.setState({ loading: false, error: 'Masukkan OTP Code', formstatus: res.message, showError: false })
          return false 
        }
        this.setState({ loading: false, error: res.message, showError: true })
        return false
      }
      this.setState({ loading: false, error: res.message, formstatus: res.message, showError: false })
      return false
    }
    this.props.context.login(res.data.customer.Data, res.data.token)
    this.setState({ loading: false, })
    this.props.context.update('showLogin', false)
    window.location= host
  }

    async handleotp(e){ 
      e.preventDefault()
      const { phonenumber } = this.state
      this.setState({ loading: true, error: '' })
      let res = await ajax.otpgenerate(phonenumber)
      if(res.status != "SUKSES"){
        this.setState({loading: false, error: res.message})
        return false
      }
      this.setState({loading: false, error: 'Berhasil'})
    }


    //otplogin blm selesai
    async otpsubmit(e){
      e.preventDefault()
      const { phonenumber, otpcode } = this.state
      this.setState({ loading: true, error: '' })
      let res = await ajax.otplogin(phonenumber, otpcode)
      if(!res.success){
        this.setState({loading:false, error: res.message})
        return false
      }
      // Handle successful OTP verification
      this.props.context.login(res.data.customer.Data, res.data.token);
      this.setState({ loading: false });
      this.props.context.update('showLogin', false);
      window.location=host
    }


  async responseGoogle(response) {
    if (!response) return false
    const { profileObj } = response
    // this.setState({ loading: true, error: '' })
    this.setState({ error: '' })
    let res = await ajax.loginSocial(
      'Google',
      profileObj.googleId,
      profileObj.name,
      profileObj.email,
      profileObj.imageUrl,
      response.accessToken,
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.props.context.login(res.data.customer.Data, res.data.token)
    this.setState({ loading: false })
    this.props.context.update('showLogin', false)
    window.location.replace("/profile");
  }
  async responseFacebook(response) {
    if (!response) return false
    // this.setState({ loading: true, error: '' })
    this.setState({ error: '' })
    let res = await ajax.loginSocial(
      'Facebook',
      response.userID,
      response.name,
      response.email,
      response.picture ? response.picture.data.url : '',
      response.accessToken,
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.props.context.login(res.data.customer.Data, res.data.token)
    this.setState({ loading: false })
    this.props.context.update('showLogin', false)
    window.location.replace('/profile')
  }
  render() {
    const { state, update } = this.props.context
    const { phonenumber,formstatus, password, loading,otpcode, error, showError } = this.state
    return (  
      <Fragment>
        <Modal open={state.showLogin} width="30%" padding="2em 2.5em 1.5em">
          <Absolute top="7px" right="7px">
            <Icon
              className="fa fa-times-circle fa-lg"
              color="#999"
              cursor="pointer"
              onClick={() => update('showLogin', false)}
            />
          </Absolute>
          {formstatus == 1 && (
          <>
          <Text fontWeight="bold" fontSize="20px">
            Login
          </Text>
          <Underline />
          <form onSubmit={this.submit}>
            <InputOutlined
              id="loginphonenumber"
              label="Phone Number"
              placeholder="ex: 087562947163"
              value={phonenumber}
              onChange={e => this.changeState('phonenumber', e.target.value)}
              margin="0 0 15px"
            />
            <Button color="blue" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Enter'}
            </Button>
          </form>
          </>
          )}
          {formstatus == 2 && (
          <>
          <Text fontWeight="bold" fontSize="20px">
            Login
          </Text>
          <Underline />
          <form onSubmit={this.submit}>
            <InputOutlined
              id="loginphonenumber"
              label="Phone Number"
              placeholder="ex: 087562947163"
              value={phonenumber}
              onChange={e => this.changeState('phonenumber', e.target.value)}
              margin="0 0 15px"
            />
            <InputOutlined
              id="loginpassword"
              type="password"
              autocomplete="current-password"
              label="Password"
              placeholder="Password must be at least 4 characters"
              value={password}
              onChange={e => this.changeState('password', e.target.value)}
              margin="0 0 30px"
            />
            {showError ? (
              <ErrorText center={true} margin="0 0 15px">
                {error}
              </ErrorText>
            ) : null}
            <Button color="blue" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Login'}
            </Button>
            <Text
              cursor="pointer"
              margin="30px 0 0"
              textDecoration="underline"
              fontSize="12px"
              textAlign="center"
              onClick={() => {
                update('showLogin', false)
                update('showForgot', true)
              }}
            >
              Forgot password?
            </Text>
          </form>
          </>
          )}

          {formstatus == 3 && (
          <>
          <Text fontWeight="bold" fontSize="20px">
            Login
          </Text>
          <Underline />
          <form onSubmit={this.submit}>
            <InputOutlined
              id="loginphonenumber"
              label="Phone Number"
              placeholder="ex: 087562947163"
              value={phonenumber}
              onChange={e => this.changeState('phonenumber', e.target.value)}
              margin="0 0 15px"
            />
            <ErrorText center={true} margin="0 0 15px">
              Anda belum terdaftar
            </ErrorText>
            <Button color="blue" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Daftar Member'}
            </Button>
            {/* <Button color="blue" padding="10px 20px" block disabled={loading}>
            {loading ? <Spinner /> : 'Login'}
            </Button> */}
            <Text
              cursor="pointer"
              margin="30px 0 0"
              textDecoration="underline"
              fontSize="12px"
              textAlign="center"
              onClick={() => {
                update('showLogin', false)
                update('showForgot', true)
              }}
            >
              Forgot password?
            </Text>
          </form>
          </>
          )}

          {/* Tambahan Baru :: OTP */}
          {formstatus == 4 && (
          <>
          <Text fontWeight="bold" fontSize="20px">
            Enter OTP Code
          </Text>
          <Underline />
          <form onSubmit={this.otpsubmit}>
            <InputOutlined
              id="inputotpcode"
              label="OTP Code"
              placeholder="ex: 231232"
              value={otpcode}
              onChange={e => this.changeState('otpcode', e.target.value)}
              margin="0 0 15px"
            />
            {/* <ErrorText center={true} margin="0 0 15px">
              {'Please Enter the OTP Code that has been sent to your phone number'}
            </ErrorText> */}
            <ErrorText center={true} margin="0 0 15px">
              {error}
            </ErrorText>
            <Button color="blue" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Enter'}
            </Button>
          </form>
          <Text
              cursor="pointer"
              margin="30px 0 0"
              textDecoration="underline"
              fontSize="12px"
              textAlign="center"
              onClick={this.handleotp}
            >
              Send OTP Code
            </Text> 
          </>
          )}
        </Modal>
      </Fragment>
    )
  }
}

export default withContext(Login)
