import styled from '@emotion/styled'
import { blue } from '../../assets/styles/styles'

const Underline = styled.div`
  border-bottom: 3px solid ${blue};
  margin: 5px 0 20px;
  width: 40%;
`

export { Underline }
