import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {
  Flex,
  FlexCell,
  Image,
  Text,
  Absolute,
  Relative,
  PageWrapper,
} from '../../assets/styles/components'
import ProductSearchList from './components/ProductSearchList'
import ProductSearchCategory from './components/ProductSearchCategory'
import ProductSearchBrand from './components/ProductSearchBrand'
import ajax from './ajax'
import Footer from '../../components/Footer/Footer'
import Pagination from './components/Pagination'
import { white } from '../../assets/styles/styles'

class Sale extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      category: [],
      orderType : { id: 0, name: '-', value: 0},
      orderTypeList: [
        { id: 1, name: 'Harga Terendah' , value: 1},
        { id: 2, name: 'Harga Tertinggi', value: 2},
        { id: 3, name: 'A-Z', value: 3},
        { id: 4, name: 'Z-A', value: 4},
      ],
      orderCount: {id: 15, name: '15', value: 15},
      orderCountList: [
        { id: 15, name: '15', value: 15},
        { id: 25, name: '25', value: 25},
        { id: 50, name: '50', value: 50},
        { id: 100, name: '100', value: 100},
      ],
      brand: [],
      product: [],
      filteredbrand: [],
      activeCategory: '',
      totalcount: 0,
      page: 1,
      totalpage: 1,
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchBrand = this.fetchBrand.bind(this)
    this.add = this.add.bind(this)
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    await this.fetchData(1)
    await this.fetchBrand()
  }

  async componentDidUpdate(prevProps, prevState)
  {
    if(prevState.page!=this.state.page){
      await this.fetchData(this.state.page, this.state.orderCount.value, this.state.orderType.value)
    }
    if (prevState.orderCount.value!=this.state.orderCount.value) {
      await this.fetchData(1, this.state.orderCount.value, this.state.orderType.value)
    }

    if (prevState.orderType.value!=this.state.orderType.value) {
      await this.fetchData(1, this.state.orderCount.value, this.state.orderType.value)
    }

  }
  

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData(page = 1, perpage = 15, orderby = 0) {
    const { location, context } = this.props
    const { token } = context.state
    this.setState({
      loading: true,
      errordata: false,
    })
    let brand = []
    this.state.brand.map(g => {
      if (g.checked) brand.push(g.id)
      return g
    })

    let category = 5
    let res = await ajax.getProductList(page, perpage, orderby, category, brand, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let totalcount = res.data.product_count
    let totalpage = 1
    if (res.data.total_page) totalpage = res.data.total_page
    else {
      totalpage = parseInt(totalcount / this.state.orderCount.value)
      if (totalcount % this.state.orderCount.value != 0) totalpage++
    }
    if (totalpage < 1) totalpage = 1
    this.setState({
      loading: false,
      product: res.data.product,
      totalcount,
      totalpage,
      page,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  
  async add(id, data) {
    const { token } = this.props.context.state
    const { qty } = this.state
    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajax.addToCart(id, 1, token)

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', data)
    this.props.context.update('refetchCart', true)
  }

  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  async fetchBrand(keyword = '') {
    const { token } = this.props.context.state
    this.setState({ loadingBrand: true, errordataBrand: false })
    let res = await ajax.getBrandOnCategory(
      keyword,
      5,
      token,
    )
    if (!res.success) {
      this.setState({ loadingBrand: false, errordataBrand: true })
      return false
    }
    let brand = res.data
    // if (this.props.location.state.brand) {
    //   brand = brand.map(g => {
    //     if (g.id == this.props.location.state.brand) g.checked = true
    //     return g
    //   })
    // }
    this.setState({
      brand,
      filteredbrand: brand,
      loadingBrand: false,
    })
  }
  filter = value => {
    let filteredbrand = [...this.state.brand]
    filteredbrand = filteredbrand.filter(g =>
      g.name.toUpperCase().includes(value.toUpperCase()),
    )
    this.setState({ filteredbrand })
  }
  checkBrand = index => {
    let brand = [...this.state.brand]
    brand[index].checked = !brand[index].checked
    this.setState({ brand }, () => this.fetchData())
  }

  render() {
    const {
      loading,
      errordata,
      brand,
      changeState,
      category,
      loadingBrand,
      errordataBrand,
      filteredbrand,
      product,
      totalcount,
      orderType,
      orderTypeList,
      orderCount,
      orderCountList,
      page,
      totalpage,
    } = this.state
    const { location, context } = this.props
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb text={`Home > Sale`} margin="0" />
              <Container>
                <Row>
                  <Col md={2} padding="0">
                    <Relative
                      padding="0 30px 0 0"
                      mobileStyle={{ padding: 0 }}
                      backgroundColor={white}
                      // style={{
                      //   boxShadow: '0 1px 20px 0 rgba(155, 155, 155, 0.2)',
                      // }}
                    >
                      <Row margin="0 -15px 30px">
                        <Col md={12}>
                          <Text
                            margin="0"
                            isTitle="true"
                            fontSize="26px"
                            fontWeight="bold"
                          >
                            Sale
                          </Text>
                        </Col>
                      </Row>
                    </Relative>
                    
                  </Col>
                  <Col md={10} padding="0">
                    <Relative padding="0 0 0 15px" mobileStyle={{ padding: 0 }}>
                      <ProductSearchList
                        data={product}
                        loading={loading}
                        errordata={errordata}
                        totalcount={totalcount}
                        add={this.add}
                        page={page}
                        orderType={orderType}
                        orderTypeList={orderTypeList}
                        changeState={this.changeState}
                        orderCount={orderCount}
                        orderCountList={orderCountList}
                        titletext="Sale"
                      />

                      {loading ? null : (
                        <Pagination
                          totalcount={totalcount}
                          totalpage={totalpage}
                          fetch={this.fetchData}
                          page={page}
                          orderCount={orderCount}
                        />
                      )}
                    </Relative>
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Sale)
