import React, { useState, Fragment } from 'react'
import { Tab } from '../styles'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Flex,
  Relative,
  Image,
  FlexCell,
} from '../../../assets/styles/components'
import Spinner from '../../../components/Loader/Spinner'
import Rating from '../../../components/Rating/Rating'
import MediaQuery from 'react-responsive'
import { breakpoints } from '../../../assets/styles/styles'

const ProductDetailsFooter = ({
  data,
  loading,
  review,
  reviewnontrans,
  viewReview,
  datareview,
  datacountreview,
  loadmore,
  loadmorenontrans,
  fetchData,
  fetchDataNonTrans,
  page,
  pagenontrans,
}) => {
  const [activetab, setActivetab] = useState(0)
  return (
    <Row>
      <Col md={12} padding="0">
        <Flex>
          <Tab active={activetab === 0} onClick={() => setActivetab(0)}>
            Product Information
          </Tab>
          <Tab active={activetab === 1} onClick={() => setActivetab(1)}>
            Review ({review.length + datacountreview})
          </Tab>
        </Flex>

        {activetab === 0 ? (
          <Relative padding="20px 30px">
            <Text
              dangerouslySetInnerHTML={{
                __html: data.product && data.product.description,
              }}
            />
          </Relative>
        ) : null}

        {activetab === 1 ? (
          <Relative padding="20px 30px">
            {loading ? (
              <Flex>
                <Spinner className="fa-2x" />
              </Flex>
            ) : review.length > 0 || reviewnontrans.length > 0 ? (
              <Fragment>
                {datareview=="T" && (
                  <p>Do you want review this product ? <a href='javascript:void(0)' className='badge bg-warning text-white' onClick={() => viewReview(data)}>Review Now</a></p>
                )}

                {review.map((g, i) => (
                  <Flex
                    key={i}
                    alignItems="flex-start"
                    padding="20px 0 15px"
                    style={{ borderBottom: `1px solid #e0e0e0` }}
                  >
                    <FlexCell>
                      <Image
                        src={g.customer.Data.avatar}
                        width="50px"
                        height="50px"
                        borderRadius="50%"
                      />
                    </FlexCell>
                    <MediaQuery maxWidth={breakpoints[0]}>
                      <FlexCell padding="0 0 0 25px">
                        <Text
                          fontSize="15px"
                          margin="0 0 3px"
                          fontWeight="bold"
                        >
                          {g.customer.Data.name +
                            ' ' +
                            g.customer.Data.last_name}
                        </Text>
                        <Relative margin="0 0 10px">
                          <Rating count={g.rating} left />
                        </Relative>
                        <Text>{g.review}</Text>
                      </FlexCell>
                    </MediaQuery>

                    <MediaQuery minWidth={breakpoints[0]}>
                      <FlexCell padding="0 0 0 25px">
                        <Text
                          fontSize="15px"
                          margin="15px 0 0"
                          fontWeight="bold"
                        >
                          {g.customer.Data.name +
                            ' ' +
                            g.customer.Data.last_name}
                        </Text>
                      </FlexCell>
                      <FlexCell padding="0 0 0 35px">
                        <Relative margin="15px 0 10px">
                          <Rating count={g.rating} left />
                        </Relative>
                        <Text>{g.review}</Text>
                      </FlexCell>
                    </MediaQuery>
                  </Flex>
                ))}

                {loadmore? (
                  <Text
                    onClick={() => fetchData(page + 1)}
                    textAlign="center"
                    margin="10px 0 0"
                    cursor="pointer"
                    fontSize="13px"
                    textDecoration="underline"
                  >
                    load more
                  </Text>
                ) : null} 

                {reviewnontrans.map((g, i) => (
                  <Flex
                    key={i}
                    alignItems="flex-start"
                    padding="20px 0 15px"
                    style={{ borderBottom: `1px solid #e0e0e0` }}
                  >
                    <FlexCell>
                      <Image
                        src={g.avatar}
                        width="50px"
                        height="50px"
                        borderRadius="50%"
                      />
                    </FlexCell>
                    <MediaQuery maxWidth={breakpoints[0]}>
                      <FlexCell padding="0 0 0 25px">
                        <Text
                          fontSize="15px"
                          margin="0 0 3px"
                          fontWeight="bold"
                        >
                          {g.customer_name}
                        </Text>
                        <Relative margin="0 0 10px">
                          <Rating count={g.rating} left />
                        </Relative>
                        <Text>{g.review}</Text>
                      </FlexCell>
                    </MediaQuery>

                    <MediaQuery minWidth={breakpoints[0]}>
                      <FlexCell padding="0 0 0 25px">
                        <Text
                          fontSize="15px"
                          margin="15px 0 0"
                          fontWeight="bold"
                        >
                          {g.customer_name}
                        </Text>
                      </FlexCell>
                      <FlexCell padding="0 0 0 35px">
                        <Relative margin="15px 0 10px">
                          <Rating count={g.rating} left />
                        </Relative>
                        <Text>{g.review}</Text>
                      </FlexCell>
                    </MediaQuery>
                  </Flex>
                ))} 

                {loadmorenontrans? (
                  <Text
                    onClick={() => fetchDataNonTrans(pagenontrans + 1)}
                    textAlign="center"
                    margin="10px 0 0"
                    cursor="pointer"
                    fontSize="13px"
                    textDecoration="underline"
                  >
                    load more
                  </Text>
                ) : null}
                
              </Fragment>
            ) : (
              <Fragment>
                {datareview=="T" && (
                  <p>Do you want review this product ? <a href='javascript:void(0)' className='badge bg-warning text-white' onClick={() => viewReview(data)}>Review Now</a></p>
                )}
                <Text>No review yet</Text>
              </Fragment>
              
            )}
          </Relative>
        ) : null}
      </Col>
    </Row>
  )
}

export default ProductDetailsFooter
