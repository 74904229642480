import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Flex,
  FlexCell,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import { withContext } from '../../utils/helper'
import Spinner from '../../components/Loader/Spinner'
import ajax from './ajax'
import { MainPost, PostImage, OtherWrapper } from './styles'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import PageTitle from '../../components/PageTitle/PageTitle'
import bgmainblog from '../../assets/images/bg-mainblog.png'
const settings = {
  dots: false,
  autoPlay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // appendDots: dots => (
  //   <BannerBulletContainer>
  //     <ul> {dots} </ul>
  //   </BannerBulletContainer>
  // ),
}

class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      recent: [],
      page: 1,
      loadmore: true,
      frame: '',
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData(1)
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async fetchData(page = this.state.page) {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getBlogFrame(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let res2 = await ajax.getRecent(page, token)
    if (!res2.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    // let data = res.data
    let frame = res.data.picture || bgmainblog
    let data = []
    let recent = res2.data
    recent = [...this.state.recent, ...recent]
    if (recent.length > 0) data = [{ blog: recent[0] }]
    this.setState({
      data,
      recent,
      loading: false,
      page: this.state.page,
      loadmore: res2.data.length == 5,
      frame,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }

  render() {
    const { loading, errordata, data, recent, frame } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Blog`} margin="0 0 10px" />
              {loading ? (
                <Flex justifyContent="center">
                  <Spinner className="fa-2x" />
                </Flex>
              ) : (
                <Container padding="0">
                  <Row margin="0 -15px 40px">
                    <Col md={12}>
                      <PageTitle text="Blog" />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8} md={{ span: 6, offset: 1 }}>
                      <MainPost>
                        {/* <Slider
                      {...settings}
                      afterChange={index => this.setState({ index })}
                    > */}
                        {data.map((g, i) => (
                          <a
                            key={i}
                            href={`/blog/${g.blog && g.blog.slug}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'block',
                            }}
                          >
                            <Image src={frame} width="100%" height="auto" />
                            <PostImage
                              src={g.blog && g.blog.img_blog}
                              style={{
                                zIndex: -1,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                            />
                          </a>
                        ))}
                        {/* </Slider> */}
                      </MainPost>
                      {/* <Image
                        src={require('../../assets/images/imac-bottom.png')}
                        width="100%"
                        margin="0 0 15px"
                      /> */}
                    </Col>
                    <Col
                      sm={4}
                      md={{ span: 3, offset: 1 }}
                      padding="0 15px 0 30px"
                      className="other-blog"
                    >
                      <Text
                        fontWeight="bold"
                        margin="0 0 20px"
                        fontSize="16px"
                        textAlign="center"
                        mobileStyle={{ marginTop: 30 }}
                      >
                        Other Blog
                      </Text>
                      <OtherWrapper>
                        {recent.map((g, i) => (
                          <a
                            key={i}
                            href={`/blog/${g.slug}`}
                            onMouseEnter={() =>
                              this.changeState('data', [{ blog: g }])
                            }
                          >
                            <Row margin="0 -15px 20px">
                              <Col xs={5}>
                                <Relative
                                  style={{
                                    background: `url('${g.background}')`,
                                    backgroundSize: '100%',
                                    padding: '0 10px',
                                  }}
                                >
                                  <Image
                                    className="rounded"
                                    src={g.picture}
                                    width="100%"
                                  />
                                </Relative>
                              </Col>
                              <Col xs={7} padding="0 0 0 15px">
                                <Text margin="0 0 5px" textDecoration="none">
                                  {g.title}
                                </Text>
                                <Text fontSize="13px" textDecoration="none">
                                  {moment(
                                    g.published_date,
                                    'YYYY-MM-DD HH:mm:ss',
                                  ).format('DD MMMM YYYY HH:mm')}
                                </Text>
                              </Col>
                            </Row>
                          </a>
                        ))}
                      </OtherWrapper>
                      {this.state.loadmore ? (
                        <Text
                          onClick={() => this.fetchData(this.state.page + 1)}
                          textAlign="center"
                          margin="5px 0 0"
                          cursor="pointer"
                          fontSize="13px"
                          textDecoration="underline"
                        >
                          load more
                        </Text>
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              )}
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Blog)
