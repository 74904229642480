
import React from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Relative,
  Absolute,
  Icon,
  Flex,
} from '../../../assets/styles/components'

const ProductCategoryList = ({
    data,
    location,
}) => {
  return (
    
    <Row margin="0 -15px 10px">
      <Col md={12} style={{ marginBottom: '20px',paddingRight:'2px' }}>
        { data.length > 0 && data.map((g, i) => (
        <>
        <Text margin="0 0 10px" fontSize="14px" fontWeight="bold">
          {g.name}
        </Text>
        <Relative>
            
            <ul className="product-category-list">
                { g.child.length > 0 && g.child.map((item, index) => (
                <li key={index}>
                    <a href={`/subcategory/${item.slug}`}>{location.pathname === `/subcategory/${item.slug}` || item.subchild.find(item1 => item1.slug === location.pathname.split('/')[2])? <b>{item.name}</b>: item.name } {location.pathname === `/subcategory/${item.slug}` || item.subchild.find(item1 => item1.slug === location.pathname.split('/')[2])?<span className="fa fa-chevron-down"></span>: <span className="fa fa-chevron-right"></span>}</a>
                    <ul className={location.pathname === `/subcategory/${item.slug}` || item.subchild.find(item1 => item1.slug === location.pathname.split('/')[2]) ? 'sub-category-list' : 'sub-category-list d-none'}>
                        { item.subchild.length > 0 && item.subchild.map((item1, index1) => (
                            <li key={index1} > 
                             <a href={`/subcategory/${item1.slug}`} className={location.pathname === `/subcategory/${item1.slug}` ? 'active' : ''}>- {item1.name}</a>
                            </li>
                        ))}
                    </ul>
                </li>
                ))}
            </ul>
        </Relative></>
        ))}
      </Col>
    
    </Row>
  )
}

export default ProductCategoryList
