import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
// import './assets/css/bootstrap.min.css'
// import './assets/css/bootstrap-theme.min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/font.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/css/style.css'
import './assets/css/tooltip-bootstrap.css'
// import './assets/css/tooltip-bootstrap_white.css'
import { AppContext } from './context/AppContext'
import Navigation from './components/Navigation/Navigation'
import { dummypic, dummypic2 } from './utils/apihost'

const refetch = true

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: {},
      token: '',
      authenticated: true,
      ready: false,
      showLogin: false,
      showForgot: false,
      showLoading: false,
      showDandanLoader: true,
      success: '',
      successCart: {},
      error: '',
      jenisbank :'',
      confirmation: null,
      paymentconfirmation : null,
      paymentorderconfirmation : null,
      categories: [],
      orderCount: 0,
      category: {},
      history: {},
      refetchCart: refetch,
      refetchOrder: false,
      refetchFooter: refetch,
      refetchSearchHistory: refetch,
      refetchCategories: refetch,
      refetchPromo: refetch,
      refetchMenu: refetch,
      contact: [],
      socialmedia: [],
      courier: [],
      historykeyword: [],
      onlineplatform: [],
      cart: [],
      promo: {},
      menu: [],
    }
  }
  componentDidMount() {
    this.fetchData()
  }
  async fetchData() {
    let data = localStorage.getItem('token')
    let data2 = localStorage.getItem('customer')
    let data3 = localStorage.getItem('category')
    let data4 = localStorage.getItem('categories')
    let authenticated = true
    if (!data || !data2) {
      authenticated = false
    }

    let token = JSON.parse(data)
    let customer = JSON.parse(data2)
    let category = JSON.parse(data3)
    let categories = JSON.parse(data4)
    if (!categories) categories = []
    this.setState({
      token,
      customer,
      category,
      categories,
      authenticated,
      ready: true,
    })
  }
  update = (key, value) => {
    this.setState({ [key]: value })
  }
  login = (customer, token) => {
    if (!customer.avatar)
      customer.avatar = customer.gender == 'Laki-laki' ? dummypic : dummypic2
    this.setState({
      customer,
      token,
      authenticated: true,
      refetchCart: true,
      refetchOrder: true,
      refetchSearchHistory: true,
    })
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('customer', JSON.stringify(customer))
  }
  updateCategory = (category) => {
    localStorage.setItem('category', JSON.stringify(category))
    this.setState({ category })
  }
  logout = () => {
    this.setState({
      customer: {},
      token: '',
      authenticated: false,
      cart: [],
      orderCount: 0,
      historykeyword: [],
    })
    localStorage.removeItem('token')
    localStorage.removeItem('customer')
  }
  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          update: this.update,
          login: this.login,
          logout: this.logout,
          updateCategory: this.updateCategory,
        }}
      >
        <BrowserRouter>
          {this.state.ready ? <Navigation /> : null}
        </BrowserRouter>
      </AppContext.Provider>
    )
  }
}

export default App
