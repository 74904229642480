import React from 'react'
import PropTypes from 'prop-types'
import {
  RadioWrapper,
  LabelStyled,
  RadioStyled,
  RadioTextStyled,
  RadioFilled,
} from './styles'

const Radio = ({ label, text, name, margin, value, ...props }) => {
  return (
    <RadioWrapper margin={margin}>
      {/* {label ? <LabelStyled>{label}</LabelStyled> : null} */}
      <RadioStyled {...props} id={value}>
        {value ? <RadioFilled /> : null}
      </RadioStyled>
      {/* <RadioTextStyled htmlFor={value}>{text}</RadioTextStyled> */}
    </RadioWrapper>
  )
}

Radio.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Radio.defaultProps = {
  label: '',
  text: 'Option',
  margin: '0 0 25px',
}

export default Radio
