import React, { useContext } from 'react'
import { CategoryWrapper, CategoryList } from '../styles'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { Text, Flex } from '../../../assets/styles/components'
import Spinner from '../../../components/Loader/Spinner'
import { AppContext } from '../../../context/AppContext'

const ProductSearchCategory = ({ data, loading, changeState }) => {
  const { state } = useContext(AppContext)
  return (
    <Row margin="0 -15px 30px">
      <Col md={12}>
        <Text margin="0" isTitle="true" fontSize="26px" fontWeight="bold">
          {data.mainCategory && data.mainCategory.name}
        </Text>
        <Text margin="0 0 5px" isTitle="true" fontSize="20px" fontWeight="bold">
          {data.secondCategory && data.secondCategory.name}
        </Text>
      </Col>
      <CategoryWrapper>
        {loading ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          data.thirdCategories.map((g, i) => (
            <Col key={i} md={12} margin="0 0 5px">
              <CategoryList
                active={data.thirdCategory && data.thirdCategory.id === g.id}
                onClick={() =>
                  changeState('category', { ...data, thirdCategory: g })
                }
              >
                {g.name}
              </CategoryList>
            </Col>
          ))
        )}
      </CategoryWrapper>
    </Row>
  )
}

export default ProductSearchCategory
