import React from 'react'
import { ProductListStyled, ImageWrapper } from '../styles'
import Rating from '../../../components/Rating/Rating'
import { Image, Absolute, Text } from '../../../assets/styles/components'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Button from '../../../components/Button/Button'
import ButtonLink from '../../../components/Button/ButtonLink'
import { white, breakpoints, blue, orange } from '../../../assets/styles/styles'
import MediaQuery from 'react-responsive'
import ButtonLinkA from '../../../components/Button/ButtonLinkA'
import { host } from '../../../utils/apihost'

const ProductList = ({ data, add, left = false }) => {
  return (
    <ProductListStyled href={`/product/${data.slug}`}>
      <ImageWrapper>
        <Image
          className="product-image"
          src={
            data.picture || 'https://via.placeholder.com/200x160?text=No+Image'
          }
          // width="100%"
          width="auto"
          padding="5px"
          height="155px"
          margin="0 auto 10px"
          objectFit="contain"
          display="block"
          mobileStyle={{ height: 110 }}
          mediumStyle={{ height: 110 }}
        />
        <MediaQuery minWidth={breakpoints[2]}>
          <Absolute
            bottom="5px"
            width="100%"
            left="50%"
            transform="translateX(-50%)"
            className="btn-wrapper"
          >
            <Button
              color="orange"
              padding="4px 10px"
              block
              fontSize="12px"
              margin="0 0 5px"
              onClick={e => {
                e.preventDefault()
                add(data.id, data)
              }}
            >
              Add to cart
            </Button>
            <ButtonLinkA
              href={`/product/${data.slug}`}
              color="blue"
              padding="4px 10px"
              block
              fontSize="12px"
              hoverStyle={{ color: white }}
            >
              View
            </ButtonLinkA>
          </Absolute>
        </MediaQuery>
      </ImageWrapper>

      <Text
        fontWeight="bold"
        textAlign={left ? 'left' : 'center'}
        fontSize="12px"
        style={{ height: 25, overflow: 'hidden' }}
      >
        {data.brand && data.brand.name}
      </Text>
      <Text
        color="#999"
        textAlign={left ? 'left' : 'center'}
        fontSize="12px"
        margin="0 0 5px"
        style={{ height: 16, overflow: 'hidden' }}
      >
        {data.name}
      </Text>
      <Text
        fontWeight="bold"
        fontSize="13px"
        color='#F37933'
        textAlign={left ? 'left' : 'center'}
      >
        Rp{' '}
        {formatMoney(
          calculateDiscount(data.price, data.discount_type, data.discount_value)
            .price,
        )}{' '}
        {data.discount_value ? (
          <span
            style={{
              fontWeight: '400',
              fontSize: 12,
              color: '#999',
              textDecoration: 'line-through',
              marginLeft: 5,
            }}
          >
            Rp {formatMoney(data.price || 0)}
          </span>
        ) : null}
      </Text>
      {data.rate ? (
        <Rating count={data.rate} left />
      ) : (
        <Text textAlign={left ? 'left' : 'center'} color={blue} fontSize="12px">
          Not rated
        </Text>
      )}
      <MediaQuery maxWidth={breakpoints[0]}>
        <Button
          color="orange"
          padding="6px 10px"
          block
          fontSize="12px"
          margin="10px 0 5px"
          onClick={e => {
            e.preventDefault()
            add(data.id, data)
          }}
        >
          Add to cart
        </Button>
        <ButtonLinkA
          href={`/product/${data.slug}`}
          color="blue"
          padding="4px 10px"
          block
          fontSize="12px"
          hoverStyle={{ color: white }}
        >
          View
        </ButtonLinkA>
      </MediaQuery>
      {/* {data.badge ? (
        <Absolute top="0" right="0">
          <Image src={data.badge} width="60px" height="60px" />
        </Absolute>
      ) : null} */}
      {data.discount_type ? (
        <Absolute top="0" left="0">
          <Text
            style={{ backgroundColor:'#BB2027' , color: 'white', padding: '4px 7px', borderRadius: '10px 0px 0px 0px' }}
            fontSize="10px"
          >
            {data.discount_type==='persentase'?`${data.discount_value}%`:'SALE'}
          </Text>
        </Absolute>
      ) : null}
    </ProductListStyled>
  )
}

export default ProductList
