import styled from '@emotion/styled'
import { white, breakpoints } from '../../assets/styles/styles'
import bg from '../../assets/images/bg-mainblog.png'

const MainPost = styled.div`
  width: 100%;
  height: auto;
  // background: #ffffff url(${bg}) no-repeat;
  // background-size: cover;
  // padding: 4% 4% 4% 3.6%;
  // border: 25px solid black;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  position: relative;
  overflow: hidden;
  // background-color: ${white};

  @media (max-width: ${breakpoints[0]}) {
    height: auto;
    border-width: 18px;
  }

  @media (max-width: 320px) {
    height: 180px;
  }
`

const PostImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: 50% 35%;
  position: relative;
  @media (max-width: ${breakpoints[0]}) {
    // height: 140px;
  }
`

const OtherWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 30vw;
  @media (max-width: ${breakpoints[0]}) {
    overflow-y: visible;
    max-height: 50vh;
  }
`

export { MainPost, PostImage, OtherWrapper }
