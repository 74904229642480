import moment from 'moment'
import { typeChecker } from './helper'

export function formatMoney(value) {
  return parseInt(value)
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function isInt(value) {
  var er = /^-?[0-9]+$/

  return er.test(value)
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error('timeout'))
    }, ms)
    promise.then(resolve, reject)
  })
}

export function numberOnly(value) {
  return value.toString().replace(/[^0-9]/g, '')
}

export function localeDay(day) {
  switch (day) {
    case 'Mon':
      return 'Senin'
    case 'Tue':
      return 'Selasa'
    case 'Wed':
      return 'Rabu'
    case 'Thu':
      return 'Kamis'
    case 'Fri':
      return 'Jumat'
    case 'Sat':
      return 'Sabtu'
    case 'Sun':
      return 'Minggu'
    default:
      return day
  }
}

export function calculateDiscount(price = 0, type = '', value = 0) {
  price = parseInt(price || 0)
  value = parseInt(value || 0)

  let discount = 0
  if (type == 'Persentase') {
    discount = (price * value) / 100
    price -= discount
  } else if (type == 'Langsung') {
    price -= value
    discount = value
  }

  if (!price) price = 0
  if (!discount) discount = 0

  return { price, discount }
}

const timeouttime = 12000
const errormessage = 'Server error. Please try again'

export async function fetchGet(url, token, withToken = true) {
  let responseJSON
  let headers = {
    Accept: 'application/json',
  }

  if (withToken) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  await timeout(
    timeouttime,
    fetch(url, {
      headers,
    }),
  )
    .then(async function(response) {
      if (response.status === 500) {
        responseJSON = {
          success: false,
          message: errormessage,
        }
      } else if (response.status === 403) {
        responseJSON = {
          success: false,
          message: 'forbidden',
        }
      } else {
        responseJSON = await response.json()
        responseJSON = {
          success: responseJSON.Status === 'OK' || responseJSON.status === 'OK',
          data: responseJSON.Data,
          message: responseJSON.Message,
        }
      }
    })
    .catch(function(error) {
      responseJSON = {
        success: false,
        message: 'timeout',
      }
    })
  return responseJSON
}

export async function fetchPost(
  url,
  data,
  token,
  useJSON = true,
  withToken = true,
) {
  let responseJSON
  let headers = {
    Accept: 'application/json',
  }

  if (useJSON) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    }
  }

  if (withToken) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  await timeout(
    timeouttime,
    fetch(url, {
      method: 'POST',
      headers,
      body: data,
    }),
  )
    .then(async function(response) {
      if (response.status === 500) {
        responseJSON = {
          success: false,
          message: errormessage,
        }
      } else if (response.status === 403) {
        responseJSON = {
          success: false,
          message: 'forbidden',
        }
      } else {
        responseJSON = await response.json()
        responseJSON = {
          success: responseJSON.Status === 'OK' || responseJSON.status === 'OK',
          data: responseJSON.Data,
          message: responseJSON.Message,
        }
      }
    })
    .catch(function(error) {
      responseJSON = {
        success: false,
        message: 'timeout',
      }
    })
  return responseJSON
}
