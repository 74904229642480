import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import {
  Relative,
  Image,
  Text,
  Absolute,
  Icon,
} from '../../../assets/styles/components'
import { BlogStyled, BlogFooter } from '../styles'
import ProductList from '../../Product/components/ProductList'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { Link } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { orange } from '../../../assets/styles/styles'

const BestChoice = ({ data, text, isTablet }) => {
  const scroll = useRef()
  const [hide, setHide] = useState(false)
  return (
    <Relative>
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            background: `url('${text.picture}')`,
            display: 'inline-block',
            margin: '0 0 15px',
            backgroundSize: '100%',
          }}
        >
          <Text
            isTitle="true"
            fontWeight="bold"
            fontSize="28px"
            textAlign="center"
          >
            {text.content}
          </Text>
        </div>
      </div>
      {/* <div
        style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}
      > */}
      <Scrollbars
        ref={scroll}
        className="best-scrollview"
        style={{ height: isTablet ? 150 : 300 }}
        onScroll={e => {
          if (e.target.scrollTop == 200) setHide(true)
          else {
            if (!hide) return false
            setHide(false)
          }
        }}
        // renderTrackHorizontal={() => <div></div>}
      >
        <Row padding="0 30px">
          {data.map((g, i) => (
            <Col xs={6} key={i} padding="0">
              <a href={g.link}>
                <Image
                  className="rounded"
                  src={g.picture}
                  width="90px"
                  margin="0 auto 10px"
                  display="block"
                  height="90px"
                  objectFit="contain"
                  mediumStyle={{ width: 50, height: 50 }}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Scrollbars>
      {hide ? null : (
        <Absolute
          left="50%"
          bottom="10px"
          transform="translateX(-50%)"
          onClick={() => scroll.current.scrollToBottom()}
        >
          <Icon
            className="fa fa-angle-double-down fa-lg"
            color={orange}
            cursor="pointer"
          />
        </Absolute>
      )}
    </Relative>
  )
}

export default BestChoice
