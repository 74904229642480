import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getData(token) {
    let responseJSON = await fetchGet(baseURL + '/cart/get', token)
    return responseJSON
  },

  async updateCart(CartId, Qty, token) {
    let form = JSON.stringify({
      CartId,
      Qty,
    })
    let responseJSON = await fetchPost(baseURL + '/cart/update', form, token)
    return responseJSON
  },

  async deleteCart(CartId, token) {
    let form = JSON.stringify({
      CartId,
    })
    let responseJSON = await fetchPost(baseURL + '/cart/delete', form, token)
    return responseJSON
  },

  async createTransaction(
    CustomerAddressId,
    CourierServiceId,
    VoucherIds,
    token,
  ) {
    let form = JSON.stringify({
      CustomerAddressId,
      CourierServiceId,
      VoucherIds,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/create',
      form,
      token,
    )
    return responseJSON
  },

  async checkTransaction(
    CustomerAddressId,
    CourierServiceId,
    VoucherIds,
    token,
  ) {
    let form = JSON.stringify({
      CustomerAddressId,
      CourierServiceId,
      VoucherIds,
    })
    let responseJSON = await fetchPost(
      baseURL + '/kredivo/kalkulator',
      form,
      token,
    )
    return responseJSON
  },

  async snapToken(TransactionId, jnsbank, token) {
    let form = JSON.stringify({ 
      TransactionId, 
      jnsbank 
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/snaptokenbeta',
      form,
      token,
    )
    return responseJSON
  },

  //Kredivo
  async snapTokenKredivo(TransactionId, token) {
    let form = JSON.stringify({ TransactionId })
    let responseJSON = await fetchPost(
      baseURL + '/kredivo/checkout',
      form,
      token,
    )
    return responseJSON

  },

  async checkVoucher(promo_code,courier_service_id, totalprice, token) {
    let form = JSON.stringify({
      promo_code,
      courier_service_id,
      totalprice
    })
    let responseJSON = await fetchPost(baseURL + '/voucher/check',
      form, 
      token
    )
    return responseJSON
  },

  async getCourier(token) {
    let responseJSON = await fetchGet(baseURL + '/courier/get', token)
    return responseJSON
  },

  async checkDelivery(CustomerAddressId, CourierId, token) {
    let form = JSON.stringify({
      CustomerAddressId,
      CourierId,
    })
    let responseJSON = await fetchPost(
      baseURL + '/courier/checkPrice',
      form,
      token,
    )
    return responseJSON
  },
}
