import React, { useContext } from 'react'
import Modal from '../../../components/Modal/Modal'
import { Text, Icon } from '../../../assets/styles/components'
import { blue } from '../../../assets/styles/styles'
import Button from '../../../components/Button/Button'
import { AppContext } from '../../../context/AppContext'

const ModalSuccess = ({ open, message, close }) => {
  const { update } = useContext(AppContext)
  return (
    <Modal open={open} width="25%" padding="15px 20px">
      <Text textAlign="center">
        <Icon
          className="fa fa-check-circle"
          style={{ fontSize: 80 }}
          color={blue}
        />
      </Text>
      <Text
        textAlign="center"
        fontWeight="bold"
        fontSize="18px"
        margin="10px 0 15px"
      >
        {message}
      </Text>
      <Button
        color="blue"
        padding="8px 20px"
        block
        onClick={() => {
          close()
          update('showLogin', true)
        }}
      >
        Login
      </Button>
    </Modal>
  )
}

export default ModalSuccess
