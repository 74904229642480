import styled from '@emotion/styled'
import { blue, white, breakpoints } from '../../assets/styles/styles'

const Wrapper = styled.div`
  padding: 20px 25px;
  border-radius: 10px;
  // box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  margin-bottom: 30px;
  background-color: ${white};
`

const Chip = styled.div`
padding: 8px 20px;
color: ${props => (props.active ? white : '#999')};
border-width: 1px;
border-style: solid;
font-weight: ${props => (props.active ? 'bold' : '400')}
border-color: ${props => (props.active ? blue : '#999')};
background-color: ${props => (props.active ? blue : white)};
margin: 0 15px 0 0;
border-radius: 20px;
display: inline-block;
cursor: pointer;
	
@media (max-width: ${breakpoints[0]}) {
	margin-bottom: 7px;
}
`

export { Wrapper, Chip }
