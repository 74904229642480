import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getProductCategory(category, token) {
    let responseJSON = await fetchGet(
      baseURL + '/category?Category=' + category,
      token,
    )
    return responseJSON
  },

  async getProductCategoryId(category, token) {
    let responseJSON = await fetchGet(
      baseURL + '/categorybyid?Category=' + category,
      token,
    )
    return responseJSON
  },

  async getFeaturedProduct(token) {
    let responseJSON = await fetchGet(baseURL + '/featuredproduct', token)
    return responseJSON
  },

  async getBrandOnCategory(keyword, category, token) {
    let responseJSON = await fetchGet(
      baseURL + '/brand?Keyword=' + keyword + '&Category=' + category,
      token,
    )
    return responseJSON
  },

  async getProductList(page, perpage, orderby, category, brand, token) {
    let FormData = require('form-data')
    let form = new FormData()
    form.append('Page', page)
    form.append('Perpage', perpage)
    form.append('Orderby', orderby)
    form.append('MainCategory', category)
    for (let i = 0; i < brand.length; i++) {
      form.append('Brand[' + i + ']', brand[i])
    }
    let responseJSON = await fetchPost(
      baseURL + '/product',
      form,
      token,
      false,
      true,
    )
    return responseJSON
  },

  // async getProductList(category, brand, token) {
  //   let responseJSON = await fetchGet(
  //     baseURL + '/product?MainCategory=' + category,
  //     token,
  //   )
  //   return responseJSON
  // },

  async getProductDetails(product, token) {
    let responseJSON = await fetchGet(
      baseURL + '/productdetail?Produk=' + product,
      token,
    )
    return responseJSON
  },

  async search(keyword, token) {
    let responseJSON = await fetchGet(
      baseURL + '/search?Keyword=' + keyword,
      token,
    )
    return responseJSON
  },

  async addToCart(ProductId, Qty, token) {
    let form = JSON.stringify({
      ProductId,
      Qty,
    })
    let responseJSON = await fetchPost(baseURL + '/cart/add', form, token)
    return responseJSON
  },

  async getSearchHistory(token) {
    let responseJSON = await fetchGet(baseURL + '/searchhistory', token)
    return responseJSON
  },

  async getReview(id, Page, token) {
    let form = JSON.stringify({ Page })
    let responseJSON = await fetchPost(
      baseURL + '/productreview/' + id,
      form,
      token,
    )
    return responseJSON
  },

  async getReviewNonTrans(id, Page, token) {
    let form = JSON.stringify({ Page })
    let responseJSON = await fetchPost(
      baseURL + '/reviewlist/' + id,
      form,
      token
    )
    return responseJSON
  },

  async review(Product, Rate, Review, token) {
    let form = JSON.stringify({ Product, Rate, Review })
    let responseJSON = await fetchPost(
      baseURL + '/reviewproductnontrans',
      form,
      token,
    )
    return responseJSON
  },

  async getCheckReview(id, token) {
    let responseJSON = await fetchGet(
      baseURL + '/getproductreview/' + id,
      token
    )
    return responseJSON
  },

  async getCountReview(id) {
    let responseJSON = await fetchGet(
      baseURL + '/getproductreviewall/' + id
    )
    return responseJSON
  },
}
