import React, { useContext } from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { Text, Flex } from '../../../assets/styles/components'
import ProductList from './ProductList'
import Spinner from '../../../components/Loader/Spinner'
import { AppContext } from '../../../context/AppContext'
import { ProductListWrapper } from '../styles'
import Select from '../../../components/Select/Select'

const ProductSearchList = ({
  data,
  loading,
  totalcount,
  category,
  add,
  brand,
  frombrand,
  titletext,
  orderType,
  orderTypeList,
  changeState,
  orderCount,
  orderCountList,
  page,
}) => {
  const { state } = useContext(AppContext)
  return (
    <Row>
      <Col md={6}>
        <Text margin="10px 0 15px" fontSize="12px">
          Showing <strong>{data.length}</strong>{' '}
          <strong>
            product(s) for{' '}
            {frombrand
              ? (brand && brand.name) || ''
              : titletext
              ? titletext
              : category.thirdCategory.name}
          </strong>{' '}
          (<strong>{page==1 ? 1 : ((page-1)*orderCount.value)+1} - {page*orderCount.value}</strong> from <strong>{totalcount}</strong>)
        </Text>
      </Col>

      <Col md={4}>
      <Flex justifyContent="flex-end">
        <div style={{ display: 'flex', alignItems: 'center', height: '38px', marginBottom:'12px' }}>
          <label style={{ marginRight: '10px', width: '70px', fontSize: '12px', textAlign: 'right' }}>Sort By</label>
        </div>
      <Select
            value={orderType}
            option={orderTypeList}
            optionKey="name"
            onChange={changeState}
            stateKey="orderType"
            margin="0 0 15px"
            padding="4px 12px"
            style={{ fontSize: '12px' }}
          />
        </Flex>
      </Col>

      <Col md={2} style={{ paddingLeft:'0px' }}>
      <Flex justifyContent="flex-end">
        <div style={{ display: 'flex', alignItems: 'center', height: '38px', marginBottom:'12px' }}>
          <label style={{ marginRight: '10px', width: '80px', fontSize: '12px', textAlign: 'right' }}>Tampilkan</label>
        </div>
      <Select
            value={orderCount}
            option={orderCountList}
            optionKey="name"
            onChange={changeState}
            stateKey="orderCount"
            margin="0 0 15px"
            padding="4px 12px"
            style={{ fontSize: '12px' }}
          />
        </Flex>
      </Col>

      {loading ? (
        <Col md={12} padding="10px">
          <Flex justifyContent="center">
            <Spinner className="fa-2x" />
          </Flex>
        </Col>
      ) : data.length > 0 ? (
        data.map((g, i) => (
          <ProductListWrapper key={i}>
            <ProductList data={g} add={add} left />
          </ProductListWrapper>
        ))
      ) : (
        <Col md={12} padding="10px">
          <Flex justifyContent="center">
            <Text>No product found</Text>
          </Flex>
        </Col>
      )}
    </Row>
  )
}

export default ProductSearchList
