import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Separator,
  BoxShadow,
  Flex,
  Icon,
  FlexCell,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import Help from '../../components/Help/Help'
import { Redirect, Link } from 'react-router-dom'
import { dummyplaceholder } from '../../utils/apihost'
import { useMediaQuery } from 'react-responsive'

class SubCategory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: { name: '', child: [] },
      maindata: { name: '', child: [] },
      indexes: [],
      isMobile: false,
      id: '',
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  resize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 })
  }

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
    window.removeEventListener('resize', this.resize)
  }
  async fetchData() {
    const { token, categories } = this.props.context.state
    const { match, location, parent, root } = this.props
    let id = location.pathname.split('-')
    id = id[id.length - 1]

    this.setState({ loading: true, errordata: false })
    let data = {}

    if (parent) {
      data = categories.find(g => g.id == parent)
      data = data.child.find(g => g.id == id)
    } else {
      data = categories.find(g => g.id == id)
    }
    let maindata = data
    if (!data) {
      this.props.context.update('showDandanLoader', false)
      window.location.replace('/')
    }
    this.setState({
      id,
      data,
      maindata,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  url = (slug, parentid) => {
    const { id } = this.state
    const { parent, root } = this.props
    let uri = ''
    if (!parent) uri = `/subcategory/${slug}?parent=${id}`
    else uri = `/subcategory/${slug}?parent=${id}&root=${parent}`
    return uri
  }

  render() {
    const {
      loading,
      errordata,
      data,
      indexes,
      maindata,
      isMobile,
      id,
    } = this.state
    const { parent, root } = this.props
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 3em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > ${data.name}`} margin="0 0 10px" />
              <Container padding="0">
                <Row margin="0 -15px 40px">
                  <Col md={{ span: 10, offset: 1 }}>
                    <Text
                      fontSize="26px"
                      fontWeight="bold"
                      textAlign="center"
                      margin="0 0 20px"
                    >
                      {data.name}
                    </Text>

                    <Row
                      justifyContent={
                        (data.child && data.child.length > 6) || isMobile
                          ? 'flex-start'
                          : 'center'
                      }
                    >
                      {(data.child &&
                        data.child.map((g, i) => (
                          <Col key={i} md={2} xs={6} margin="0 0 15px">
                            <a
                              href={
                                // `/subcategory/${g.slug}`
                                this.url(g.slug, g.id)
                              }
                            >
                              <div style={{ cursor: 'pointer' }}>
                                <Image
                                  src={g.picture}
                                  width="140px"
                                  height="140px"
                                  borderRadius="50%"
                                  margin="0 auto 5px"
                                  display="block"
                                  mobileStyle={{
                                    width: '100%',
                                    height: 'auto',
                                  }}
                                  mediumStyle={{
                                    width: '100%',
                                    height: 'auto',
                                  }}
                                />
                                <Text
                                  textAlign="center"
                                  fontWeight="bold"
                                  fontSize="15px"
                                  margin="10px 0 0"
                                >
                                  {g.name}
                                </Text>
                              </div>
                            </a>
                          </Col>
                        ))) || (
                        <Text
                          margin="15px 0"
                          fontSize="16px"
                          textAlign="center"
                        >
                          No category found
                        </Text>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row></Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
        {/* <Help /> */}
      </Fragment>
    )
  }
}

export default withContext(SubCategory)
