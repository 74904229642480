import React, { Fragment } from 'react'
import { ActionWrapper, ActionList } from './styles'
import { white, orange, grey2 } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  FlexCell,
  Icon,
  Image,
  Relative,
} from '../../assets/styles/components'
import Badge from '../Badge/Badge'
import dummy from '../../utils/dummy'
import { formatMoney } from '../../utils/format'
import Button from '../Button/Button'
import ButtonLink from '../Button/ButtonLink'
import ajax from '../../routes/Cart/ajax'
import { withContext } from '../../utils/helper'
import Spinner from '../Loader/Spinner'

class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      errordata: false,
      data: [],
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.fetchData()
  }
  componentDidUpdate(prevProps) {
    if (
      !prevProps.context.state.refetchCart &&
      this.props.context.state.refetchCart
    ) {
      this.fetchData()
      this.props.context.update('refetchCart', false)
    }
  }

  async fetchData() {
    const { token, refetchCart } = this.props.context.state
    if (!refetchCart) return false
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getData(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    this.setState({
      data,
      loading: false,
    })
    this.props.context.update('cart', data)
    this.props.context.update('refetchCart', false)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside = event => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.setState({ open: false })
    }
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    const { loading, errordata, data, open } = this.state
    const { cart } = this.props.context.state
    return (
      <Relative>
        <Relative cursor="pointer" onClick={this.toggle}>
          <Image
            src={require('../../assets/images/icon-cart-2.png')}
            width={28}
          />
          <Badge count={cart.length} withNumber top="-3px" right="-7px" />
        </Relative>
        {open ? (
          <ActionWrapper ref={ref => (this.wrapper = ref)}>
            {loading ? (
              <Flex justifyContent="center">
                <Spinner className="fa-lg" />
              </Flex>
            ) : cart.length == 0 ? (
              <Text textAlign="center" fontSize="13px">
                No item in cart
              </Text>
            ) : (
              <Fragment>
                {cart.map((g, i) => (
                  <ActionList key={i} onClick={() => {}} style={{maxHeight: 200, overflow: 'auto'}}>
                    <Flex justifyContent="space-between" >
                      <FlexCell>
                        <Flex>
                          <Image src={g.avatar} width="35px" />
                          <FlexCell margin="0 0 0 10px">
                            <Text fontSize="12px">{g.name}</Text>
                            <Text fontSize="12px">{g.category_name}</Text>
                          </FlexCell>
                        </Flex>
                      </FlexCell>
                      <FlexCell>
                        <Text color={orange} textAlign="right" fontSize="12px">
                          Rp {formatMoney(g.price)}
                        </Text>
                        <Text textAlign="right" color="#999" fontSize="12px">
                          {g.qty} Pc(s)
                        </Text>
                      </FlexCell>
                    </Flex>
                  </ActionList>
                ))}

                <Text textAlign="center" fontSize="11px">
                  Total : <strong>{cart.length} Item</strong>
                </Text>

                <ButtonLink
                  to="/cart"
                  color="orange"
                  padding="6px 20px"
                  block
                  margin="10px 0 0"
                >
                  Checkout
                </ButtonLink>
              </Fragment>
            )}
          </ActionWrapper>
        ) : null}
      </Relative>
    )
  }
}

export default withContext(Cart)
