import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Flex,
  FlexCell,
  Absolute,
  BoxShadow,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import { white } from '../../assets/styles/styles'
import { OtherWrapper } from './styles'
import Calendar from '../../components/Calendar/Calendar'
import Select from '../../components/Select/Select'
import PageTitle from '../../components/PageTitle/PageTitle'
import titlebg from '../../assets/images/bg-event-title.png'

class Event extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      category: { id: '', name: 'All Area' },
      categorylist: [],
      data: [],
      highlight: {},
      page: 1,
      loadmore: true,
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData(1)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.category != this.state.category) this.fetchData(1)
  }

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData(page = this.state.page) {
    const { token } = this.props.context.state
    const { category } = this.state
    this.setState({ loading: true, errordata: false })
    let res0 = await ajax.getCategory(token)
    if (!res0.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let res = await ajax.getData(page, category ? category.id : '', token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let highlight = this.state.highlight
    let data = res.data
    if (page > 1) data = [...this.state.data, ...data]
    let categorylist = [{ id: '', name: 'All Area' }, ...res0.data]
    this.setState({
      categorylist,
      data,
      highlight,
      loading: false,
      page,
      loadmore: res.data.length == 8,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  render() {
    const {
      loading,
      errordata,
      data,
      highlight,
      category,
      categorylist,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Events`} margin="0 0 10px" />
              {loading ? (
                <Flex justifyContent="center">
                  <Spinner className="fa-2x" />
                </Flex>
              ) : (
                <Container padding="0">
                  <Row>
                    <Col md={12}>
                      <div style={{ textAlign: 'center', margin: '0 0 30px' }}>
                        <div
                          style={{
                            backgroundImage: `url('${titlebg}')`,
                            display: 'inline-block',
                            backgroundSize: '100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: 260,
                            // padding: '20px 60px',
                          }}
                        >
                          <Text
                            fontSize="30px"
                            fontWeight="bold"
                            textAlign="center"
                            isTitle="true"
                            color={white}
                          >
                            Events
                          </Text>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ span: 7, offset: 1 }}>
                      <Relative mobileStyle={{ marginBottom: 20 }}>
                        <Calendar data={data} />
                        {/* <a href={highlight.link} style={{ display: 'block' }}>
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          background: `-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.2)),to(rgba(0,0,0,.2))),url('') 50%/cover no-repeat`,
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                        }}
                      />
                      <Image
                        className="rounded"
                        src={highlight.photo}
                        width="100%"
                        height="50vh"
                        mobileStyle={{ height: '30vh' }}
                      />
                      <Absolute left="15px" bottom="15px">
                        <Text
                          fontSize="17px"
                          fontWeight="bold"
                          margin="0 0 5px"
                          color={white}
                        >
                          {highlight.name}
                        </Text>
                        <Text color={white} fontSize="15px">
                          {moment(
                            highlight.start_time,
                            'YYYY-MM-DD HH:mm:ss',
                          ).format('DD MMMM YYYY HH:mm')}
                        </Text>
                      </Absolute>
                    </a> */}
                      </Relative>
                    </Col>
                    <Col md={3}>
                      <Relative
                        padding="0 15px 0 30px"
                        mobileStyle={{ padding: 0 }}
                      >
                        <Select
                          placeholder="- Choose Location -"
                          value={category}
                          option={categorylist}
                          optionKey="name"
                          onChange={this.changeState}
                          stateKey="category"
                          margin="0 0 15px"
                          wrapperStyle={{
                            boxShadow: '0 1px 20px 0 rgba(155, 155, 155, 0.2)',
                            backgroundColor: white,
                          }}
                        />

                        <BoxShadow
                          padding="15px 0"
                          backgroundColor={white}
                          borderRadius="10px"
                        >
                          <Text
                            fontWeight="bold"
                            margin="0 0 20px"
                            fontSize="16px"
                            textAlign="center"
                          >
                            All Events
                          </Text>
                          <OtherWrapper style={{ padding: '0 20px' }}>
                            {data.length > 0 ? (
                              data.map((g, i) => (
                                <Row margin="0 -15px 20px" key={i}>
                                  <Col xs={4}>
                                    <a
                                      href={g.link}
                                      style={{ display: 'block' }}
                                      target="_blank"
                                    >
                                      <Image
                                        className="rounded"
                                        src={g.photo}
                                        width="100%"
                                      />
                                    </a>
                                  </Col>
                                  <Col xs={8} padding="0 10px 0 15px">
                                    <a
                                      href={g.link}
                                      style={{ display: 'block' }}
                                      target="_blank"
                                    >
                                      <Text
                                        margin="0 0 5px"
                                        wordBreak="break-all"
                                      >
                                        {g.name}
                                      </Text>
                                      <Text fontSize="13px">
                                        {moment(
                                          g.start_time,
                                          'YYYY-MM-DD HH:mm:ss',
                                        ).format('DD MMMM YYYY HH:mm')}
                                      </Text>
                                    </a>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <Text textAlign="center">No event yet</Text>
                            )}
                          </OtherWrapper>
                          {this.state.loadmore ? (
                            <Text
                              onClick={() =>
                                this.fetchData(this.state.page + 1)
                              }
                              textAlign="center"
                              margin="5px 0 0"
                              cursor="pointer"
                              fontSize="13px"
                              textDecoration="underline"
                            >
                              load more
                            </Text>
                          ) : null}
                        </BoxShadow>
                      </Relative>
                    </Col>
                  </Row>
                </Container>
              )}
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Event)
