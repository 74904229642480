import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  CheckboxWrapper,
  CheckboxStyled,
  Checkmark,
  CheckboxText,
} from './styles'
import { Flex } from '../../assets/styles/components'

const Checkbox = ({ text, name, margin, width, height, ...props }) => {
  return (
    <Flex alignItems="flex-start" margin={margin}>
      <CheckboxWrapper width={width} height={height}>
        <CheckboxStyled {...props} type="checkbox" id={text} />
        <Checkmark width={width} height={height} />
      </CheckboxWrapper>
      {text ? <CheckboxText htmlFor={text}>{text}</CheckboxText> : null}
    </Flex>
  )
}

Checkbox.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  height: PropTypes.number,
}

Checkbox.defaultProps = {
  label: '',
  text: '',
  margin: '0 0 25px',
  width: 15,
  height: 15,
}

export default Checkbox
