import React, { Fragment, useContext } from 'react'
import { Wrapper } from '../styles'
import {
  Flex,
  FlexCell,
  Text,
  Separator,
  Icon,
  Image,
} from '../../../assets/styles/components'
import moment from 'moment'
import { Col, Row } from '../../../components/Bootstrap/Bootstrap'
import { blue, orange } from '../../../assets/styles/styles'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Button from '../../../components/Button/Button'
import { AppContext } from '../../../context/AppContext'
import ButtonLink from '../../../components/Button/ButtonLink'
import Countdown from 'react-countdown-now'
import ButtonLinkA from '../../../components/Button/ButtonLinkA'
import { host } from '../../../utils/apihost'

export const mapStatus = {
  // 0: 'Menunggu Pembayaran',
  // 1: 'Menunggu Konfirmasi',
  // 2: 'Pesanan Diproses',
  // 3: 'Sedang Dikirim',
  // 4: 'Pesanan Tiba',
  // 5: 'Pesanan Selesai',
  // 6: 'Pesanan Ditolak',

  0: 'Waiting for Payment',
  1: 'Waiting Confirmation',
  2: 'Processed',
  3: 'Sent',
  4: 'Arrived',
  5: 'Completed',
  6: 'Rejected',
  7: 'Pending',
}

const OrderList = ({
  data,
  viewDetails,
  viewTracking,
  viewReview,
  confirm,
  handleClick,
  changeState,
  pay,
  paykredivo,
  ...props
}) => {
  const { update } = useContext(AppContext)
  return (
    <Col md={12} padding="0">
      <Wrapper>
        <Row>
          <Col md={3} xs={6}>
            <Text>
              Order Date:{' '}
              <strong>
                {moment(data.order_datetime, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD MMMM YYYY',
                )}
              </strong>
            </Text>
          </Col>
          <Col md={3} xs={6}>
            <Text>
              Status:{' '}
              <strong style={{ color: blue }}>{mapStatus[data.status]}</strong>
              {data.status == 0 && data.payment_deadline ? (
                <Countdown
                  date={moment(
                    data.payment_deadline,
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('YYYY-MM-DD HH:mm:ss')}
                  renderer={({ days, hours, minutes, seconds, completed }) => (
                    <div>
                      {/* <strong>{days}</strong> days  */}
                      <strong>{hours}</strong> hours <strong>{minutes}</strong>{' '}
                      minutes <strong>{seconds}</strong> seconds
                    </div>
                  )}
                />
              ) : null}
            </Text>
          </Col>
          <Col md={3} xs={6}>
            <Text>
              Order ID: <strong>{data.transaction_id}</strong>
            </Text>
          </Col>
          <Col md={3} xs={6}>
            <Text>
              Total Shopping:{' '}
              <strong style={{ color: orange }}>
                Rp{' '}
                {formatMoney(
                  parseInt(data.total_price) + parseInt(data.delivery_fee) - parseInt(data.delivery_discount),
                )}
              </strong>
            </Text>
          </Col>
        </Row>

        <Separator margin="15px 0" borderWidth="2px" />

        {data.detail.map((g, i) => {
          let rating = []
          if (g.rating) {
            for (let i = 0; i < parseInt(g.rating); i++) {
              rating.push(
                <Icon
                  key={i}
                  className="fa fa-heart fa-lg"
                  color={orange}
                  margin="0 5px"
                />,
              )
            }
          }
          if (i < 2)
            return (
              <Fragment key={i}>
                <Row alignItems="center" margin="15px -15px">
                  <Col md={1}>
                    <a href={`/product/${g.slug}`} className="link">
                      <Image
                        className="rounded"
                        src={g.avatar}
                        width="100%"
                        mobileStyle={{ marginBottom: 15, width: '50%' }}
                      />
                    </a>
                  </Col>
                  <Col md={4}>
                    <a href={`/product/${g.slug}`} className="link">
                      <Text fontWeight="bold" margin="0 0 5px">
                        {g.name}
                      </Text>
                    </a>
                    <Flex>
                      <Text color={orange}>
                        Rp{' '}
                        {formatMoney(
                          calculateDiscount(
                            g.price,
                            'Langsung',
                            g.discount_value,
                          ).price,
                        )}
                      </Text>
                      {g.discount_value ? (
                        <Text
                          color="#777"
                          textDecoration="line-through"
                          margin="0 0 0 10px"
                        >
                          Rp {formatMoney(g.price || 0)}
                        </Text>
                      ) : null}
                      <Text color="#999" margin="0 0 0 10px">
                        {g.qty} Item(s)
                      </Text>
                    </Flex>
                  </Col>
                  <Col md={3}>
                    <Text
                      color="#999"
                      margin="0 0 5px"
                      mobileStyle={{ margin: '10px 0 0' }}
                    >
                      Subtotal
                    </Text>
                    <Text
                      color={orange}
                      fontWeight="bold"
                      mobileStyle={{ marginBottom: 10 }}
                    >
                      Rp{' '}
                      {formatMoney(
                        calculateDiscount(g.price, 'Langsung', g.discount_value)
                          .price * g.qty,
                      )}
                    </Text>
                    <Text>
                      Total Price : Rp{' '}{formatMoney(data.total_price+data.delivery_fee-data.delivery_discount)}
                    </Text>
                  </Col>
                  <Col md={2}>
                    {data.status == 5 ? (
                      rating.length > 0 ? ( 
                        <Flex
                          mobileStyle={{
                            justifyContent: 'center',
                            margin: '5px 0 15px',
                          }}
                        >
                          {rating}
                        </Flex>
                      ) : (
                        <Button
                          color="ghost-blue"
                          padding="10px 12px"
                          block
                          onClick={() => viewReview(g)}
                          mobileStyle={{ marginBottom: 15 }}
                        >
                          Review product
                        </Button>
                      )
                    ) : null}
                  </Col>
                  <Col md={2}>
                    {data.status == 5 || data.status == 6 ? (
                      <ButtonLinkA
                        color="orange"
                        padding="10px 12px"
                        block
                        href={`/product/${g.slug}`}
                      >
                        Buy again
                      </ButtonLinkA>
                    ) : null}
                  </Col>
                </Row>
                <Separator margin="0" />
              </Fragment>
            )
          return null
        })}

        {data.detail.length > 2 ? (
          <Fragment>
            <Text textAlign="center" margin="10px 0">
              + {data.detail.length - 2} more products
            </Text>
            <Separator margin="15px 0 0" />
          </Fragment>
        ) : null}

        <Flex
          margin="15px 0 0"
          mobileStyle={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
          }}
        >
          <Text
            cursor="pointer"
            onClick={() => viewDetails(data.transaction_id)}
            margin="0 20px 0 0"
            mobileStyle={{
              margin: '15px 0 0',
            }}
          >
            <Icon className="fa fa-eye" margin="0 5px 0 0" /> View Detail
          </Text>

          {data.status == 3 ? (
            <Text
              cursor="pointer"
              onClick={() => viewTracking(data.transaction_id)}
              margin="0 20px 0 0"
              mobileStyle={{
                margin: '15px 0 0',
              }}
            >
              <Icon className="fa fa-truck" margin="0 5px 0 0" /> Order Tracking
            </Text>
          ) : null}

          {data.status == 4 ? (
            <Text
              cursor="pointer"
              onClick={() =>
                update('confirmation', {
                  text: 'Are you sure to receive the item?',
                  submitText: 'Confirm',
                  submit: () => {
                    confirm(data.transaction_id)
                    update('confirmation', null)
                  },
                })
              }
              margin="0 20px 0 0"
              mobileStyle={{
                margin: '15px 0 0',
              }}
            >
              <Icon className="fa fa-archive" margin="0 5px 0 0" /> Confirm
              Receive
            </Text>
          ) : null}
          
          {data.status == 0 && data.payment!=null? (
            <Button
              color="orange"
              padding="6px 12px"
              fontSize="13px"
              onClick={() => {
                if(data.payment.bank=="kredivo"){
                  paykredivo(data.transaction_id)
                }else{
                  pay(null,data.transaction_id)
                }
              }}
              mobileStyle={{
                margin: '15px 0 0',
              }}
            >
              
              Pay Now
            </Button>
          ) : null}
        </Flex>
      </Wrapper>
    </Col>
  )
}

export default OrderList
