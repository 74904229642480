import React from 'react'
import classNames from 'classnames'
import { black } from '../../assets/styles/styles'

const Spinner = props => {
  return (
    <i
      className={classNames('fa fa-spinner fa-pulse', props.className)}
      style={{ color: props.color || black }}
    />
  )
}

export default Spinner
