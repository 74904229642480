import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getData(page, category, token) {
    let responseJSON = await fetchGet(
      baseURL + '/event?Page=' + page + '&Category=' + category,
      token,
    )
    return responseJSON
  },

  async getCategory(token) {
    let responseJSON = await fetchGet(baseURL + '/eventcategory', token)
    return responseJSON
  },
}
