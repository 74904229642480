import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getProfile(token) {
    let responseJSON = await fetchGet(baseURL + '/profile', token)
    return responseJSON
  },

  async getLocation(query, token) {
    let responseJSON = await fetchGet(
      baseURL + '/address/locationQuery?query=' + query,
      token,
    )
    return responseJSON
  },

  async getLocationRegistration(query) {
    let responseJSON = await fetchGet(
      baseURL + '/locationQueryRegistration?query=' + query
    )
    return responseJSON
  },

  async getNotification(token) {
    let responseJSON = await fetchGet(baseURL + '/notification', token)
    return responseJSON
  },

  async getNotificationDetails(id, token) {
    let responseJSON = await fetchGet(baseURL + '/news/' + id, token)
    return responseJSON
  },

  async readNotification(ids, token) {
    let FormData = require('form-data')
    let form = new FormData()
    for (let i = 0; i < ids.length; i++) {
      form.append(`Notification[${i}]`, ids[i])
    }
    let responseJSON = await fetchPost(
      baseURL + '/readnotification',
      form,
      token,
      false,
      true,
    )
    return responseJSON
  },

  async updateProfile(
    NamaDepan,
    NamaBelakang,
    Email,
    Password,
    Gender,
    NomorTelepon,
    Avatar,
    token,
  ) {
    let FormData = require('form-data')
    let form = new FormData()
    form.append('NamaDepan', NamaDepan)
    form.append('NamaBelakang', NamaBelakang)
    form.append('Email', Email)
    form.append('Password', Password)
    form.append('Gender', Gender)
    form.append('PhoneNumber', NomorTelepon)
    form.append('NomorTelepon', NomorTelepon)
    form.append('Avatar', Avatar)
    let responseJSON = await fetchPost(
      baseURL + '/updateProfile',
      form,
      token,
      false,
      true,
    )
    return responseJSON
  },

  async getAddress(token) {
    let responseJSON = await fetchGet(baseURL + '/address/get', token)
    return responseJSON
  },

  async addAddress(
    Name,
    ReceiverName,
    ReceiverPhonenumber,
    Address,
    LocationId,
    token,
  ) {
    let form = JSON.stringify({
      Name,
      ReceiverName,
      ReceiverPhonenumber,
      Address,
      LocationId,
    })
    let responseJSON = await fetchPost(baseURL + '/address/add', form, token)
    return responseJSON
  },

  //Diperbaiki lagi bsk
  // async addAddress(
  //   Name,
  //   ReceiverName,
  //   ReceiverPhonenumber,
  //   Address,
  //   LocationId,
  //   token = null  // Token is optional here
  // ) {
  //   let form = JSON.stringify({
  //     Name,
  //     ReceiverName,
  //     ReceiverPhonenumber,
  //     Address,
  //     LocationId,
  //   });
  
  //   // If the token exists, include it in the headers, otherwise skip it
  //   let headers = {
  //     'Content-Type': 'application/json',
  //   };
    
  //   if (token) {
  //     headers['Authorization'] = 'Bearer ' + token;
  //   }
  
  //   let response = await fetch(baseURL + '/add', {
  //     method: 'POST',
  //     body: form,
  //     headers: headers,
  //   });
  
  //   let responseJSON = await response.json();
  //   return responseJSON;
  // },
  

  async editAddress(
    CustomerAddressId,
    Name,
    ReceiverName,
    ReceiverPhonenumber,
    Address,
    LocationId,
    token,
  ) {
    let form = JSON.stringify({
      CustomerAddressId,
      Name,
      ReceiverName,
      ReceiverPhonenumber,
      Address,
      LocationId,
    })
    let responseJSON = await fetchPost(baseURL + '/address/update', form, token)
    return responseJSON
  },

  async deleteAddress(CustomerAddressId, token) {
    let form = JSON.stringify({ CustomerAddressId })
    let responseJSON = await fetchPost(baseURL + '/address/delete', form, token)
    return responseJSON
  },
}
