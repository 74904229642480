import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async getData(Page, Lokasi, Search, token) {
    let form = JSON.stringify({ Page, Lokasi, Search })
    let responseJSON = await fetchPost(baseURL + '/offlinestore', form, token)
    return responseJSON
  },

  async getLocation(token) {
    let responseJSON = await fetchGet(baseURL + '/storelocation', token)
    return responseJSON
  },
}
