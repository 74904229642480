import styled from '@emotion/styled'
import { white, red } from '../../assets/styles/styles'

const BadgeStyled = styled.div`
  background-color: ${red};
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  border: 1px solid ${white};
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
`

const BadgeTextStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${white};
  font-size: ${props => props.fontSize};
`

export { BadgeStyled, BadgeTextStyled }
