import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Flex,
  FlexCell,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import { withContext } from '../../utils/helper'
import Spinner from '../../components/Loader/Spinner'
import ajax from './ajax'
import { MainPost, PostImage, OtherWrapper } from './styles'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import PageTitle from '../../components/PageTitle/PageTitle'
import bgmainblog from '../../assets/images/bg-mainblog.png'
const settings = {
  dots: false,
  autoPlay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // appendDots: dots => (
  //   <BannerBulletContainer>
  //     <ul> {dots} </ul>
  //   </BannerBulletContainer>
  // ),
}

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

class ProductDigital extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      recent: [],
      page: '',
      loadmore: true,
      frame: '',
      jdl: '',
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData(1)
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    // let cat = this.props.location.pathname.split('=')
    let url = window.location.href

    let id = url.substring(url.lastIndexOf('/') + 1)
    let cat = id.substring(id.lastIndexOf('?') + 1)
    let title = id.substring(id.lastIndexOf('=') + 1)
    let res2 = await ajax.getRecent(cat, token)
    if (!res2.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    // let data = res.data
    // let frame = res.data.picture || bgmainblog
    let data = []
    let recent = res2.data
    recent = [...this.state.recent, ...recent]
    // if (recent.length > 0) data = [{ blog: recent[0] }]
    this.setState({
      data,
      jdl: title.replace('-', ' '),
      recent,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }

  render() {
    const { loading, errordata, data, recent, jdl } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Layanan Digital`} margin="0 0 10px" />
              {loading ? (
                <Flex justifyContent="center">
                  <Spinner className="fa-2x" />
                </Flex>
              ) : (
                <Container padding="0">
                  <Row margin="0 -15px 40px">
                    <Col md={{ span: 10, offset: 1 }}>
                    <Text
                      fontSize="26px"
                      fontWeight="bold"
                      textAlign="center"
                      margin="0 0 20px"
                    >
                      {capitalizeWords(jdl)}
                    </Text>
                    </Col>
                  </Row>
                  <Col md={{ span: 10, offset: 1 }}>
                    <Text
                      fontSize="26px"
                      fontWeight="bold"
                      textAlign="center"
                      margin="0 0 20px"
                    >
                      {data.name}
                    </Text>

                    <Row justifyContent={'center'}>
                      {recent.map((g, i) => (
                        <Col key={i} md={2} xs={6} margin="0 0 15px">
                          <a href={`/productdigital/${g.slug}`}>
                            <div style={{ cursor: 'pointer' }}>
                              <Image
                                src={g.picture}
                                height="70px"
                                borderRadius="10%"
                                margin="0 auto 5px"
                                display="block"
                                mobileStyle={{
                                  width: '100%',
                                  height: 'auto',
                                }}
                                mediumStyle={{
                                  width: '100%',
                                  height: 'auto',
                                }}
                              />
                              <Text
                                textAlign="center"
                                fontWeight="bold"
                                fontSize="15px"
                                margin="10px 0 0"
                              >
                                {g.title}
                              </Text>
                            </div>
                          </a>
                        </Col>
                      )) || (
                        <Text
                          margin="15px 0"
                          fontSize="16px"
                          textAlign="center"
                        >
                          No product digital found
                        </Text>
                      )}
                    </Row>
                  </Col>
                  {/* <Row>
                    <Col md={12}>
                      <Row>
                        <Col
                          sm={12}
                          md={3}
                          padding="0 15px 0 30px"
                          className="other-blog"
                        >
                          <Text
                            fontWeight="bold"
                            margin="0 0 20px"
                            fontSize="16px"
                            textAlign="center"
                            mobileStyle={{ marginTop: 30 }}
                          >
                            Other Blog
                          </Text>
                          <OtherWrapper>
                            {recent.map((g, i) => (
                              <a
                                key={i}
                                href={`/blog/${g.slug}`}
                                onMouseEnter={() =>
                                  this.changeState('data', [{ blog: g }])
                                }
                              >
                                <Row margin="0 -15px 20px">
                                  <Col xs={5}>
                                    <Relative
                                      style={{
                                        background: `url('${g.background}')`,
                                        backgroundSize: '100%',
                                        padding: '0 10px',
                                      }}
                                    >
                                      <Image
                                        className="rounded"
                                        src={g.picture}
                                        width="100%"
                                      />
                                    </Relative>
                                  </Col>
                                  <Col xs={7} padding="0 0 0 15px">
                                    <Text
                                      margin="0 0 5px"
                                      textDecoration="none"
                                    >
                                      {g.title}
                                    </Text>
                                    <Text fontSize="13px" textDecoration="none">
                                      {moment(
                                        g.published_date,
                                        'YYYY-MM-DD HH:mm:ss',
                                      ).format('DD MMMM YYYY HH:mm')}
                                    </Text>
                                  </Col>
                                </Row>
                              </a>
                            ))}
                          </OtherWrapper>
                          {this.state.loadmore ? (
                            <Text
                              onClick={() =>
                                this.fetchData(this.state.page + 1)
                              }
                              textAlign="center"
                              margin="5px 0 0"
                              cursor="pointer"
                              fontSize="13px"
                              textDecoration="underline"
                            >
                              load more
                            </Text>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                </Container>
              )}
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(ProductDigital)
