import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Separator,
  BoxShadow,
  Flex,
  Icon,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import Help from '../../components/Help/Help'
import { white } from '../../assets/styles/styles'
import PageTitle from '../../components/PageTitle/PageTitle'

class FAQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getData(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data.map(g => {
      g.open = false
      return g
    })
    this.setState({
      data,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  toggle = index => {
    let data = [...this.state.data]
    data[index].open = !data[index].open
    this.setState({ data })
  }

  render() {
    const { loading, errordata, data } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 3em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > FAQ`} margin="0 0 10px" />
              <Container padding="0">
                {/* <Row>
              <Col md={12}>
                <PageTitle text="Frequently Asked Questions" />
              </Col>
            </Row> */}
                <Row margin="0 -15px 40px">
                  <Col md={{ span: 8, offset: 2 }}>
                    <BoxShadow
                      padding="10px 15px 15px"
                      borderRadius="10px"
                      backgroundColor={white}
                    >
                      <Text
                        fontSize="26px"
                        fontWeight="bold"
                        textAlign="center"
                        margin="0 0 10px"
                      >
                        Frequently Asked Questions
                      </Text>{' '}
                      <Separator />
                      {loading ? (
                        <Flex justifyContent="center" margin="15px 0">
                          <Spinner className="fa-2x" />
                        </Flex>
                      ) : (
                        data.map((g, i) => (
                          <Fragment key={i}>
                            <Relative
                              padding="10px 15px"
                              cursor="pointer"
                              onClick={() => this.toggle(i)}
                            >
                              <Row>
                                <Col xs={10}>Q : {g.question}</Col>
                                <Col xs={2}>
                                  <Text textAlign="right">
                                    {g.open ? '-' : '+'}
                                  </Text>
                                </Col>
                              </Row>
                            </Relative>

                            {g.open ? (
                              <Row padding="10px 15px">
                                <Col xs={12}>
                                  <Text
                                    dangerouslySetInnerHTML={{
                                      __html: g.answer,
                                    }}
                                  ></Text>
                                </Col>
                              </Row>
                            ) : null}

                            <Separator />
                          </Fragment>
                        ))
                      )}
                    </BoxShadow>
                  </Col>
                </Row>
                <Row></Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(FAQ)
