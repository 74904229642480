import React from 'react'
import { FormGroup } from '../../assets/styles/components'
import { LabelOutlinedStyled, TextareaOutlinedStyled } from './styles'

const TextareaOutlined = props => {
  const { label, id, margin } = props
  return (
    <FormGroup margin={margin}>
      {label ? (
        <LabelOutlinedStyled htmlFor={id || label}>{label}</LabelOutlinedStyled>
      ) : null}
      <TextareaOutlinedStyled
        {...props}
        id={id || label}
        padding={props.padding}
      />
    </FormGroup>
  )
}

TextareaOutlined.defaultProps = {
  id: '',
  label: '',
  margin: '0 0 30px',
  padding: '8px 12px',
  height: '100px',
}

export default TextareaOutlined
