import React, { Fragment, useContext } from 'react'
import Modal from '../Modal/Modal'
import Spinner from './Spinner'
import { Text, Flex } from '../../assets/styles/components'
import { AppContext } from '../../context/AppContext'

const ModalLoading = ({ ...props }) => {
  const { state } = useContext(AppContext)
  return (
    <Fragment>
      <Modal open={state.showLoading} width="auto" padding="15px 30px">
        <Flex column>
          <Spinner className="fa-3x" />
          <Text
            fontWeight="bold"
            fontSize="16px"
            textAlign="center"
            margin="10px 0 0"
          >
            loading...
          </Text>
        </Flex>
      </Modal>
    </Fragment>
  )
}

export default ModalLoading
