import React, { Fragment } from 'react'
import { withContext } from '../../utils/helper'
import { HeaderTop, HeaderStyled } from './styles'
import {
  Flex,
  Image,
  FlexCell,
  SeparatorVertical,
  Relative,
  Text,
  Icon,
} from '../../assets/styles/components'
import InputOutlined from '../Input/InputOutlined'
import Badge from '../Badge/Badge'
import Menu from '../Menu/Menu'
import ButtonLink from '../Button/ButtonLink'
import { Link } from 'react-router-dom'
import Cart from '../Cart/Cart'
import Search from '../Search/Search'
import Notification from '../Notification/Notification'
import User from '../User/User'
import { white, breakpoints } from '../../assets/styles/styles'
import { Row, Col } from '../Bootstrap/Bootstrap'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

class HeaderMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { state, update } = this.props.context
    return (
      <Row alignItems="center">
        <Col xs={2} padding="0 5px">
          <button
            type="button"
            className={classNames('navbar-toggle', {
              collapsed: !this.props.mobileMenu,
            })}
            data-toggle="collapse"
            data-target="#navbar-collapse-1"
            aria-expanded="false"
            onClick={() =>
              this.props.changeState('mobileMenu', !this.props.mobileMenu)
            }
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top-bar" />
            <span className="icon-bar middle-bar" />
            <span className="icon-bar bottom-bar" />
          </button>
        </Col>
        <Col xs={8}>
          <Flex justifyContent="center">
            <Link
              to="/"
              onClick={() => {
                if (this.props.location.pathname === '/')
                  window.location.reload()
              }}
            >
              <Image
                src={require('../../assets/images/logo-new.png')}
                width={90}
              />
            </Link>
          </Flex>
        </Col>
        <Col xs={2}>
          <Flex justifyContent="flex-end">
            <Cart />
            {/* {state.token ? (
              <Fragment>
                <SeparatorVertical margin="0 20px" />
              </Fragment>
            ) : (
              <Icon className="fa fa-search fa-lg" color={white} />
            )} */}
          </Flex>
        </Col>
      </Row>
    )
  }
}

HeaderMobile.defaultProps = {}

export default withContext(HeaderMobile)
