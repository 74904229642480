import React, { useState } from 'react'
import Slider from 'react-slick'
import { Relative, Image } from '../../../assets/styles/components'
import { BannerBullet, BannerBulletContainer } from '../styles'
import { NextArrow, PrevArrow } from '../../../utils/helper'

const settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  nextArrow: <NextArrow right={7} />,
  prevArrow: <PrevArrow left={7} />,
  // appendDots: dots => (
  //   <BannerBulletContainer>
  //     <ul> {dots} </ul>
  //   </BannerBulletContainer>
  // ),
}

const Banner = ({ data, ...props }) => {
  const [index, setIndex] = useState(0)
  return (
    <Relative
      margin="0 0 15px"
      style={{ overflow: 'hidden' }}
      className="banner"
    >
      <Slider
        {...settings}
        afterChange={index => setIndex(index)}
        customPaging={i => {
          // console.log(index, i)
          return <BannerBullet active={index === i} />
        }}
      >
        {data.map((g, i) => (
          <div key={i}>
            <Image
              className="rounded"
              style={{ boxShadow: 'none' }}
              width="100%"
              height="auto"
              mobileStyle={{ height: 'auto' }}
              src={g.link}
              objectFit="fill"
            />
          </div>
        ))}
      </Slider>
    </Relative>
  )
}

export default Banner
