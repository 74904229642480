import React, { Fragment } from 'react'
import {
  Absolute,
  Icon,
  Text,
  Flex,
  Separator,
  Image,
  Relative,
} from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import Spinner from '../../../components/Loader/Spinner'
import { orange, blue } from '../../../assets/styles/styles'
import { Container, Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Button from '../../../components/Button/Button'
import { mapStatus } from './OrderList'
import moment from 'moment'

const ModalDetails = ({ open, close, data, loading, errordata, ...props }) => {
  return (
    <Modal open={open} width="50%" padding="15px 30px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={close}
        />
      </Absolute>
      <Text fontSize="20px" fontWeight="bold" margin="0 0 20px">
        Order Detail
      </Text>

      {loading ? (
        <Flex justifyContent="center">
          <Spinner className="fa-2x" />
        </Flex>
      ) : errordata ? (
        <Flex justifyContent="center">
          <Text>Order not valid</Text>
        </Flex>
      ) : (
        <Container fluid>
          <Row>
            <Col md={5} padding="0">
              <Text mobileStyle={{ marginBottom: 7 }}>
                Order ID:{' '}
                <strong>
                  {data.transaction && data.transaction.transaction_id}
                </strong>
              </Text>
              <Text mobileStyle={{ marginBottom: 7 }}>
                Date:{' '}
                <strong>
                  {moment(
                    data.transaction && data.transaction.order_datetime,
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('DD MMMM YYYY')}
                </strong>
              </Text>
            </Col>
            <Col md={4} padding="0">
              <Text>Status:</Text>
              <Text
                fontWeight="bold"
                color={blue}
                mobileStyle={{ marginBottom: 7 }}
              >
                {mapStatus[data.transaction && data.transaction.status]}
              </Text>
            </Col>
            <Col md={3} padding="0">
              <Text>Total Shopping:</Text>
              <Text
                fontWeight="bold"
                color={orange}
                mobileStyle={{ marginBottom: 7 }}
              >
                Rp{' '}
                {formatMoney(
                  parseInt(data.transaction && data.transaction.total_price) +
                    parseInt(data.transaction && data.transaction.delivery_fee),
                )}
              </Text>
            </Col>
          </Row>

          <Text fontWeight="bold" margin="15px 0 10px">
            Order Items
          </Text>
          {data &&
            data.detail.map((g, i) => {
              return (
                <Fragment key={i}>
                  <Row alignItems="center" margin="15px -15px">
                    <Col md={2} padding="0">
                      <Relative padding="0 15px" mobileStyle={{ padding: 0 }}>
                        <Image
                          className="rounded"
                          src={g.avatar}
                          width="100%"
                          mobileStyle={{ width: '40%', marginBottom: 15 }}
                        />
                      </Relative>
                    </Col>
                    <Col md={6} padding="0">
                      <Text fontWeight="bold" margin="0">
                        {g.name}
                      </Text>
                      <Text margin="0 0 3px" fontSize="13px">
                        {`${g.color_name ? 'Color ' + g.color_name : ''}${
                          g.color_name && g.size_name ? ', ' : ''
                        }${g.size_name ? 'Size ' + g.size_name : ''}`}
                      </Text>
                      <Flex>
                        <Text color={orange}>
                          Rp{' '}
                          {formatMoney(
                            calculateDiscount(
                              g.price,
                              'Langsung',
                              g.discount_value,
                            ).price,
                          )}
                        </Text>
                        {g.discount_value ? (
                          <Text
                            color="#777"
                            textDecoration="line-through"
                            margin="0 0 0 10px"
                          >
                            Rp {formatMoney(g.price || 0)}
                          </Text>
                        ) : null}
                        <Text color="#999" margin="0 0 0 10px">
                          {g.qty} Item(s)
                        </Text>
                      </Flex>
                    </Col>
                    <Col md={4} padding="0">
                      <Text color="#999" margin="0 0 5px">
                        Subtotal
                      </Text>
                      <Text color={orange} fontWeight="bold">
                        Rp{' '}
                        {formatMoney(
                          calculateDiscount(
                            g.price,
                            'Langsung',
                            g.discount_value,
                          ).price * g.qty,
                        )}
                      </Text>
                    </Col>
                  </Row>
                  <Separator margin="0" mobileStyle={{ margin: '0 -15px' }} />
                </Fragment>
              )
            })}

          <Text fontWeight="bold" margin="15px 0 10px">
            Delivery
          </Text>
          {data.transaction && data.transaction.courier ? (
            <Flex justifyContent="flex-start">
              <Image
                src={data.transaction.courier.logo}
                width="60px"
                margin="0 10px 0 0"
              />
              <Text>{`${data.transaction.courier.name} - ${data.transaction.courier.service_name}`}</Text>
            </Flex>
          ) : null}
          {data.customeraddress ? (
            <Row margin="0">
              <Col md={4} padding="0">
                <Text margin="0 0 10px">Penerima</Text>
                <Text fontWeight="bold" fontSize="15px">
                  {data.customeraddress.receiver_name}
                </Text>
                <Text>{data.customeraddress.receiver_phonenumber}</Text>
              </Col>
              <Col md={4} padding="0">
                <Text margin="0 0 10px">Delivery Address</Text>
                <Text fontWeight="bold" fontSize="13px">
                  {data.customeraddress.name}
                </Text>
                <Text fontSize="13px">{data.customeraddress.address}</Text>
              </Col>
              <Col md={4} padding="0">
                <Text margin="0 0 10px">Postal Code</Text>
                <Text fontSize="13px">
                  {`${data.customeraddress.location.provinsi}, ${data.customeraddress.location.kabupaten}, ${data.customeraddress.location.kecamatan}, ${data.customeraddress.location.kelurahan}, ${data.customeraddress.location.kodepos}, Indonesia`}
                </Text>
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Modal>
  )
}

export default ModalDetails
