import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import {
  Flex,
  Text,
  Icon,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import InputOutlined from '../../components/Input/InputOutlined'
import Button from '../../components/Button/Button'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import { withContext } from '../../utils/helper'
import Select from '../../components/Select/Select'
import Footer from '../../components/Footer/Footer'
import ajax from './ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import ModalSuccess from './components/ModalSuccess'
import { numberOnly } from '../../utils/format'
import { Link } from 'react-router-dom'
import { blue, orange } from '../../assets/styles/styles'

class Verification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      error: '',
      modal: false,
      success: false,
    }
    this.submit = this.submit.bind(this)
  }
  componentDidMount() {
    this.submit()
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async submit() {
    let mes = 'Failed to verify email, token is not registered'
    if (this.props.match.params && this.props.match.params.id) {
      let res = await ajax.verificate(this.props.match.params.id)
      if (!res.success) {
        this.props.context.update('showDandanLoader', false)
        this.setState({ error: mes })
        return false
      }
      this.props.context.update('showDandanLoader', false)
      this.props.context.login(res.data.customer.Data, res.data.token)
      this.setState({ error: 'Email successfully verified', success: true })
    } else {
      this.props.context.update('showDandanLoader', false)
      this.setState({ error: mes })
    }

    this.setState({
      loading: false,
      modal: true,
      // success: res.message,
    })
  }
  render() {
    const { error, success } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Flex
              justifyContent="center"
              padding="1.5em 0.15em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Container padding="0">
                <Row margin="0 -15px 40px">
                  <Col md={12}>
                    <Text
                      fontSize="26px"
                      fontWeight="bold"
                      textAlign="center"
                      margin="0 0 20px"
                    >
                      Email Confirmation
                    </Text>
                    <Text textAlign="center">
                      {success ? (
                        <Icon
                          className="fa fa-check-circle"
                          style={{ fontSize: 80 }}
                          color={blue}
                        />
                      ) : (
                        <Icon
                          className="fa fa-times-circle-o"
                          style={{ fontSize: 80 }}
                          color={orange}
                        />
                      )}
                    </Text>
                    <Text
                      fontSize="16px"
                      textAlign="center"
                      margin="15px 0 30px"
                    >
                      {error}
                    </Text>
                    <Link to="/">
                      <Text textAlign="center" color={blue}>
                        back to home
                      </Text>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Flex>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Verification)
