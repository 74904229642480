import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'

import { withContext } from '../../utils/helper'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import Button from '../../components/Button/Button'

import {
    Image,
    Text,
    Relative,
    Separator,
    BoxShadow,
    Flex,
    Icon,
    PageWrapper,
    FlexCell,
  } from '../../assets/styles/components'

class PaymentLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errordata: false
        }
    }
    componentDidMount(){
        let id = this.props.location.pathname.split('/')
        id = id[id.length - 1]
        const snap = window.snap
        snap.pay(id)
        setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
        // window.location.href ='https://dandanku.com/'

    }

    // componentWillUnmount() {
    //     // this.props.context.update('showLoading', false)
    //     // this.setState('loading', false)
        
    // }

   
  render() {
    const { loading, errordata, data } = this.state
    return (
        <Fragment>
            <Header match={this.props.match} location={this.props.location} />

            <PageWrapper promo={this.props.context.state.promo.content}>
                <FlexCell>
                <Row margin="0 -15px 40px">
                  <Col md={{ span: 2, offset: 5 }} style={{ textAlign:"center" }}>
                    <Button
                            color="orange"
                            padding="10px 20px"
                            margin="100px auto 0"
                            width="100%"
                            onClick={() => {
                                window.location.href ='https://dandanku.com/'
                            }}
                        >
                        Back to Home
                        </Button>
                    </Col>
                </Row>
                </FlexCell>
                <FlexCell>
                    <Footer location={this.props.location} />
                    <Copyright />
                </FlexCell>
            </PageWrapper>

        </Fragment>
    )
  }
}

export default withContext(PaymentLink)
