import React, { Fragment } from 'react'
import {
  Absolute,
  Icon,
  Text,
  Flex,
  Separator,
  Image,
  Relative,
} from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import { Table } from 'react-bootstrap'
import Spinner from '../../../components/Loader/Spinner'
import { orange, blue } from '../../../assets/styles/styles'
import { Container, Row, Col, } from '../../../components/Bootstrap/Bootstrap'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Button from '../../../components/Button/Button'
//import { mapStatus } from './OrderList'
import moment from 'moment'

const ModalKalkulator = ({ open, close, data, loading, errordata, ...props }) => {
  return (
    <Modal open={open} width="70%" padding="15px 30px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={close}
        />
      </Absolute>
      <Text fontSize="16px" fontWeight="bold" margin="0 0 20px">
        Rincian Cicilan Kredivo
      </Text>

{/*      
        <Flex justifyContent="center">
          <Spinner className="fa-2x" />
        </Flex>
      
        <Flex justifyContent="center">
          <Text>Order not valid</Text>
        </Flex>
      */}
        <Container fluid>
        <Text fontWeight="bold"> Produk</Text>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nama Barang</th>
                        <th>Qty</th>
                        <th>Harga</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                  {data && data.message.items.map((g, i) => {
                    return (
                      <Fragment key={i}>
                    <tr>
                        <td>{i+1}</td>
                        <td>{g.name}</td>
                        <td>{g.quantity}</td>
                        <td>Rp {formatMoney(g.price || 0)}</td>
                        <td>Rp {formatMoney(g.quantity * g.price || 0)} </td>
                    </tr>
                    </Fragment>
                  )
                  })}
                  {data && data.message.items.map((g, i) => {
                    if(i==0){
                    return (
                      <Fragment key={i}>
                    <tr>
                        <td colSpan={3}>Biaya Pengiriman</td>
                        <td></td>
                        <td>Rp {formatMoney(data.message.ongkir || 0)}</td>
                    </tr>
                    <tr>
                        <td colSpan={4}>Total</td>
                        <td><b>Rp {formatMoney(data.message.amount || 0)}</b></td>
                    </tr></Fragment>
                   )
                    }
                  })}
                </tbody>
            </Table>
            <br></br>
            <Text fontWeight="bold"> Rincian Cicilan Kredivo</Text>
            <Table striped bordered hover>
                <thead>
                    
                    <tr>
                        <th>No</th>
                        <th>Tempo</th>
                        <th>Biaya</th>
                        <th>Cicilan</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                {data &&
            data.link.payments.map((g, i) => {
              return (
                    <tr>
                        <td>{i+1}</td>
                        <td>{g.name}</td>
                        <td>Rp {formatMoney(data.message.amount || 0)}</td>
                        <td>Rp {formatMoney(g.monthly_installment || 0)}</td>
                        <td><b>Rp {formatMoney(g.installment_amount || 0)}</b></td>
                    </tr>
                )
            })}
                </tbody>
            </Table>
          
        </Container>
    
    </Modal>
  )
}

export default ModalKalkulator
