import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import PageTitle from '../../components/PageTitle/PageTitle'
import ajax from './ajax'

class Mailer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: '',
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData() {
    this.setState({ loading: true, errordata: false })
    let res = await ajax.get(this.props.context.state.token)

    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.setState({
      data: res.data.value,
    })
  }

  render() {
    const { loading, errordata, data } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Mailer`} margin="0 0 10px" />
              <Container padding="0">
                <Row margin="0 -15px 40px">
                  <Col md={12}>
                    <PageTitle text="Mailer" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {data ? (
                      <div dangerouslySetInnerHTML={{ __html: data }} />
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Mailer)
