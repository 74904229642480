import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLinkStyled } from './styles'

const ButtonLink = ({
  block,
  rounded,
  uppercase,
  color,
  radius,
  padding,
  children,
  width,
  margin,
  disabled,
  fontSize,
  onClick,
  ...props
}) => {
  return (
    <ButtonLinkStyled
      {...props}
      uppercase={uppercase}
      radius={radius}
      rounded={rounded}
      padding={padding}
      color={color}
      block={block}
      width={width}
      margin={margin}
      fontSize={fontSize}
      onClick={disabled ? () => {} : onClick}
    >
      {children}
    </ButtonLinkStyled>
  )
}

ButtonLink.propTypes = {
  color: PropTypes.oneOf([
    'blue',
    'blue-light',
    'yellow',
    '',
    'grey',
    'orange',
    'white',
    'ghost-blue',
    'ghost-grey',
    'ghost-blue-light',
    'ghost-red',
    'ghost-orange',
    'disabled',
    'pink',
    'green',
  ]),
}

ButtonLink.defaultProps = {
  color: '',
  radius: '4px',
  className: '',
  padding: '8px 12px',
  width: 'auto',
  margin: '0',
  disabled: false,
  fontSize: '14px',
}

export default ButtonLink
