import React, { useContext } from 'react'
import {
  Absolute,
  Icon,
  Text,
  Flex,
  Image,
  FlexCell,
} from '../../../assets/styles/components'
import Modal from '../../../components/Modal/Modal'
import Spinner from '../../../components/Loader/Spinner'
import { orange, red, white, blue } from '../../../assets/styles/styles'
import ButtonLink from '../../../components/Button/ButtonLink'
import Button from '../../../components/Button/Button'
import Rating from '../../../components/Rating/Rating'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import { AppContext } from '../../../context/AppContext'

const ModalCartAdded = ({ ...props }) => {
  const { state, update } = useContext(AppContext)
  let data = state.successCart
  return (
    <Modal open={data.id ? true : false} width="30%" padding="15px 30px">
      <Absolute top="7px" right="7px">
        <Icon
          className="fa fa-times-circle fa-lg"
          color="#999"
          cursor="pointer"
          onClick={() => update('successCart', {})}
        />
      </Absolute>
      <Text fontSize="16px" fontWeight="bold" textAlign="center">
        Added to Cart
      </Text>
      <Flex margin="15px 0 0">
        <FlexCell margin="0 15px 0 0">
          <Image
            className="rounded"
            src={data.avatar || data.picture}
            width="100px"
          />
        </FlexCell>
        <FlexCell>
          <Text fontWeight="bold" fontSize="16px" margin="0 0 5px">
            {data.name}
          </Text>

          <Flex margin="0 0 5px">
            {data.rate ? (
              <Rating count={data.rate} />
            ) : (
              <Text textAlign="center" color={blue} fontSize="12px">
                Not rated
              </Text>
            )}
          </Flex>

          <Flex>
            <Text color={orange} fontSize="16px" fontWeight="bold">
              Rp{' '}
              {formatMoney(
                calculateDiscount(
                  data.price,
                  data.discount_type,
                  data.discount_value,
                ).price,
              )}
            </Text>

            {data.discount_value ? (
              <Text
                color="#777"
                textDecoration="line-through"
                margin="0 0 0 10px"
              >
                Rp {formatMoney(data.price || 0)}
              </Text>
            ) : null}
          </Flex>
        </FlexCell>
      </Flex>
      <ButtonLink
        onClick={() => update('successCart', {})}
        to="/cart"
        color="orange"
        padding="10px 20px"
        block
        margin="15px 0"
      >
        View Cart
      </ButtonLink>
      <Button
        color="ghost-grey"
        padding="10px 20px"
        block
        onClick={() => update('successCart', {})}
      >
        Continue Shopping
      </Button>
    </Modal>
  )
}

export default ModalCartAdded
